import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import {Container, Grid, Image} from "semantic-ui-react";
import MainImage from "../Images/DSC_8093-edited-darker.png";
import MobileMainImage from "../Images/mobileEsgImage.png";
import "./ESG.css";
import ESG1 from "../Images/operational-env-image.png";
import ESG2 from "../Images/Environment-01.jpg";
import GoldStandard from "../Images/Mask Group 19.png";
import VerifiedStandard from "../Images/Image 5.png";
import ClimateStandard from "../Images/Mask Group 20.png";
import Warehouse1 from "../Images/ESG-02.jpg";
import Warehouse2 from "../Images/DSC_9081-edited.jpg";
import Warehouse3 from "../Images/DSC_9002-edited.jpg";
import Footer from "./Footer";
import Arrow from "../Images/Layer 2_blue.svg";
import {Link} from "react-scroll";
import {Link as LinkThrough} from "react-router-dom";
import ScrollArrow from "../Images/Scroll down_W.svg";
import Download from "../Images/Download_Icon.png";
import { HashLink } from 'react-router-hash-link';

export default class ESG extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    Button = (text, tag, link) => {
        return (
            <Container className='buttonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <LinkThrough className='recyclingButton' to={link}><div className='buttonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></LinkThrough>
            </Container>
        );
    }

    MobileButton = (text, tag, link) => {
        return (
            <Container className='buttonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <LinkThrough className='mobileRecyclingButton' to={link}><div className='mobileButtonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></LinkThrough>
            </Container>
        );
    }

    HashLinkButton = (text, tag, link) => {
        return (
            <Container className='buttonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <HashLink className='recyclingButton' to={link}><div className='buttonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></HashLink>
            </Container>
        );
    }

    MobileHashButton = (text, tag, link) => {
        return (
            <Container className='buttonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <HashLink className='mobileRecyclingButton' to={link}><div className='mobileButtonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></HashLink>
            </Container>
        );
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileEsgMainCopy'>
                    <header className='mobileEsgSmallTitle'>ESG</header>
                    <header className='mobileH1_w'>It just</header>
                    <header className='mobileH1_w'>makes sense</header>
                    <header style={{'padding-top':'5%'}} className='mobileSubCopy2'>Pick-it up. Bring it back.</header>
                    <header className='mobileSubCopy2'>Recycle it. Zero waste.</header>
                </Container>
            )
        }
        else {
            return (
                <Container className='esgMainCopy'>
                    <header className='small_title_w'>ESG</header>
                    <header className='h1_w'>It just</header>
                    <header className='h1_w'>makes sense</header>
                    <Container className='esgContainer1'>
                        <header style={{'text-align':'center'}} className='h5_w'>Pick-it up. Bring it back.</header>
                        <header style={{'text-align':'center'}}  className='h5_w'>Recycle it. Zero waste.</header>
                    </Container>
                    <Container className='esgScrollArrow'>
                        <Link to="point1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                    </Container>
                </Container>
            )
        }
    }

    bodyHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row>
                    <Container className='esgContainer2'>
                        <h2 className='mobileEsgHeader'>Approach to ESG</h2>
                        <header className='mobileEsgBody'>Marks Electrical is committed to minimising its impact on the environment and ensuring it operates with clearly defined social objectives, whilst being closely governed for the benefits of all stakeholders.

                            We achieve this through a clear approach to Environmental activities, a focus on social KPIs to drive maximum impact and a clear and committed corporate governance structure.
                        </header>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row>
                    <Container id='point1' className='esgContainer2'>
                        <h2 className='h2'>Approach to ESG</h2>
                        <header className='body-copy-16'>Marks Electrical is committed to minimising its impact on the environment and ensuring it operates with clearly defined social objectives, whilst being closely governed for the benefits of all stakeholders.

                            We achieve this through a clear approach to Environmental activities, a focus on social KPIs to drive maximum impact and a clear and committed corporate governance structure.
                        </header>
                    </Container>
                </Grid.Row>
            )
        }
    }

    switchGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid stackable className='mobileEsgSwitchGrid'>
                    <Grid.Row>
                        <Grid.Column className='esgStackColumns'>
                            <Container className='mobileEsgContainer3'>
                                <h2 className='mobileEsgHeader'>Environment</h2>
                            </Container>
                            <img alt='warehouse' className='mobileEsgListImages' src={ESG2}/>
                        </Grid.Column>
                        <Grid.Column className='mobileEsgStackColumns'>
                            <Container className='mobileEsgStackContainers1'>
                                <header className='mobileEsgStackHeaders'>Operational environmental approach</header>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='mobileEsgListText'>In-house recycling of cardboard, polystyrene and polythene on site</text></li>
                                    <li className='journeyLi'><text className='mobileEsgListText'>WEEE recycling of used MDAs</text></li>
                                    <li className='journeyLi'><text className='mobileEsgListText'>Carbon Neutral operations, by offsetting our delivery and site level energy use</text></li>
                                </ul>
                                {this.MobileHashButton("OUR RECYCLING ACTIVITIES", "recycle", "/environment#recycling")}
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                <Grid.Row>
                    <Grid.Column className='esgStackColumns'>
                        <img alt='polystyrene recycling' className='mobileEsgListImages' src={ESG1}/>
                    </Grid.Column>
                    <Grid.Column className='mobileEsgStackColumns'>
                        <Container className='mobileEsgStackContainers1'>
                            <header className='mobileEsgStackHeaders'>Corporate environmental approach</header>
                            <ul className='leadershipUl'>
                                <li className='journeyLi'><text className='mobileEsgListText'>- Carbon KPIs tracked to compare performance over time:</text></li>
                                <li className='journeyLi'><text className='mobileEsgListText'>- 30.2 tonnes of Gross CO2e per £m turnover</text></li>
                                <li className='journeyLi'><text className='mobileEsgListText'>- 13.5 tonnes of Gross CO2e per FTE</text></li>
                                <li className='journeyLi'><text className='mobileEsgListText'>The Company offset its carbon emissions for the last financial year with certified offset projects</text></li>
                            </ul>
                            {this.MobileHashButton("OUR CARBON OFF-SETTING ACTIVITIES", "carbon", "/environment#carbonOffSetting")}
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            )
        }
        else {
            return (
                <Grid stackable className='investorsImagesGrid'>
                    <Grid.Row>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='esgContainer3'>
                                <h2 className='h2'>Environment</h2>
                            </Container>
                            <img alt='warehouse' className='worksListImages' src={ESG2}/>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='esgStackContainers1'>
                                <header className='esgStackHeaders'>Operational environmental approach</header>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='esgListText'>In-house recycling of cardboard, polystyrene and polythene on site</text></li>
                                    <li className='journeyLi'><text className='esgListText'>WEEE recycling of used MDAs</text></li>
                                    <li className='journeyLi'><text className='esgListText'>Carbon Neutral operations, by offsetting our delivery and site level energy use</text></li>
                                </ul>
                                {this.HashLinkButton("OUR RECYCLING ACTIVITIES", "recycle", "/environment#recycling")}
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column style={{'padding-top':'0'}} className='investorsDataColumns'>
                            <Container style={{'padding-top':'4%'}} className='esgStackContainers2'>
                                <header className='esgStackHeaders'>Corporate environmental approach</header>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='esgListText'>Carbon KPIs tracked to compare performance over time:</text></li>
                                    <li className='journeyLi'><text className='esgListText'>30.2 tonnes of Gross CO2e per £m turnover</text></li>
                                    <li className='journeyLi'><text className='esgListText'>13.5 tonnes of Gross CO2e per FTE</text></li>
                                </ul>
                                <text className='esgListText'>The Company offset its carbon emissions for the last financial year with certified offset projects</text>
                                {this.HashLinkButton("OUR CARBON OFF-SETTING ACTIVITIES", "carbon", "/environment#carbonOffSetting")}
                            </Container>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'0'}} className='investorsDataColumns'>
                            <img alt='polystyrene recycling' className='worksListImages' src={ESG1}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    certifications = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileEsgStandardsRow'>
                    <Grid.Row className='mobileStandardsRow1'>
                        <header style={{'max-width':'93vw'}} className='mobileEsgStackHeaders'>Standards and certifications</header>
                    </Grid.Row>
                    <Grid.Row className='mobileEsgStandardsRow'>
                        <Grid.Column className='mobileEsgStandardsColumns'>
                            <img style={{'width':'70%'}} alt='gold standard for the global goals award' className='mobileStandardsImg' src={GoldStandard}/>
                        </Grid.Column>
                        <Grid.Column className='mobileEsgStandardsColumns'>
                            <img style={{'width':'60%'}} alt='verified carbon standard logo' className='mobileStandardsImg' src={VerifiedStandard}/>
                        </Grid.Column>
                        <Grid.Column className='mobileEsgStandardsColumns'>
                            <img alt='verra climate, community, and biodiversity logo' className='mobileStandardsImg' src={ClimateStandard}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid>
                    <Grid.Row className='esgStandardsHeaderRow'>
                        <header style={{'padding-left':'12%', 'padding-bottom':'2%'}} className='esgStackHeaders'>Standards and certifications</header>
                    </Grid.Row>
                    <Grid.Row style={{'padding-left':'11%'}} className='esgStandardsRow'>
                        <Grid.Column className='esgStandardsColumns'>
                            <img alt='gold standard for the global goals award' className='esgStandards' src={GoldStandard}/>
                        </Grid.Column>
                        <Grid.Column style={{'text-align':'center'}} className='esgStandardsColumns'>
                            <img alt='verified carbon standard logo' className='esgStandards' style={{'display':'inline-block' }} src={VerifiedStandard}/>
                        </Grid.Column>
                        <Grid.Column className='esgStandardsColumns'>
                            <img alt='verra climate, community, and biodiversity logo' className='esgStandards' style={{'float':'right' }} src={ClimateStandard}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    socialRow = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row>
                    <Container className='esgContainer4'>
                        <h2 className='mobileEsgStandardsHeader'>Social</h2>
                        <header className='mobileEsgListText2'>The Group is committed to embracing gender and cultural diversity across the business and to maintaining opportunities for apprentice schemes and consistently offering staff training. Furthermore, the Group intends to increase the tracking of non-financial KPIs: including employee attrition, gender pay gap, employee training and health and safety metrics.</header>
                        <header className='mobileEsgListText2'>Outside of the day to day activities of the Group, Marks Electrical also supports multiple projects within the community.</header>
                        {this.MobileButton("SOCIAL PROJECTS", "soc", "/community")}
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row>
                    <Container className='esgContainer4'>
                        <h2 className='h2'>Social</h2>
                        <header className='esgListText2'>The Group is committed to embracing gender and cultural diversity across the business and to maintaining opportunities for apprentice schemes and consistently offering staff training. Furthermore, the Group intends to increase the tracking of non-financial KPIs: including employee attrition, gender pay gap, employee training and health and safety metrics.</header>
                        <header className='esgListText2'>Outside of the day to day activities of the Group, Marks Electrical also supports multiple projects within the community.</header>
                        {this.Button("SOCIAL PROJECTS", "soc", "/community")}
                    </Container>
                </Grid.Row>
            )
        }
    }

    governanceSwitch = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid stackable className='mobileEsgSwitchGrid'>
                    <Grid.Row>
                        <Grid.Column className='esgStackColumns'>
                            <Container className='mobileEsgContainer3'>
                                <h2 className='mobileEsgHeader'>Governance</h2>
                            </Container>
                            <img alt='man in warehouse' className='mobileEsgListImages' src={Warehouse1}/>
                        </Grid.Column>
                        <Grid.Column className='mobileEsgStackColumns'>
                            <Container className='mobileEsgStackContainers1'>
                                <header className='mobileEsgStackHeaders'>QCA code</header>
                                <header className='mobileEsgListText'>The Company also complies with the Quoted Companies Alliance (QCA) code. </header>
                                {this.MobileButton("OUR APPROACH TO GOVERNANCE", "gov", "/governance")}
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='mobileEsgStackColumns'>
                            <img alt='paper on desk' className='mobileEsgListImages' src={Warehouse2}/>
                        </Grid.Column>
                        <Grid.Column className='mobileEsgStackColumns'>
                            <Container className='mobileEsgStackContainers2'>
                                <header className='mobileEsgStackHeaders'>Board of Directors</header>
                                <header className='mobileEsgListText'>The Board consists of three non-executive Board members that bring a wide breadth of relevant skills and experience encompassing finance, digital, marketing and HR, in addition to two executive directors being the CEO and CFO. </header>
                                {this.MobileButton("ABOUT OUR BOARD", "board", "/leadership")}
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='mobileEsgStackColumns'>
                            <img alt='man at computer' className='mobileEsgListImages' src={Warehouse3}/>
                        </Grid.Column>
                        <Grid.Column className='mobileEsgStackColumns'>
                            <Container className='mobileEsgStackContainers2'>
                                <header className='mobileEsgStackHeaders'>Accuvio</header>
                                <header className='mobileEsgListText'>Centralising management of ESG data through Accuvio for more efficient and accurate impact reporting.</header>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
        else {
            return (
                <Grid style={{'padding-bottom':'5%'}} stackable className='investorsImagesGrid'>
                    <Grid.Row>
                        <Grid.Column style={{'padding-top':'4%','padding-left':'0'}} className='investorsDataColumns'>
                            <Container className='esgContainer3'>
                                <h2 className='h2'>Governance</h2>
                            </Container>
                            <img alt='man in warehouse' className='worksListImages' src={Warehouse1}/>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='esgStackContainers3'>
                                <header className='esgStackHeaders'>QCA code</header>
                                <header className='esgListText'>The Company also complies with the Quoted Companies Alliance (QCA) code. </header>
                                {this.Button("OUR APPROACH TO GOVERNANCE", "gov", "/governance")}
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='esgStackContainers2'>
                                <header className='esgStackHeaders'>Board of Directors</header>
                                <header className='esgListText'>The Board consists of three non-executive Board members that bring a wide breadth of relevant skills and experience encompassing finance, digital, marketing and HR, in addition to two executive directors being the CEO and CFO. </header>
                                {this.Button("ABOUT OUR BOARD", "board", "/leadership")}
                            </Container>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='paper on desk' className='worksListImages' src={Warehouse2}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='man at computer' className='worksListImages' src={Warehouse3}/>
                        </Grid.Column>
                        <Grid.Column className='esgStackColumns'>
                            <Container className='esgStackContainers4'>
                                <header className='esgStackHeaders'>Accuvio</header>
                                <header className='esgListText'>Centralising management of ESG data through Accuvio for more efficient and accurate impact reporting.</header>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    policiesAndReports = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row>
                    <Container className='mobileEsgContainer4'>
                        <h2 className='mobileEsgStandardsHeader'>Policies and reports</h2>
                        <Grid stackable divided='vertically' className='mobileEsgDataGrid'>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarHeadline'>DATE</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarHeadline'>TITLE</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarDate'>20 July 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarBody'>Price monitoring extention</header>
                                    <header className='esgCalendarDownload'>DOWNLOAD PDF</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarDate'>20 July 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarBody'>Price monitoring extention</header>
                                    <header className='esgCalendarDownload'>DOWNLOAD PDF</header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row>
                    <Container className='esgContainer4'>
                        <h2 className='h2'>Policies and reports</h2>
                        <Grid stackable divided='vertically' className='esgDataGrid'>
                            <Grid.Row style={{'padding-bottom':'0'}}>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarHeadline'>DATE</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarHeadline'>TITLE</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarHeadline'>DOCUMENT</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>20 July 2021</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>Price monitoring extention</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarDownload'><img alt='download icon' src={Download} className='contactIcons'/>DOWNLOAD PDF</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>20 July 2021</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>Price monitoring extention</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarDownload'><img alt='download icon' src={Download} className='contactIcons'/>DOWNLOAD PDF</header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
    }

    scrollArrow = () => {
        if(this.props.mobileSite !== true) {
            return (
                <Container className='scrollArrow'>
                    <Link to="esgPoint1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                </Container>
            )
        }
    }

    render() {
        return (
            <div className='esg'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.scrollArrow()}
                        {this.mainImage()}
                        {this.mainCopy()}
                    </Grid.Row>
                    {this.bodyHeader()}
                    {this.switchGrid()}
                    {this.certifications()}
                    {this.socialRow()}
                    {this.governanceSwitch()}
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}