import React, {Component} from "react";
import {Container, Grid, Image, List, Menu} from "semantic-ui-react";
import TrustPilot from "../Images/trustpilot.png";
import Feefo from "../Images/feefo.png";
import Euronics from "../Images/euronics-logo-new.png";
import TopCashBack from "../Images/tcblogo.png";
import Quidco from "../Images/quidco.png";
import Arrow from "../Images/invisible box.svg";
import {NavLink} from "react-router-dom";
import Pinterest from "../Images/pinterest.png";
import Youtube from "../Images/youtube.png";
import Instagram from "../Images/instagram.png";
import Twitter from "../Images/twitter.png";
import Facebook from "../Images/facebook.png";

export default class Footer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            activeItem: {}
        }
    }

    openInNewTab = (url) => {
        const newWindow = window.open();
        newWindow.location = url;
    }

    openInSameTab = (url) => {
        window.open(url, '_self', 'noopener,noreferrer')
    }

    handleMouseEnter = () => {
        this.setState({
            left: 7,
        });
    }

    handleMouseLeave = () => {
        this.setState({
            left: 2,
        });
    }

    SignUpButton = () => {
        return (
            <Container className='aboutUsButtonContainer' onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                <button className='aboutUsButton'><NavLink to='/signup' className='signUpButtonText'>SIGN UP</NavLink><div className='arrow' style={{ left: this.state.left }}><img alt='button arrow' src={Arrow}/></div></button>
            </Container>
        );
    }

    render(){
        const menuSocialContents = (menuName) => {
            return(
                <Container className='footerContainer'>
                    <Menu className={menuName} icon='labeled' vertical>
                        <Menu.Item
                            name='facebook'
                            className='socialItems'
                            fitted='vertically'
                            active={this.state.activeItem === 'facebook'}
                            onClick={() => this.openInNewTab('https://www.facebook.com/MarksElectrical')}
                        >
                            <header className='FooterIcons'><img alt='facebook icon' src={Facebook} className='footerIcons'/>Facebook</header>
                        </Menu.Item>
                        <Menu.Item
                            name='twitter'
                            className='socialItems'
                            fitted='vertically'
                            active={this.state.activeItem === 'twitter'}
                            onClick={() => this.openInNewTab('https://twitter.com/MarksElectrical')}
                        >
                            <header className='FooterIcons'><img alt='twitter icon' src={Twitter} className='footerIcons'/>Twitter</header>
                        </Menu.Item>
                        <Menu.Item
                            name='instagram'
                            className='socialItems'
                            fitted='vertically'
                            active={this.state.activeItem === 'instagram'}
                            onClick={() => this.openInNewTab('https://www.instagram.com/markselectrical.co.uk/')}
                        >
                            <header className='FooterIcons'><img alt='instagram icon' src={Instagram} className='footerIcons'/>Instagram</header>
                        </Menu.Item>
                        <Menu.Item
                            name='youtube'
                            className='socialItems'
                            fitted='vertically'
                            active={this.state.activeItem === 'youtube'}
                            onClick={() => this.openInNewTab('https://www.youtube.com/user/MarksElectrical1')}
                        >
                            <header className='FooterIcons'><img alt='youtube icon' src={Youtube} className='footerIcons'/>Youtube</header>
                        </Menu.Item>
                        <Menu.Item
                            name='pinterest'
                            className='socialItems'
                            fitted='vertically'
                            active={this.state.activeItem === 'pinterest'}
                            onClick={() => this.openInNewTab('https://www.pinterest.co.uk/markselectrical/')}
                        >
                            <header className='FooterIcons'><img alt='pinterest icon' src={Pinterest} className='footerIcons'/>Pinterest</header>
                        </Menu.Item>
                    </Menu>
                </Container>
            )
        }

        const menuAddressContents = (addressList) => {
            return(
                <List className={addressList}>
                    <List.Item>
                        <List.Content className="addressItems">Marks Electrical Group plc</List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content className="addressItems">4 Boston Rd</List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content className="addressItems">Leicester</List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content className="addressItems">LE4 1AU</List.Content>
                    </List.Item>
                </List>
            )
        }

        const reviewContent = (menuName) => {
            return (
                <Menu className={menuName} size='large' vertical>
                    <Menu.Item
                        name = 'trustPilot'
                        fitted='horizontally'
                        active={this.state.activeItem === 'trustPilot'}
                        onClick={() => this.openInNewTab('https://uk.trustpilot.com/review/www.markselectrical.co.uk')}
                    >
                        <Image className='footerImg' src={TrustPilot} alt="trustPilot" size='small'/>
                    </Menu.Item>
                    <Menu.Item
                        name = 'feefo'
                        fitted='horizontally'
                        active={this.state.activeItem === 'feefo'}
                        onClick={() => this.openInNewTab('https://www.feefo.com/en-GB/reviews/marks-electrical?displayFeedbackType=BOTH&timeFrame=YEAR')}
                    >
                        <Image className='footerImg' src={Feefo} alt="feefo" onClick={this.onClick} size='small'/>
                    </Menu.Item>
                    { /*
                    <Menu.Item
                        name = 'euronics'
                        fitted='horizontally'
                        active={this.state.activeItem === 'euronics'}
                        onClick={() => this.openInNewTab('https://www.euronics.co.uk/store/Head%20Office%20-%20Marks%20Electrical%20Ltd%2014600?lat=52.66366&long=-1.17192')}
                    >
                        <Image className='footerImg' src={Euronics} alt="euronics" onClick={this.onClick} size='small'/>
                    </Menu.Item> */ }

                    <Menu.Item
                        name = 'topCashBack'
                        fitted='horizontally'
                        active={this.state.activeItem === 'topCashBack'}
                        onClick={() => this.openInNewTab('https://www.topcashback.co.uk/marks-electrical/')}
                    >
                        <Image className='footerImg' src={TopCashBack} alt="topCashBack" onClick={this.onClick} size='small'/>
                    </Menu.Item>
                    <Menu.Item
                        name = 'quidco'
                        fitted='horizontally'
                        active={this.state.activeItem === 'quidco'}
                        onClick={() => this.openInNewTab('https://www.quidco.com/marks-electrical/')}
                    >
                        <Image className='footerImg' src={Quidco} alt="quidco" onClick={this.onClick} size='small'/>
                    </Menu.Item>
                </Menu>
            )
        }

        const reviewMenu = (menuName) => {
            return(
                <Container fluid className='footerContainer'>
                    {reviewContent(menuName)}
                </Container>
            )
        }

        const mobileGrids = () => {
            if (this.props.mobileSite === true) {
                return(
                    <Grid className='footerGrid' inverted stackable>
                        <Grid.Row className='footerGridRow'>
                            <Grid.Column className='mobileFooterGridColumns'>
                                <header style={{'padding-bottom':'2%'}} className='mobileFooterHeader'>Our reviews</header>
                                {reviewMenu("mobileReviewMenu", "mobileReviews")}
                            </Grid.Column>
                            <Grid.Column className='mobileFooterGridColumns'>
                                <header className='mobileFooterHeader'>Social</header>
                                {menuSocialContents("mobileMenuName", "mobileFollowUs")}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className='footerGridRow'>
                            <Grid.Column className='mobileFooterGridColumns'>
                                <header className='mobileFooterHeader'>Head office</header>
                                {menuAddressContents("mobileAddressList")}
                            </Grid.Column>
                            <Grid.Column className='mobileFooterGridColumns'>
                                <header className='mobileFooterHeader'>Email sign up</header>
                                {this.SignUpButton()}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )
            }
            else{
                return(
                    <Grid className='footerGrid' inverted stackable>
                        <Grid.Row className='footerGridRow'>
                            <Grid.Column className='footerGridColumns'>
                                <header className='footerHeader'>Our reviews</header>
                                {reviewMenu("mobileReviewMenu", "mobileReviews")}
                            </Grid.Column>
                            <Grid.Column className='footerGridColumns'>
                                <header className='footerHeader'>Social</header>
                                {menuSocialContents("mobileMenuName", "mobileFollowUs")}
                            </Grid.Column>
                            <Grid.Column className='footerGridColumns'>
                                <header className='footerHeader'>Head office</header>
                                {menuAddressContents("mobileAddressList")}
                            </Grid.Column>
                            <Grid.Column className='footerGridColumns'>
                                <header className='footerHeader'>Email sign up</header>
                                {this.SignUpButton()}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )
            }
        }

        return(
            <div className='footer'>
                {mobileGrids()}
                <Grid className='footerGrid' divided='vertically' inverted stackable>
                    <Grid.Row>
                        <Menu className='footerMenu' borderless stackable>
                            <Menu.Item
                                name='privacy'
                                active={this.state.activeItem === 'privacy'}
                                onClick={() => this.openInSameTab('https://markselectrical.co.uk/privacy-policy.html')}
                            >
                                <h3 className='h3'>Privacy Policy</h3>
                            </Menu.Item>
                            <Menu.Item
                                name='cookies'
                                active={this.state.activeItem === 'cookies'}
                                onClick={() => this.openInSameTab('https://markselectrical.co.uk/cookies.html')}
                            >
                                <h3 className='h3'>Cookies</h3>
                            </Menu.Item>
                            <Menu.Item
                                name='tandc'
                                active={this.state.activeItem === 'tandc'}
                                onClick={() => this.openInSameTab('https://markselectrical.co.uk/terms-and-conditions.html')}
                            >
                                <h3 className='h3'>Terms and conditions</h3>
                            </Menu.Item>
                            <Menu.Item
                                name='modernslavery'
                                active={this.state.activeItem === 'modernslavery'}
                                onClick={() => this.openInSameTab('https://s3-eu-west-1.amazonaws.com/media.markselectrical.co.uk/other/Modern-Slavery-and-Human-Trafficking-Statement-august2023.pdf')}
                            >
                                <h3 className='h3'>Modern Slavery Act</h3>
                            </Menu.Item>
                            <Menu.Item
                                name='genderpgreport'
                                active={this.state.activeItem === 'genderpgreport'}
                                onClick={() => this.openInSameTab('https://s3.eu-west-1.amazonaws.com/media.markselectrical.co.uk/other/Gender-Pay-Gap-Report-Apr-2024.pdf')}
                            >
                                <h3 className='h3'>Gender Pay Gap Report</h3>
                            </Menu.Item>
                        </Menu>
                    </Grid.Row>
                    <Grid.Row>
                        <Container className='legalParagraph'>
                            <h3 className='h3'>Copyright © Marks Electrical Group plc 2021. Showroom & Head Office: Marks Electrical Group plc, 4 Boston Rd, Leicester, LE4 1AU. Company Registration Number: 13509635</h3>
                        </Container>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}