import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import {Container, Grid, Image} from "semantic-ui-react";
import MainImage from "../Images/Mask Group 7.png";
import MobileMainImage from "../Images/Group 8706_10.png";
import Footer from "./Footer";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import Download from "../Images/Download_Icon.png";
import Arrow from "../Images/Layer 2_blue.svg";
import {Link as LinkThrough} from "react-router-dom";

export default class Governance extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    openInNewTab = (newWindow, url) => {
        newWindow.location = url;
    }

    fetchSignedUrl = async(newWindow, file) => {
        await Promise.resolve(fetch(`https://group.markselectrical.co.uk/document?name=${file}`)
            .then(response => response.json())
            .then((data) => {
                this.setState({docUrls: data} );
            })
            .catch(console.log))

        if(this.state.docUrls.length > 0){
            this.openInNewTab(newWindow, this.state.docUrls[0].url);
        }
        else{
            console.log("No url retrieved!")
        }
    }

    handleClick = (file) => {
        const newWindow = window.open();
        this.fetchSignedUrl(newWindow, file);
    }

    DocButton = (text, tag, file) => {
        return (
            <Container className='investorsButtonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <button className='govButton' onClick={() => this.handleClick(file)}><div className='buttonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></button>
            </Container>
        );
    }

    Button = (text, tag, link) => {
        return (
            <Container className='investorsButtonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <LinkThrough className='govButton' to={link}><div className='buttonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></LinkThrough>
            </Container>
        );
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    items = [
        {
            uuid: "1",
            heading: 'Principle 1: Establish a strategy and business model which promote long-term value for shareholders',
            content: 'The Company’s strategy and business model are designed to promote long-term value for the Company’s shareholders through further developing the Company’s high-quality online customer proposition, capitalising on its key strengths as a single site, vertically integrated, ethical online retail and delivery business specialising in premium products and focussed on optimising the customer experience.We operate a simple and proven distribution network, with one national distribution centre that caters for next- day delivery to over 90% of the UK’s population, using our own fleet and driver-installers, giving us complete control over the entire process. The nature of our vertically integrated delivery model means it is scalable and with the right capital investment it has enabled significant growth in recent years.' +
                '\n\nWe work with over 50 brand partners, along with our buying group, to source relevant and popular products for our growing customer base. Our buying team monitors the market closely with our proprietary pricing technology to ensure that our pricing is both consistent with market expectations as well as aligned with supplier recommendations.' +
                '\n\nIn addition, we also work closely with a number of finance partners to provide credit and buy-now-pay-later solutions for customers. We couple this with extended warranty options, where we work with a nationwide provider of warranty solutions, to ensure customers can add this option should they choose. Ensuring we have the most relevant range of products and solutions for customers is key to our business model success and is critical to driving profitability and strong returns for investors.' +
                '\n\nWe believe that our business model is both simple and effective, having been refined over 35 years of sector experience.  ' 
        },
        {
            uuid: "2",
            heading: 'Principle 2: Seek to understand and meet shareholder needs and expectations',
            content: 'The Company is committed to listening and communicating openly with its shareholders and in doing so ensuring that the Company’s strategy, business model and performance are clearly understood.' +
                '\n\nThe Executive Directors meet regularly with institutional shareholders, including after the announcement of full-year and half-year results, and are responsible for ensuring that their expectations are understood by the Board. The Company’s annual general meetings (AGMs) also provide opportunities for dialogue between the Board, who routinely attend AGMs, and the Company’s shareholders.' +
                '\n\nMeetings with shareholders can cover a variety of issues, from current performance and outlook, to strategy and governance. We believe this regular conversation with shareholders provides optimal engagement, ensuring the shareholders have a deep understanding of the business they have invested in. As well as the scheduled meetings the Group also provides a range of corporate information about the business through its corporate website group.markselectrical.co.uk, including governance information and strategy insights as well as corporate documents such as annual reports and regulatory news announcements.' 
        },
        {
            uuid: "3",
            heading: 'Principle 3: Take into account wider stakeholder and social responsibilities and their implications for long-term success',
            content: 'The Board considers the interests of shareholders and all relevant stakeholders in line with section 172 of the Companies Act 2006. Engaging effectively with the Company’s stakeholders, in particular its team, suppliers, and customers, is core to the Company’s strategy and is considered to be a driver of long-term shareholder value. More detail on the Board’s consideration of stakeholder matters can be found in the most recent annual report and accounts.' +
                '\n\nThe Board believes that a motivated, appropriately rewarded and recognised team focussed on customer care excellence is critical to the Company’s brand and success. As the business operates from a single site, the Executive Directors are embedded in the workforce and have high visibility of the day-to-day business and issues affecting the team. All employees are encouraged to raise and discuss any areas of interest or concern they may have. The Board also holds its meetings at the Company’s Leicester Head Office, enabling the Non-Executive Directors to meet the wider team on a regular basis.' +
                
                '\n\nThe Company’s Head of HR has overseen fundamental structural changes to the workforce, improving productivity and work-life balance. She is also actively improving employee engagement, training and appraisal processes, which are fundamental to the growth of the employee base. To ensure our employees operate in a safe environment we have been concentrating on areas throughout the warehouse, building a detailed action plan, to improve processes. This includes risk assessments and safe systems of work, scheduled monthly site audits, weekly near-miss walkarounds, a training matrix, and enhanced communication to support and reinforce the Health and Safety culture.  ' +
                '\n\nThe management team makes decisions with a long-term view, in line with the Group’s policies, to consider the likely consequences for all stakeholders. Where possible, the management team endeavour to discuss major business decisions with the affected stakeholders to ensure they are fully on board and aligned before actions are taken. ' +
                
                '\n\nThe Board recognises the competitive landscape in which the business operates and seeks to differentiate itself partly through its excellent customer experience. The Company actively seeks to optimise the customer experience and routinely sources customer feedback to ensure it remains best in class.' +
                '\n\nThe Company aims to be a considerate and constructive neighbour and the Board is very cognisant of the importance of upholding its reputation in the community in order to maintain its “licence to operate” and its ability to attract and retain talent. The business is proud to be a key local employer and supports multiple grass-roots sports clubs.' 
               
        },
        {
            uuid: "4",
            heading: 'Principle 4: Embed effective risk management, considering both opportunities and threats, throughout the organisation',
            content: 'The Board is responsible for determining the Group’s risk appetite as part of its strategic planning, as well as establishing and maintaining the Company’s system of internal controls. The Board has established the Audit and Risk Committee whose role is to assist the Board in fulfilling its oversight responsibilities by reviewing and monitoring, inter alia, the Group’s system of internal controls and risk management and how risk is reported internally and externally. ' +
            '\n\nInternal financial control systems have been reviewed, designed, and strengthened pre- and post-IPO, to ensure the Group is appropriately placed to achieve its growth strategy, has relevant controls and governance to operate as a publicly traded Group and is adequately armed to mitigate the risks to which it is exposed. Such internal financial controls include strategic planning, approval of annual budgets, regular monitoring of performance against budget (including full investigation of significant variances), control of capital expenditure and ensuring proper accounting records are maintained. In addition, the Finance function was significantly upskilled pre-IPO to prepare for the control and reporting environment required of a listed group.' +
            '\n\nThe Group also maintains a risk register to assist the business and the Board in ensuring that risk is appropriately identified, managed and mitigated. The register is regularly monitored and updated to ensure it is relevant. Input is provided by all senior management and functional heads and the register is reviewed by the Audit and Risk Committee on a regular basis and approved by the Board.' +
            '\n\nDetails of the identified principal risks and uncertainties of the Group are discussed in the most recent the annual report. ' 

        },
        {
            uuid: "5",
            heading: 'Principle 5: Maintaining the Board as a well-functioning, balanced team led by the Chair',
            content: 'The Board considers its overall size and current composition to be suitable and have an appropriate balance of sector, financial and public markets skills and experience as well as an appropriate balance of personal qualities and capabilities.' +
                '\n\nA Nomination Committee has been established to review the structure, size and composition of the Board based upon the skills, knowledge and experience required to ensure that the Board operates effectively.' +
                '\n\nThe Board consists of the Non-Executive Chair, the Chief Executive Officer, the Chief Financial Officer and two Non-Executive Directors. The biographical details of the Board members can be found both on the Company’s AIM Rule 26 website and in the Company’s annual report. The Non-Executive Directors bring key skills, experience and alternative viewpoints to the Board through their varied professional and personal experiences including listed company experience, financial acumen, HR expertise, online retail, marketing and brand management.' +
                '\n\nThe Board has determined that each of the Non-Executive Directors of the Company, namely Marnie Millard (Chair), David Wilkinson and Alyson Fadil, are independent in character and judgment and satisfy the independence criteria under the QCA Corporate Governance Code – being the recognised corporate governance code adopted by the Company. The non-executive directors have undertaken to dedicate sufficient time to the Company to discharge their duties effectively.' +
                '\n\nThe Board meets formally at least four times throughout the year in order to, amongst other things, review performance, approve financial statements, and discuss strategy and key commercial matters. The attendance record of each relevant Director at Board and Committee meetings during the financial year is disclosed in the latest annual report. There is a formal schedule of matters reserved for decision by the Board in place and this is available to view on the Governance pages of this website. The role of the Chair and CEO are clearly defined and a description of the split in responsibilities can also be viewed on the Governance page.'  +
                '\n\nThe Board is supported by an Audit and Risk Committee, a Remuneration Committee and a Nomination Committee, further details of which are set out on this website, including the Committees’ Terms of Reference.'  +
                '\n\nThe Board, Board Committees and Chair are supported by a professional company secretarial firm which ensures that a fully qualified chartered secretary is in attendance at all Board and Committee meetings to provide governance advice and support.'  
            },
        {
            uuid: "6",
            heading: 'Principle 6: Ensure that between them the Directors have the necessary up-to-date experience, skills and capabilities',
            content: 'All Directors are encouraged to continue to develop their skills and keep up to date with market developments and corporate governance matters. The Executive Directors remain current with industry news and developments as a matter of course, with input from third party advisers where appropriate (for example, in relation to IT). The CFO and Audit and Risk Committee Chair are qualified accountants and maintain their professional standing through CPD compliance, attendance at relevant training and updating events. The Chair regularly attends seminars and other events to keep up to date with new developments and topics of interest, and through her other roles has access to the resources of the Quoted Companies Alliance. Alyson Fadil is the Chief People Officer of Matalan, and was previously the Chief People Office and AIM listed company, bringing current HR expertise in a listed environment to the Board and Remuneration Committee. Training on AIM Rules and associated regulatory issues is provided by the Company’s Nomad on an annual basis. The Company Secretary will present plc related regulatory changes affecting the Company at Board meetings throughout the year. Directors are also able to take independent professional advice, if necessary, at the Company’s expense.' +
                '\n\nGenerally, the Nomination Committee is responsible for reviewing the skills and capabilities of the Board to determine any areas which may benefit from additional expertise. A priority for the Committee is to ensure that members of the Board possess the range of skills, expertise and industry knowledge, and business experience necessary for the effective oversight of the Group. Appointments are to be made against objective criteria and with regard to the benefits of diversity on the Board and the required capacity for the role.'
        },
        {
            uuid: "7",
            heading: 'Principle 7: Evaluate Board performance based on clear and relevant objectives, seeking continuous improvement',
            content: 'The Company carried out its first evaluation of the Board and the Committees in December 2022, and repeated the activity in December 2023.' + 
                     'The evaluation processes assessed the balance of skills, experience, independence, and diversity, including gender and ethnicity, of the Directors as a whole,'+
                     'and also explored factors relevant to their effectiveness, such as how they work together. It is the Chair’s responsibility to take action based on the result' +
                     'of the performance evaluations, which could include proposing new Board members or seeking the resignation of Directors in situ. Further information on the' +
                     'Company’s approach to succession planning is disclosed in the Nomination Committee Report in the latest annual report.' +
                     '\n\nThe individual evaluations aim to determine whether each Director continues to demonstrate commitment to the role, and contribute effectively, ' +
                     'including the commitment of time for Board and Committee meetings and other duties. The performance evaluation was led by the independent Company Secretary. ' +
                     'The topics covered by the review included the following areas: ' +
                     '\n\n- Strategy development ' +
                     '\n\n- Operational challenges ' +
                     '\n\n- Risk management ' +
                     '\n\n- Stakeholder relationships ' +
                     '\n\n- Talent management and succession planning ' +
                     '\n\n- Purpose, values and culture ' +
                     '\n\n- Board role and dynamics ' +
                     '\n\n- Composition and engagement ' +
                     '\n\n- Board Committees ' +
                     '\n\nThere were no material deficiencies highlighted through the Board effectiveness evaluation.The Board is satisfied that all Directors continue to be effective and ' +
                     'demonstrate commitment and the required capacity to their respective roles on the Board. The next performance evaluation will take place before the end of FY25. '
        },
        {
            uuid: "8",
            heading: 'Principle 8: Promote a culture that is based on ethical values and behaviours',
            content: 'The Company’s values and culture are critical to its strategy and are core to promoting long-term shareholder value.' +
                '\n\nThe Group has operated with the same core principles since it was founded in 1987 and these are crucial to its brand identity. These are to:' +
                '\n\n - Try your hardest: Every day we strive to be better than yesterday. We are proud of our business and dedicated to its success.' +
                '\n\n - Think like a customer: Our team are fully committed to ensuring we listen to our customers, understand their requirements and meet their needs.' +
                '\n\n - Move at pace: As the business is constantly evolving we have a clear understanding of expectations and operate quickly and efficiently.' +
                '\n\n - Be honest. Always: We are trustworthy and honest in all that we say and do and take responsibility for our own actions.' +
                '\n\nThe Group has also implemented, inter alia, the following policies to help ensure appropriate values and behaviours:' +
                '\n\n - an Anti-Bribery and Corruption Policy' +
                '\n\n - a Whistleblowing Policy' +
                '\n\n - a Share Dealing Policy' +
                '\n\n - various HR policies including anti-discrimination and anti-bullying policies' +
                '\n\nThe Company has focussed on establishing a strong culture and has long promoted ethical values and behaviours, which the Directors believe has helped to attract and retain quality, engaged personnel dedicated to delivering high-quality customer service, thereby contributing towards the impressive levels of customer satisfaction. To further increase employee engagement, the Company introduced two employee share schemes (one of which is an all-employee scheme) in connection with admission to trading on AIM in November 2021.'
        },
        {
            uuid: "9",
            heading: 'Principle 9: Maintain governance structures and processes that are fit for purpose and support good decision-making by the Board',
            content: 'Whilst the Board is collectively responsible for defining corporate governance arrangements, the Chair is ultimately responsible for corporate governance. The governance structures within the Company have been assessed by the Board and are considered appropriate for the size, complexity and risk profile of the Company and, furthermore, that the QCA Corporate Governance Code is the appropriate recognised UK corporate governance code for the Company to adopt. This is periodically reviewed by the Board to ensure governance arrangements continue to be appropriate as the Company changes over time.' +
                '\n\nThe Board meets formally at least four times throughout the year and the Board and its Committees receive appropriate and timely information prior to each meeting. The Company Secretary has worked with the Board and Committees to develop annual agenda cycles to ensure appropriate time and focus is dedicated to relevant matters.' +
                '\n\nMeetings are minuted by the Company Secretary and any specific actions are documented, with responsibility assigned, and followed up at subsequent meetings until the matter is concluded.' +
                '\n\nThere is a formal schedule of matters reserved for the decision of the Board which is available to view on the Governance page.' +
                '\n\nThe Chair and Chief Executive Officer have clearly defined roles and responsibilities, with the role of the Chair being to lead the Board and ensure it is operating effectively in setting and monitoring the strategic direction of the Company.' +
                '\n\nThe role of the Chief Executive Officer is to propose strategic direction to the Board and to execute the approved strategy by leading the executive team in managing the Company’s business. The split of responsibilities between the Chair and CEO is available to view on the Corporate Governance page. The Board is supported by an Audit and Risk Committee, Remuneration Committee and Nomination Committee.' +
                '\n\nAudit and Risk Committee' +
                '\n\nThe Audit and Risk Committee has responsibility for monitoring the integrity of the Group’s financial statements, reviewing significant financial reporting issues, reviewing the effectiveness of the Group’s internal control and risk management systems, monitoring the internal and external audit process, and overseeing the relationship with the external auditor (including advising on their appointment, agreeing the scope of the audit and reviewing the audit findings).' +
                '\n\nThe Audit and Risk Committee meets at least three times a year at appropriate times in the reporting and audit cycle and otherwise as required. The Audit and Risk Committee also has unrestricted access to the Group’s external auditors.'+
                '\n\nRemuneration Committee' +
                '\n\nThe Remuneration Committee has responsibility for determining and agreeing with the Board the framework for the remuneration of the Executive Directors and other designated senior executives and, within the terms of the agreed framework, determining the total individual remuneration packages of the Executive Directors including, where appropriate, bonuses, incentive payments and share options or other share awards. The remuneration of Non-Executive Directors will be a matter for the Board. No Director will be involved in any decision as to his or her own remuneration.' +
                '\n\nThe Remuneration Committee meets at least two times a year and otherwise as necessary.' +
                '\n\nNomination Committee' +
                '\n\nThe Nomination Committee has responsibility for reviewing the structure, size and composition (including the skills, knowledge and experience) of the Board and giving full consideration to succession planning. The Nomination Committee also has responsibility for recommending new appointments to the Board and to the other Board Committees. It is responsible for identifying suitable candidates for board membership and monitors the performance and suitability of the current Board on an ongoing basis.'  +
                '\n\nThe Nomination Committee meets at least once a year and otherwise as necessary.' +
                '\n\nFurther information and Terms of Reference of the Committees are set out on the Corporate Governance page of this website.'+
                '\n\nThe Board acknowledges that the new 2023 Quoted Companies Alliance Code (2023 Code) will apply to the Company from the financial year commencing on 1 April 2024 and the Company intends to comply with the 2023 Code following the 12-month transition period. '
        },
        {
            uuid: "10",
            heading: 'Principle 10: Communicate how the Company is governed and is performing by maintaining a dialogue with shareholders and other relevant stakeholders',
            content: 'As stated above, the Board is committed to maintaining effective communication and having constructive dialogue with its shareholders. The company aspires to have a close ongoing relationship with its private shareholders, institutional shareholders and analysts and for them to have the opportunity to discuss issues and provide feedback at meetings with the Company.' +
             ' Communication is made primarily through the Company’s regulatory announcements, website disclosures, the annual report and accounts, the AGM and one-to-one meetings with large existing shareholders and potential new institutional investors.' +
             '\n\nThe Board maintains that, if there is a resolution passed at a general meeting with 20% votes cast against, the Company will seek to understand the reason for the result and, where appropriate, take suitable action. Notices of general meetings, including the notice of the 2024 AGM, and details of the Company’s 2023 AGM results can be found on the Shareholder Information page of this website. All 2023 AGM resolutions were passed comfortably. The votes on all resolutions were taken on a poll to ensure that full shareholder representation was reflected.' +
             '\n\nThe Company’s annual and interim reports are available on the Results, Reports & Presentations page of this website.'
        }
    ]

    mainHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='govContainer2'>
                    <h2 className='mobileShareholderMainHeader'>Governance</h2>
                    <header className='mobileShareholderText1'>The Company’s ordinary shares are admitted to trading on the AIM, London Stock Exchange's market for small and medium size growth companies.

                        The Board is committed to high standards of corporate governance and has chosen to comply with the Quoted Companies Alliance Corporate Governance Code (QCA Code).

                        The main principles of the QCA Code are embedded into the corporate governance framework that the company follows. The Board recognises the importance of high standards of corporate governance and believes that the success of the company is enhanced by a strong corporate governance framework.
                    </header>
                </Container>
            )
        }
        else {
            return (
                <Container className='govContainer1'>
                    <h2 className='govHeader'>Governance</h2>
                    <header style={{'margin-top':'3%'}} className='govSubHeader'>The Company’s ordinary shares are admitted to trading on the AIM, London Stock Exchange's market for small and medium size growth companies.</header>
                    <header className='govSubHeader'>The Board is committed to high standards of corporate governance and has chosen to comply with the Quoted Companies Alliance Corporate Governance Code (QCA Code).</header>
                    <header className='govSubHeader'>The main principles of the QCA Code are embedded into the corporate governance framework that the company follows. The Board recognises the importance of high standards of corporate governance and believes that the success of the company is enhanced by a strong corporate governance framework.</header>
                </Container>
            )
        }
    }

    accordion = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='govContainer2'>
                    <h2 className='mobileShareholderMainHeader'>QCA code</h2>
                    <Accordion allowMultipleExpanded={false} preExpanded={['1']} allowZeroExpanded={true}>
                        {this.items.map((item) => (
                            <AccordionItem uuid={item.uuid}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {item.heading}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    {item.content}
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </Container>
            )
        }
        else {
            return (
                <Container className='govContainer1'>
                    <h2 className='govHeader'>QCA code</h2>
                    <Accordion allowMultipleExpanded={false} preExpanded={['1']} allowZeroExpanded={true}>
                        {this.items.map((item) => (
                            <AccordionItem uuid={item.uuid}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <header style={{'width':'80%'}}>{item.heading}</header>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <header style={{'width':'80%'}}>{item.content}</header>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </Container>
            )
        }
    }

    policies = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row>
                    <Container className='mobileEsgContainer4'>
                        <h2 className='mobileShareholderMainHeader'>Policies</h2>
                        <Grid stackable divided='vertically' className='mobileEsgDataGrid'>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarHeadline'>DATE</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarHeadline'>TITLE</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarDate'>5 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarBody'>Audit and Risk Committee Terms of Reference</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("MRK - Terms of Reference - Audit and Risk Committee - 2021 - FINAL.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarDate'>5 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarBody'>Nomination Committee Terms of Reference</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("MRK - Terms of Reference - Nomination Committee - 2024.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarDate'>5 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarBody'>Remuneration Committee Terms of Reference</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("MRK - Terms of Reference - Remuneration Committee - 2024.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarDate'>5 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarBody'>Matters Reserved for the Board</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("MRK - Matters Reserved for the Board - 2021 - FINAL.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarDate'>5 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns3'>
                                    <header className='esgCalendarBody'>Chair/CEO Split of Responsibilities</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("MRK - Chair CEO Split of Responsibilities - 2021 - FINAL.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row>
                    <Container style={{'margin-top':'0'}} className='shareholderContainer1'>
                        <h2 className='h2'>Policies</h2>
                        <Grid stackable divided='vertically' className='esgDataGrid'>
                            <Grid.Row style={{'padding-bottom':'0'}}>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarHeadline'>DATE</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarHeadline'>TITLE</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarHeadline'>DOCUMENT</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>5 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>Audit and Risk Committee Terms of Reference</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <button className='fileDownload' onClick={() => this.handleClick("MRK - Terms of Reference - Audit and Risk Committee - 2021 - FINAL.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>5 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>Nomination Committee Terms of Reference</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <button className='fileDownload' onClick={() => this.handleClick("MRK - Terms of Reference - Nomination Committee - 2024.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>5 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>Remuneration Committee Terms of Reference</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <button className='fileDownload' onClick={() => this.handleClick("MRK - Terms of Reference - Remuneration Committee - 2024.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>5 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>Matters Reserved for the Board</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <button className='fileDownload' onClick={() => this.handleClick("MRK - Matters Reserved for the Board - 2021 - FINAL.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>5 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>Chair/CEO Split of Responsibilities</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <button className='fileDownload' onClick={() => this.handleClick("MRK - Chair CEO Split of Responsibilities - 2021 - FINAL.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
    }

    governanceFrameWork = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='govContainer2'>
                    <h2 className='mobileShareholderMainHeader'>Governance framework</h2>
                    <header className='mobileShareholderHeader'>Purpose of the Board</header>
                    <header className='mobileShareholderText1'>The Board is responsible for formulating, reviewing and approving the Group’s strategy. In addition, it has overall responsibility for the budget and strategic plan. The Board recognises the importance of high standards of corporate governance and supports the principles contained in the QCA Code.</header>
                    <header className='mobileShareholderText1'>The Board has delegated responsibilities to the Audit and Risk, Remuneration and Nomination Committees.</header>
                    <header className='mobileShareholderText1'>David Wilkinson chairs the Audit and Risk Committee, with Marnie Millard as the other member.</header>
                    <header className='mobileShareholderText1'>Alyson Fadil chairs the Remuneration Committee, with David Wilkinson as the other member.</header>
                    <header className='mobileShareholderText1'>Marnie Millard chairs the Nomination Committee with Alyson Fadil as the other member.</header>
                    <header className='mobileShareholderText1'>Terms of Reference for each of the Committees which detail their primary responsibilities, as well as articles of association are available to view below:</header>
                    {this.DocButton("Audit and Risk Committee Terms of Reference", "audit", "MRK - Terms of Reference - Audit and Risk Committee - 2021 - FINAL.pdf")}
                    {this.DocButton("Remuneration Committee Terms of Reference", "renum", "MRK - Terms of Reference - Remuneration Committee - 2024.pdf")}
                    {this.DocButton("Nomination Committee Terms of Reference", "nom", "MRK - Terms of Reference - Nomination Committee - 2024.pdf")}
                    {this.DocButton("Articles of Association", "assoc", "Marks Electrical Holding Limited - Memorandum and Articles.pdf")}
                    <header className='mobileShareholderHeader'>Compliance with the QCA Code</header>
                    <header className='mobileShareholderText1'>The Code is based on 10 principles, aimed at delivering growth, maintaining a dynamic framework for management and building trust for all stakeholders. The table below provides an explanation of how Marks Electrical applies the principles of the QCA Code. Further information on the Board’s corporate governance procedures can be found below.</header>
                    {this.Button("Leadership", "leadership", "/leadership")}
                </Container>
            )
        }
        else {
            return (
                <Container style={{'margin-top':'0'}} className='govContainer1'>
                    <h2 className='govHeader'>Governance framework</h2>
                    <header className='govSubMainHeader'>Purpose of the Board</header>
                    <header className='govSubHeader'>The Board is responsible for formulating, reviewing and approving the Group’s strategy. In addition, it has overall responsibility for the budget and strategic plan. The Board recognises the importance of high standards of corporate governance and supports the principles contained in the QCA Code.</header>
                    <header className='govSubHeader'>The Board has delegated responsibilities to the Audit and Risk, Remuneration and Nomination Committees.</header>
                    <header className='govSubHeader'>David Wilkinson chairs the Audit and Risk Committee, with Marnie Millard as the other member.</header>
                    <header className='govSubHeader'>Alyson Fadil chairs the Remuneration Committee, with David Wilkinson and Marnie Millard as the other members.</header>
                    <header className='govSubHeader'>Marnie Millard chairs the Nomination Committee with Alyson Fadil as the other member.</header>
                    {this.Button("Leadership", "leadership", "/leadership")}
                    <header style={{'padding-top':'3%'}} className='govSubHeader'>Terms of Reference for each of the Committees which detail their primary responsibilities, as well as articles of association are available to view below:</header>
                    {this.DocButton("Audit and Risk Committee Terms of Reference", "audit", "MRK - Terms of Reference - Audit and Risk Committee - 2021 - FINAL.pdf")}
                    {this.DocButton("Remuneration Committee Terms of Reference", "renum", "MRK - Terms of Reference - Remuneration Committee - 2024.pdf")}
                    {this.DocButton("Nomination Committee Terms of Reference", "nom", "MRK - Terms of Reference - Nomination Committee - 2024.pdf")}
                    {this.DocButton("Articles of Association", "assoc", "Marks Electrical Holding Limited - Memorandum and Articles.pdf")}
                    <header className='govSubMainHeader'>Compliance with the QCA Code</header>
                    <header className='govSubHeader'>The Code is based on 10 principles, aimed at delivering growth, maintaining a dynamic framework for management and building trust for all stakeholders. The table below provides an explanation of how Marks Electrical applies the principles of the QCA Code. Further information on the Board’s corporate governance procedures can be found below.</header>
                </Container>
            )
        }
    }

    committeeHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='govContainer2'>
                    <h2 className='mobileShareholderMainHeader'>Committees</h2>
                </Container>
            )
        }
        else {
            return (
                <Container className='esgContainer4'>
                    <h2 className='h2'>Committees</h2>
                </Container>
            )
        }
    }

    render() {
        return (
            <div className='governance'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                    </Grid.Row>
                    <Grid.Row>
                        <header style={{'padding-left':'10%'}}>Last updated on 18 July 2024.</header>
                        {this.mainHeader()}
                    </Grid.Row>
                    <Grid.Row>
                        {this.governanceFrameWork()}
                    </Grid.Row>
                    <Grid.Row>
                        {this.accordion()}
                    </Grid.Row>
                    {this.policies()}
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}