import React, { useState } from 'react';
import {Link, withRouter} from 'react-router-dom';
import Dropdown from './Dropdown';
import "./Navbar.css";
import {Button, Image} from "semantic-ui-react";
import { MenuItems1 } from './MenuItems';
import { MenuItems2 } from './MenuItems';
import { MenuItems3 } from './MenuItems';

function Navbar(props) {
    const [click, setClick] = useState(false);
    const [dropdown1, setDropdown1] = useState(false);
    const [dropdown2, setDropdown2] = useState(false);
    const [dropdown3, setDropdown3] = useState(false);
    const [location, setLocation] = useState(null);
    const [dropdownActive1, setDropdownActive1] = useState(false);
    const [dropdownActive2, setDropdownActive2] = useState(false);
    const [dropdownActive3, setDropdownActive3] = useState(false);
    const [contactActive, setContactActive] = useState(false);
    const [careersActive, setCareersActive] = useState(false);

    React.useEffect(() => {
        setLocation(props.location.pathname)
        checkLocation()
    })

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const closeMobileMenu = () => setClick(false);

    function checkLocation() {
        if(location === '/about' || location === '/leadership'  || location === '/journey' ) {
            setDropdownActive1(true)
        }
        if(location === '/esg' || location === '/environment'  || location === '/community' || location === '/governance' ) {
            setDropdownActive2(true)
        }
        if(location === '/investors' || location === '/investmentcase' || location === '/howitworks'
            || location === '/ipo' || location === '/aim26' || location === '/regulatorynews'
            || location === '/shareholder' || location === '/results' ) {
            setDropdownActive3(true)
        }
        if(location === '/contact' ) {
            setContactActive(true)
        }
        if(location === '/careers') {
            setCareersActive(true)
        }
    }

    const onMouseEnter = (num) => {
        if (window.innerWidth < 960 && num === 1) {
            setDropdown1(false);
        }
        else if (window.innerWidth > 960 && num === 1) {
            setDropdown1(true);
        }
        else if (window.innerWidth < 960 && num === 2) {
            setDropdown2(false);
        }
        else if (window.innerWidth > 960 && num === 2) {
            setDropdown2(true);
        }
        else if (window.innerWidth < 960 && num === 3) {
            setDropdown3(false);
        }
        else {
            setDropdown3(true);
        }
    };

    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown1(false);
            setDropdown2(false);
            setDropdown3(false);
        } else {
            setDropdown1(false);
            setDropdown2(false);
            setDropdown3(false);
        }
    };

    return (
        <>
            <nav className='navbar'>
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    <Image className='logoGridDesktop' src={props.logo} size='small' as='a'/>
                </Link>
                <ul className='nav-menu'>
                    <li className='nav-item-top1'>
                        <Button onClick={() => openInNewTab("https://markselectrical.co.uk/")} basic size='mini' className={props.shopButton}>Shop With Us</Button>
                    </li>
                    <li className='nav-item-top2'>
                        <iframe title='small rns shareprice widget' className="mainHeaderSharePriceIFrame" src={props.sharePriceUrl}/>
                    </li>
                    <li
                        className='nav-item'
                        onMouseEnter={() => onMouseEnter(1)}
                        onMouseLeave={onMouseLeave}
                    >
                        <Link
                            to='/about'
                            className={ dropdownActive1 ? (`${props.navLinks}Active`)  : props.navLinks }
                            onClick={closeMobileMenu}
                        >
                            About us
                        </Link>
                        {dropdown1 && <Dropdown items={MenuItems1} name='dropdown-menu1'/>}
                    </li>
                    <li className='nav-item'
                        onMouseEnter={() => onMouseEnter(2)}
                        onMouseLeave={onMouseLeave}
                    >
                        <Link
                            to='/esg'
                            className={ dropdownActive2 ? (`${props.navLinks}Active`)  : props.navLinks }
                            onClick={closeMobileMenu}
                        >
                            ESG
                        </Link>
                        {dropdown2 && <Dropdown items={MenuItems2} name='dropdown-menu2'/>}
                    </li>
                    <li className='nav-item'
                        onMouseEnter={() => onMouseEnter(3)}
                        onMouseLeave={onMouseLeave}
                    >
                        <Link
                            to='/investors'
                            className={ dropdownActive3 ? (`${props.navLinks}Active`)  : props.navLinks }
                            onClick={closeMobileMenu}
                        >
                            Investors
                        </Link>
                        {dropdown3 && <Dropdown items={MenuItems3} name='dropdown-menu3'/>}
                    </li>
                    <li className='nav-item'>
                        <Link
                            to='/contact'
                            className={ contactActive ? (`${props.navLinks}Active`) : props.navLinks }
                            onClick={closeMobileMenu}
                        >
                            Contact us
                        </Link>
                    </li>

                    <li className='nav-item'>
                        <Link
                            to='/careers'
                            className={ careersActive ? (`${props.navLinks}Active`)  : props.navLinks }
                            onClick={closeMobileMenu}
                        >
                            Careers
                        </Link>
                    </li> 

                </ul>
            </nav>
        </>
    );
}

export default withRouter(Navbar);