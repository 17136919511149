import React, {Component} from "react";
import {Container, Grid, Image} from "semantic-ui-react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";

import SearchImageWhite from "../Images/Group 8085.svg";
import MainImage from "../Images/careersHeader.png";
import MobileMainImage from "../Images/careersMobileImg.png";
import Footer from "./Footer";
import Arrow from "../Images/Layer 2_blue.svg";
import "./Careers.css";
import BodyImg from "../Images/CareersBody.png";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import "./Accordian.css";

export default class Careers extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    items = [
        {
            uuid: "1",
            heading: 'Ethical trading policy',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. In ornare quam viverra orci sagittis eu volutpat odio. Duis convallis convallis tellus id interdum. Sed risus ultricies tristique nulla aliquet enim tortor at. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. Magna fermentum iaculis eu non diam phasellus vestibulum lorem. Nunc congue nisi vitae suscipit tellus. Nullam vehicula ipsum a arcu cursus vitae congue. Justo nec ultrices dui sapien eget mi. Etiam sit amet nisl purus in mollis nunc sed id. Ut aliquam purus sit amet. Odio facilisis mauris sit amet massa vitae. Erat imperdiet sed euismod nisi porta lorem mollis aliquam ut. Nisl nisi scelerisque eu ultrices vitae auctor eu augue ut. Elit at imperdiet dui accumsan sit amet nulla facilisi morbi. Scelerisque varius morbi enim nunc. Est pellentesque elit ullamcorper dignissim cras tincidunt. Lacinia quis vel eros donec ac odio tempor orci.'
        },
        {
            uuid: "2",
            heading: 'Preventing hidden labour exploitation',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. In ornare quam viverra orci sagittis eu volutpat odio. Duis convallis convallis tellus id interdum. Sed risus ultricies tristique nulla aliquet enim tortor at. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. Magna fermentum iaculis eu non diam phasellus vestibulum lorem. Nunc congue nisi vitae suscipit tellus. Nullam vehicula ipsum a arcu cursus vitae congue. Justo nec ultrices dui sapien eget mi. Etiam sit amet nisl purus in mollis nunc sed id. Ut aliquam purus sit amet. Odio facilisis mauris sit amet massa vitae. Erat imperdiet sed euismod nisi porta lorem mollis aliquam ut. Nisl nisi scelerisque eu ultrices vitae auctor eu augue ut. Elit at imperdiet dui accumsan sit amet nulla facilisi morbi. Scelerisque varius morbi enim nunc. Est pellentesque elit ullamcorper dignissim cras tincidunt. Lacinia quis vel eros donec ac odio tempor orci.'
        }
    ]

    openInNewTab = (newWindow, url) => {
        newWindow.location = url;
    }

    fetchSignedUrl = async(newWindow, file) => {
        await Promise.resolve(fetch(`https://group.markselectrical.co.uk/document?name=${file}`)
            .then(response => response.json())
            .then((data) => {
                this.setState({docUrls: data} );
            })
            .catch(console.log))

        if(this.state.docUrls.length > 0){
            this.openInNewTab(newWindow, this.state.docUrls[0].url);
        }
        else{
            console.log("No url retrieved!")
        }
    }

    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    Button = (text, tag) => {
        return (
            <Container className='careersButtonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <button className='recyclingButton'><div className='buttonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></button>
            </Container>
        );
    }

    handleClick = (file) => {
        const newWindow = window.open();
        this.fetchSignedUrl(newWindow, file);
    }

    handleLinkClick = (url) => {
        const newWindow = window.open();
        this.openInNewTab(newWindow, url)
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    accordion = () => {
        if(this.props.mobileSite === true) {
            return (
                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                    {this.items.map((item) => (
                        <AccordionItem uuid={item.uuid}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {item.heading}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                {item.content}
                            </AccordionItemPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            )
        }
        else {
            return (
                <Container className='careersContainer2'>
                    <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                        {this.items.map((item) => (
                            <AccordionItem uuid={item.uuid}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {item.heading}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    {item.content}
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </Container>
            )
        }
    }

    mainGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid stackable className='mobileCareersGrid'>
                    <Grid.Row style={{'margin-top':'8%'}}>
                        <h2 className='mobileShareholderMainHeader'>Interested in working at Marks Electrical?</h2>
                    </Grid.Row>
                    <Grid.Row>
                        <header className='mobileCareersBody'>As our company grows, we are always looking for innovative, committed people to add to our team. Our people are our biggest assets and we believe that working together and supporting our employees is the best way forward.</header>
                    </Grid.Row>
                    <Grid.Row>
                        <img className='mobileCareersImage' src={BodyImg} alt='office'/>
                    </Grid.Row>
                    <Grid.Row>
                        <header className='mobileCareersBody'>We have a number of exciting roles which we are recruiting for, for more information please click on the below links.</header>
                    </Grid.Row>
                    <Grid.Row>
                        <h2 className='mobileShareholderMainHeader'>Vacancies</h2>
                    </Grid.Row>
                    <Grid.Row>
                        {/* <header className='mobileCareersSubHeader'>Customer Services Advisor </header>
                        <text style={{'padding-bottom':'3%'}} className='mobileCareersBoldBody'>The role – it’s all about the customer experience!</text>
                        <text style={{'padding-bottom':'3%'}} className='mobileCareersBody'>Reporting to the Customer Service Manager your main responsibilities will be:</text>
            */}         <ul className='journeyUl'>
                            <li className='journeyLi'><button className='fileDownload' onClick={() => this.handleClick("marks-electrical-customer-service-advisor.pdf")}><text className='mobileCareersBody'>Customer Service Advisor</text></button></li>
                            <li className='journeyLi'><button className='fileDownload' onClick={() => this.handleClick("marks-electrical-marketing-executive.pdf")}><text className='mobileCareersBody'>Marketing Executive</text></button></li>
                            <li className='journeyLi'><button className='fileDownload' onClick={() => this.handleClick("marks-electrical-graphic-designer.pdf")}><text className='mobileCareersBody'>Graphic Designer</text></button></li>
                            <li className='journeyLi'><button className='fileDownload' onClick={() => this.handleClick("marks-electrical-accounts-payable-manager.pdf")}><text className='mobileCareersBody'>Accounts Payable Manager</text></button></li>
                            </ul>
                        <text style={{'padding-top':'3%'}} className='mobileCareersBody'>If you are interested in applying for any of these roles, please email a copy of your CV to recruitment@markselectrical.co.uk</text>
                  { /*     <text style={{'padding-bottom':'3%'}} className='mobileCareersBody'>We are looking for someone with a genuine desire to always help customers in their actions and the tone they use with the
                            experience of communicating with customers effectively via various channels. You must have excellent communication and
                            interpersonal skills to be able to deal diplomatically with a range of people inside and outside of the business.
                            An exceptional attention to detail, with proven general IT skills and someone who can work individually, or part of a close-knit
                            team is a must.
                        </text>
                        <text style={{'padding-bottom':'3%'}} className='mobileCareersBody'>Hours of work are Monday to Friday 9am to 5:30pm with the need to work two weekend days per four week period.</text>
                        <text className='mobileCareersBody'>If you think you have the necessary skills and experience then email your CV with a covering letter to recruitment@markselectrical.co.uk  explaining why you think you’re suitable for the role.</text>
                     */}</Grid.Row>
                </Grid>
            )
        }
        else {
            return (
                <Container className='careersContainer1'>
                    <Grid stackable className='aimDataGrid'>
                        <Grid.Row>
                            <h2 className='h2'>Interested in working at Marks Electrical?</h2>
                        </Grid.Row>
                        <Grid.Row>
                            <header className='careersBody'>As our company grows, we are always looking for innovative, committed people to add to our team. Our people are our biggest assets and we believe that working together and supporting our employees is the best way forward.</header>
                        </Grid.Row>
                        <Grid.Row>
                            <img style={{'width':'100%'}} src={BodyImg} alt='office'/>
                        </Grid.Row>
                        <Grid.Row>
                            <header className='careersBody'>We have a number of exciting roles which we are recruiting for, for more information please click on the below links.</header>
                        </Grid.Row>
                        <Grid.Row>
                            <Container className='careersTitle'>
                                <h2 className='h2'>Vacancies</h2>
                            </Container>
                        </Grid.Row>
                        <Grid.Row>
                           {/* <header className='careersSubHeader'>Customer Services Advisor </header>
                            <text style={{'padding-bottom':'3%', 'width':'100%'}} className='careersBoldBody'>The role – it’s all about the customer experience!</text>
                            <text style={{'padding-bottom':'3%'}} className='careersBody'>Reporting to the Customer Service Manager your main responsibilities will be:</text>
                        */ }<ul className='journeyUl'> 
                                <li className='journeyLi'><button className='fileDownload' onClick={() => this.handleClick("marks-electrical-customer-service-advisor.pdf")}><text className='careersBody'>Customer Service Advisor</text></button></li>
                                <li className='journeyLi'><button className='fileDownload' onClick={() => this.handleClick("marks-electrical-marketing-executive.pdf")}><text className='careersBody'>Marketing Executive</text></button></li>
                                <li className='journeyLi'><button className='fileDownload' onClick={() => this.handleClick("marks-electrical-graphic-designer.pdf")}><text className='careersBody'>Graphic Designer</text></button></li>
                                <li className='journeyLi'><button className='fileDownload' onClick={() => this.handleClick("marks-electrical-accounts-payable-manager.pdf")}><text className='careersBody'>Accounts Payable Manager</text></button></li>
                                </ul>
                              
                           <text style={{'padding-top':'3%'}} className='careersBody'>If you are interested in applying for any of these roles, please email a copy of your CV to recruitment@markselectrical.co.uk</text>
                           {/*  <text style={{'padding-bottom':'3%'}} className='careersBody'>We are looking for someone with a genuine desire to always help customers in their actions and the tone they use with the
                                experience of communicating with customers effectively via various channels. You must have excellent communication and
                                interpersonal skills to be able to deal diplomatically with a range of people inside and outside of the business.
                                An exceptional attention to detail, with proven general IT skills and someone who can work individually, or part of a close-knit
                                team is a must.
                            </text>
                            <text style={{'padding-bottom':'3%'}} className='careersBody'>Hours of work are Monday to Friday 9am to 5:30pm with the need to work two weekend days per four week period.</text>
                            <text className='careersBody'>If you think you have the necessary skills and experience then email your CV with a covering letter to recruitment@markselectrical.co.uk  explaining why you think you’re suitable for the role.</text>
                             */ }
                        </Grid.Row>
                    </Grid>
                </Container>
            )
        }
    }
    render() {
        return (
            <div className='careers'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                    </Grid.Row>
                    <Grid.Row>
                        {this.mainGrid()}
                    </Grid.Row>
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}