import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import {Container, Grid, Image} from "semantic-ui-react";
import MainImage from "../Images/press-page-header.png";
import MobileMainImage from "../Images/press-page-header-mobile.png";
import Footer from "./Footer";
import jQuery from "jquery";
import Arrow from "../Images/Layer 2_blue.svg";

export default class Press extends Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.updateWidgetHeight = this.updateWidgetHeight.bind(this);
    };

    updateWidgetHeight(event) {
        const frames = document.getElementsByTagName('iframe');
        for (let i = 0; i < frames.length; i++) {
            if (frames[i].contentWindow === event.source) {
                jQuery(frames[i]).height(event.data);
                break;
            }
        }
    }

    componentDidMount() {
        window.addEventListener('message', this.updateWidgetHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.updateWidgetHeight)
    }

    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    openInNewTab = (url) => {
        const newWindow = window.open();
        newWindow.location = url;
    }

    Button = (text, tag, url) => {
        return (
            <Container className='buttonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <button className='recyclingButton' onClick={() => this.openInNewTab(url)}><div className='buttonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></button>
            </Container>
        );
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='resultsContainer2'>
                    <h2 className='mobileResultsHeader'>Press</h2>
                </Container>
            )
        }
        else {
            return (
                <Container className='regContainer2'>
                    <h2 className='h2'>Press</h2>
                </Container>
            )
        }
    }

    mainGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row>
                    <Container className='mobileEsgContainer4'>
                        <Grid stackable divided='vertically' className='mobileEsgDataGrid'>

                        <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    April 2023
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical boss Mark Smithson on keeping its edge in a difficult market | Interview | Retail Week (retail-week.com)</header>
                                    {this.Button("OPEN LINK", "1", 'https://www.retail-week.com/people/marks-electrical-boss-mark-smithson-on-keeping-its-edge-in-a-difficult-market/7043527.article?authent=1')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Air fryers and coffee machines send white goods retailer's shares soaring Marks Electrical hails air fryer sales for 21.5 per cent rise in revenues (cityam.com)</header>
                                    {this.Button("OPEN LINK", "2", 'https://www.cityam.com/marks-electrical-hails-air-fryer-sales-for-21-5-per-cent-rise-in-revenues/')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Record revenues for Marks Electrical as sales near £100m - Business Live (business-live.co.uk)</header>
                                    {this.Button("OPEN LINK", "3", 'https://www.business-live.co.uk/retail-consumer/record-revenues-marks-electrical-sales-26679246')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical revenues soar to a record £97.8 million - Retail Gazette</header>
                                    {this.Button("OPEN LINK", "4", 'https://www.retailgazette.co.uk/blog/2023/04/marks-electrical-hails-record-sales/')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical hails strong business model as revenues soar | News | Retail Week (retail-week.com)</header>
                                    {this.Button("OPEN LINK", "5", 'https://www.retail-week.com/electricals/marks-electrical-hails-strong-business-model-as-revenues-soar/7043517.article?authent=1')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical momentum continues - UK Investor Magazine</header>
                                    {this.Button("OPEN LINK", "6", 'https://ukinvestormagazine.co.uk/marks-electrical-momentum-continues-2/')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Discover why Marks Electrical shares are full of spark today | Shares Magazine</header>
                                    {this.Button("OPEN LINK", "7", 'https://www.sharesmagazine.co.uk/news/shares/discover-why-marks-electrical-shares-are-full-of-spark-today')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical reports record FY revenue despite "challenging" market (chargedretail.co.uk)</header>
                                    {this.Button("OPEN LINK", "8", 'https://www.chargedretail.co.uk/2023/04/12/marks-electrical-fy-revenue/')}
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    February 2023
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Best ovens 2023: Top built-in electric, gas and mini oven alternatives | Evening Standard</header>
                                    {this.Button("OPEN LINK", "1", 'https://www.standard.co.uk/shopping/esbest/home-garden/kitchen-appliances/best-ovens-electric-gas-and-mini-oven-b900499.html')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>‘Most effective’ way to remove limescale from kettles - reduces ‘cost of your bills’ | Express.co.uk</header>
                                    {this.Button("OPEN LINK", "2", 'https://www.express.co.uk/life-style/property/1731884/how-to-clean-kettles-limescale-white-vinegar-lemon-household-appliance')}
                                </Grid.Column>
                            </Grid.Row>


                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    January 2023
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Strong trading momentum continues at Leicester online electrical retailer - East Midlands Business Link</header>
                                    {this.Button("OPEN LINK", "1", 'https://www.eastmidlandsbusinesslink.co.uk/mag/finance/strong-trading-momentum-continues-at-leicester-online-electrical-retailer/')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Demand for low energy appliances drives festive growth at Marks Electricals - Business Live (business-live.co.uk)</header>
                                    {this.Button("OPEN LINK", "2", 'https://www.business-live.co.uk/retail-consumer/demand-low-energy-appliances-drives-25945099')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical Christmas sales jump as energy-efficient products soar - Retail Gazette</header>
                                    {this.Button("OPEN LINK", "3", 'https://www.retailgazette.co.uk/blog/2023/01/marks-electrical-sales/')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical outstrips rivals - UK Investor Magazine</header>
                                    {this.Button("OPEN LINK", "4", 'https://ukinvestormagazine.co.uk/mark-electricals-outstrips-rivals/')}
                                </Grid.Column>
                            </Grid.Row>


                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    December 2022
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Local pub served enterprising spirits and I drank it all in to take off in retail (thetimes.co.uk)</header>
                                    {this.Button("OPEN LINK", "1", 'https://www.thetimes.co.uk/article/local-pub-served-enterprising-spirits-and-i-drank-it-all-in-to-take-off-in-retail-ndb8rhx90')}
                                </Grid.Column>
                            </Grid.Row>


                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    November 2022
                                </Grid.Column>
                            </Grid.Row>


                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical boss: We're winning spend while John Lewis 'neglect their customers' | News | Retail Week (retail-week.com)</header>
                                    {this.Button("OPEN LINK", "1", 'https://www.retail-week.com/electricals/marks-electrical-boss-were-winning-spend-while-john-lewis-neglect-their-customers/7042686.article?authent=1')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical reports boom in energy efficient laundry machine sales | The Independent</header>
                                    {this.Button("OPEN LINK", "2", 'https://www.independent.co.uk/business/marks-electrical-reports-boom-in-energy-efficient-laundry-machine-sales-b2220148.html')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Online retailer Marks Electrical delivers double-digit revenue growth despite economic volatilities (cityam.com)</header>
                                    {this.Button("OPEN LINK", "3", 'https://www.cityam.com/online-retailer-marks-electrical-delivers-double-digit-revenue-growth-despite-economic-volatilities/?utm_source=dlvr.it&utm_medium=twitter')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical reports boom in energy efficient laundry machine sales | Yorkshire Post</header>
                                    {this.Button("OPEN LINK", "4", 'https://www.yorkshirepost.co.uk/business/marks-electrical-reports-boom-in-energy-efficient-laundry-machine-sales-3909870')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical boss: We're winning spend while John Lewis 'neglect their customers' | News | Retail Week (retail-week.com)</header>
                                    {this.Button("OPEN LINK", "5", 'https://www.retail-week.com/electricals/marks-electrical-boss-were-winning-spend-while-john-lewis-neglect-their-customers/7042686.article?authent=1')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical's strong start to the second half - UK Investor Magazine</header>
                                    {this.Button("OPEN LINK", "6", 'https://ukinvestormagazine.co.uk/marks-electricals-strong-start-to-the-second-half/')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Experts compare AO with Marks Electrical and ask if it's 'out of the woods' or still 'vulnerable' - Business Live (business-live.co.uk)</header>
                                    {this.Button("OPEN LINK", "7", 'https://www.business-live.co.uk/retail-consumer/experts-compare-ao-marks-electrical-25579316')}
                                </Grid.Column>
                            </Grid.Row>


                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    October 2022
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical boss on how his business is the filling gap left by the big retailers - Business Live (business-live.co.uk)</header>
                                    {this.Button("OPEN LINK", "1", 'https://www.business-live.co.uk/retail-consumer/marks-electrical-boss-how-business-25235273')}
                                </Grid.Column>
                            </Grid.Row>


                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    August 2022
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electricals sales on the up despite tough market - Business Live (business-live.co.uk)</header>
                                    {this.Button("OPEN LINK", "1", 'https://www.business-live.co.uk/retail-consumer/marks-electricals-sales-up-despite-24727722')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical posts double digit revenue growth despite “challenging” market backdrop - Retail Gazette</header>
                                    {this.Button("OPEN LINK", "2", 'https://www.retailgazette.co.uk/blog/2022/08/marks-electrical-posts-double-digit-revenue-growth-despite-challenging-market-backdrop/')}
                                </Grid.Column>
                            </Grid.Row>


                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    June 2022
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical boss says home appliance prices dropping - Business Live (business-live.co.uk)</header>
                                    {this.Button("OPEN LINK", "1", 'https://www.business-live.co.uk/retail-consumer/marks-electrical-boss-says-home-24175250')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical up 2.2% as market share gains and maiden dividend please | Shares Magazine</header>
                                    {this.Button("OPEN LINK", "2", 'https://www.sharesmagazine.co.uk/news/shares/marks-electrical-up-2-2-as-market-share-gains-and-maiden-dividend-please')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Home appliances firm Marks Electrical weathers cost of living storm | This is Money</header>
                                    {this.Button("OPEN LINK", "3", 'https://www.thisismoney.co.uk/money/markets/article-10897943/Home-appliances-firm-Marks-Electrical-weathers-cost-living-storm.html')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Marks Electrical ‘ready to weather cost-of-living crisis’ | The Independent</header>
                                    {this.Button("OPEN LINK", "4", 'https://www.independent.co.uk/business/marks-electrical-ready-to-weather-costofliving-crisis-b2096339.html')}
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    May 2022
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobilePressColumns'>
                                    <header className='esgCalendarDate'>Stick your head in the oven and buy Marks Electrical Group (thetimes.co.uk)</header>
                                    {this.Button("OPEN LINK", "1", 'https://www.thetimes.co.uk/article/stick-your-head-in-the-oven-and-buy-marks-electrical-group-xk85tcr3d')}
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Container className='ipoDocsContainer2'>
                        <Grid stackable divided='vertically' className='shareDataGrid'>
                            <Grid.Row style={{'padding-bottom':'0'}}>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='shareholderCalendarHeadline'>ARTICLE</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    <header className='shareholderCalendarHeadline'>LINK</header>
                                </Grid.Column>
                            </Grid.Row>
                
        <Grid.Row>
            <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                <header className='shareholderCalendarMonthHeadline'>April 2023</header>
            </Grid.Column>
        </Grid.Row>        

                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical boss Mark Smithson on keeping its edge in a difficult market | Interview | Retail Week (retail-week.com)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "1", 'https://www.retail-week.com/people/marks-electrical-boss-mark-smithson-on-keeping-its-edge-in-a-difficult-market/7043527.article?authent=1')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Air fryers and coffee machines send white goods retailer's shares soaring Marks Electrical hails air fryer sales for 21.5 per cent rise in revenues (cityam.com)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "2", 'https://www.cityam.com/marks-electrical-hails-air-fryer-sales-for-21-5-per-cent-rise-in-revenues/')}
                                </Grid.Column>
                            </Grid.Row>           
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Record revenues for Marks Electrical as sales near £100m - Business Live (business-live.co.uk)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "3", 'https://www.business-live.co.uk/retail-consumer/record-revenues-marks-electrical-sales-26679246')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical revenues soar to a record £97.8 million - Retail Gazette</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "4", 'https://www.retailgazette.co.uk/blog/2023/04/marks-electrical-hails-record-sales/')}
                                </Grid.Column>
                            </Grid.Row>     
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical hails strong business model as revenues soar | News | Retail Week (retail-week.com)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "5", 'https://www.retail-week.com/electricals/marks-electrical-hails-strong-business-model-as-revenues-soar/7043517.article?authent=1')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical momentum continues - UK Investor Magazine</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "6", 'https://ukinvestormagazine.co.uk/marks-electrical-momentum-continues-2/')}
                                </Grid.Column>
                            </Grid.Row>     
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Discover why Marks Electrical shares are full of spark today | Shares Magazine</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "7", 'https://www.sharesmagazine.co.uk/news/shares/discover-why-marks-electrical-shares-are-full-of-spark-today')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical reports record FY revenue despite "challenging" market (chargedretail.co.uk)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "8", 'https://www.chargedretail.co.uk/2023/04/12/marks-electrical-fy-revenue/')}
                                </Grid.Column>
                            </Grid.Row>  



        <Grid.Row>
            <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                <header className='shareholderCalendarMonthHeadline'>February 2023</header>
            </Grid.Column>
        </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Best ovens 2023: Top built-in electric, gas and mini oven alternatives | Evening Standard</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "1", 'https://www.standard.co.uk/shopping/esbest/home-garden/kitchen-appliances/best-ovens-electric-gas-and-mini-oven-b900499.html')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>‘Most effective’ way to remove limescale from kettles - reduces ‘cost of your bills’ | Express.co.uk</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "2", 'https://www.express.co.uk/life-style/property/1731884/how-to-clean-kettles-limescale-white-vinegar-lemon-household-appliance')}
                                </Grid.Column>
                            </Grid.Row>               

            <Grid.Row>
                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                    <header className='shareholderCalendarMonthHeadline'>January 2023</header>
                </Grid.Column>
            </Grid.Row>     
                             <Grid.Row>
                            <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Strong trading momentum continues at Leicester online electrical retailer - East Midlands Business Link</header>
                            </Grid.Column>
                            <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "1", 'https://www.eastmidlandsbusinesslink.co.uk/mag/finance/strong-trading-momentum-continues-at-leicester-online-electrical-retailer/')}
                            </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                            <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Demand for low energy appliances drives festive growth at Marks Electricals - Business Live (business-live.co.uk)</header>
                            </Grid.Column>
                            <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "2", 'https://www.business-live.co.uk/retail-consumer/demand-low-energy-appliances-drives-25945099')}
                            </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                            <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical Christmas sales jump as energy-efficient products soar - Retail Gazette</header>
                            </Grid.Column>
                            <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "3", 'https://www.retailgazette.co.uk/blog/2023/01/marks-electrical-sales/')}
                            </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                            <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical outstrips rivals - UK Investor Magazine</header>
                            </Grid.Column>
                            <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "4", 'https://ukinvestormagazine.co.uk/mark-electricals-outstrips-rivals/')}
                            </Grid.Column>
                            </Grid.Row>

            <Grid.Row>
                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                        <header className='shareholderCalendarMonthHeadline'>December 2022</header>
                </Grid.Column>
            </Grid.Row>     
                <Grid.Row>
                            <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Local pub served enterprising spirits and I drank it all in to take off in retail (thetimes.co.uk)</header>
                            </Grid.Column>
                            <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "1", 'https://www.thetimes.co.uk/article/local-pub-served-enterprising-spirits-and-i-drank-it-all-in-to-take-off-in-retail-ndb8rhx90')}
                            </Grid.Column>
                </Grid.Row>      


                <Grid.Row>
                    <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                        <header className='shareholderCalendarMonthHeadline'>November 2022</header>
                    </Grid.Column>
                </Grid.Row>


                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical boss: We're winning spend while John Lewis 'neglect their customers' | News | Retail Week (retail-week.com)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "1", 'https://www.retail-week.com/electricals/marks-electrical-boss-were-winning-spend-while-john-lewis-neglect-their-customers/7042686.article?authent=1')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical reports boom in energy efficient laundry machine sales | The Independent</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "2", 'https://www.independent.co.uk/business/marks-electrical-reports-boom-in-energy-efficient-laundry-machine-sales-b2220148.html')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Online retailer Marks Electrical delivers double-digit revenue growth despite economic volatilities (cityam.com)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "3", 'https://www.cityam.com/online-retailer-marks-electrical-delivers-double-digit-revenue-growth-despite-economic-volatilities/?utm_source=dlvr.it&utm_medium=twitter')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical reports boom in energy efficient laundry machine sales | Yorkshire Post</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "4", 'https://www.yorkshirepost.co.uk/business/marks-electrical-reports-boom-in-energy-efficient-laundry-machine-sales-3909870')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical boss: We're winning spend while John Lewis 'neglect their customers' | News | Retail Week (retail-week.com)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "5", 'https://www.retail-week.com/electricals/marks-electrical-boss-were-winning-spend-while-john-lewis-neglect-their-customers/7042686.article?authent=1')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical's strong start to the second half - UK Investor Magazine</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "6", 'https://ukinvestormagazine.co.uk/marks-electricals-strong-start-to-the-second-half/')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Experts compare AO with Marks Electrical and ask if it's 'out of the woods' or still 'vulnerable' - Business Live (business-live.co.uk)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "7", 'https://www.business-live.co.uk/retail-consumer/experts-compare-ao-marks-electrical-25579316')}
                                </Grid.Column>
                            </Grid.Row>

                <Grid.Row>
                    <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                        <header className='shareholderCalendarMonthHeadline'>October 2022</header>
                    </Grid.Column>
                </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical boss on how his business is the filling gap left by the big retailers - Business Live (business-live.co.uk)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "1", 'https://www.business-live.co.uk/retail-consumer/marks-electrical-boss-how-business-25235273')}
                                </Grid.Column>
                            </Grid.Row>


                <Grid.Row>
                    <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                        <header className='shareholderCalendarMonthHeadline'>August 2022</header>
                    </Grid.Column>
                </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electricals sales on the up despite tough market - Business Live (business-live.co.uk)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "1", 'https://www.business-live.co.uk/retail-consumer/marks-electricals-sales-up-despite-24727722')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical posts double digit revenue growth despite "challenging" market backdrop - Retail Gazette</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "2", 'https://www.retailgazette.co.uk/blog/2022/08/marks-electrical-posts-double-digit-revenue-growth-despite-challenging-market-backdrop/')}
                                </Grid.Column>
                            </Grid.Row>

                <Grid.Row>
                    <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                        <header className='shareholderCalendarMonthHeadline'>June 2022</header>
                    </Grid.Column>
                </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical boss says home appliance prices dropping - Business Live (business-live.co.uk)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "1", 'https://www.business-live.co.uk/retail-consumer/marks-electrical-boss-says-home-24175250')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical up 2.2% as market share gains and maiden dividend please | Shares Magazine</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "2", 'https://www.sharesmagazine.co.uk/news/shares/marks-electrical-up-2-2-as-market-share-gains-and-maiden-dividend-please')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Home appliances firm Marks Electrical weathers cost of living storm | This is Money</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "3", 'https://www.thisismoney.co.uk/money/markets/article-10897943/Home-appliances-firm-Marks-Electrical-weathers-cost-living-storm.html')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Marks Electrical 'ready to weather cost-of-living crisis' | The Independent</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "4", 'https://www.independent.co.uk/business/marks-electrical-ready-to-weather-costofliving-crisis-b2096339.html')}
                                </Grid.Column>
                            </Grid.Row>            

                <Grid.Row>
                    <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                        <header className='shareholderCalendarMonthHeadline'>May 2022</header>
                    </Grid.Column>
                </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName' style={{'width': '90%'}}>
                                    <header className='esgCalendarBody'>Stick your head in the oven and buy Marks Electrical Group (thetimes.co.uk)</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle' style={{'width': '10%'}}>
                                    {this.Button("OPEN LINK", "1", 'https://www.thetimes.co.uk/article/stick-your-head-in-the-oven-and-buy-marks-electrical-group-xk85tcr3d')}
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                </Container>
            )
        }
    }

    render() {
        return (
            <div className='ipoDocuments'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl="https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4"
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                    </Grid.Row>
                    <Grid.Row className='mobileInvestorsDataGrids'>
                        <Container className='ipoDocsContainer1'>
                            {this.mainHeader()}
                        </Container>
                        {this.mainGrid()}
                    </Grid.Row>
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}