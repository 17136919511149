import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    Container,
    Grid,
    Image
} from 'semantic-ui-react';
import React, {Component} from "react";
import Fader1 from '../Images/DSC_8006-edited-darker.png';
import Fader2 from '../Images/EMPLOYEES.png';
import Fader3 from '../Images/SUPPLIERS-1680x858.png';
import Fader4 from '../Images/DSC_8093-edited-darker.png';
import Fader5 from '../Images/COMMUNITY.png';
import MobileFader1 from "../Images/mobileHomePageImage.png";
import MobileFader2 from "../Images/mobileFaderImage2.png";
import MobileFader3 from "../Images/mobileFaderImage3.png";
import MobileFader4 from "../Images/mobileFaderImage4.png";
import MobileFader5 from "../Images/mobileFader5.png";
import MobileMain2 from "../Images/MicrosoftTeams-image-(4)-375X550.png";
import MobileMain3 from "../Images/MicrosoftTeams-image-375X550.png";
import Main2 from '../Images/newspapercutting.png';
import Main3 from '../Images/Group 8088.png';
import MainHeader from "./MainHeader";
import Fader from "./Fader";
import TrustBox from "./TrustBox";
import Footer from "./Footer";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg"
import Arrow from "../Images/invisible box.svg";
import {Link} from "react-scroll";
import {Link as LinkThrough} from "react-router-dom";
import ScrollArrow from "../Images/Scroll down_W.svg"
import jQuery from 'jquery'
import ReactPlayer from 'react-player'
import PlayButton from '../Images/videoPlayButton.svg';
import TvBackground from '../Images/tvBackground.png';

class App extends Component{
    constructor(props) {
        super(props);
        this.state = {}

        this.updateWidgetHeight = this.updateWidgetHeight.bind(this);
    };

    updateWidgetHeight(event) {
        const frames = document.getElementsByTagName('iframe');
        for (let i = 0; i < frames.length; i++) {
            if (frames[i].contentWindow === event.source) {
                jQuery(frames[i]).height(event.data);
                break;
            }
        }
    }

    componentDidMount() {
        window.addEventListener('message', this.updateWidgetHeight);

        const aScript = document.createElement('script');
        aScript.type = 'text/javascript';
        aScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        aScript.async = "true"
        document.head.appendChild(aScript);
        aScript.onload = function () {
            const trustbox = document.getElementById('trustbox');
            window.Trustpilot.loadFromElement(trustbox);
        };
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.updateWidgetHeight);
    }


    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    Button = (text, tag, link) => {
        return (
            <Container className='aboutUsButtonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <LinkThrough className='aboutUsButton' to={link}><div className='homeButtonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></LinkThrough>
            </Container>
        );
    }

    MobileButton = (text, tag, link) => {
        return (
            <Container className='mobileAboutUsButtonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <LinkThrough className='aboutUsButton' to={link}><div className='homeButtonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></LinkThrough>
            </Container>
        );
    }

    fader = () => {
      if(this.props.mobileSite === true) {
          return (
              <Fader Image1={MobileFader1} Image2={MobileFader2} Image3={MobileFader3}
                     Image4={MobileFader4} Image5={MobileFader5} andMe='mobileAndMe' you='mobileYou' faderName='mobileMainFader' mobileSite={this.props.mobileSite}/>
          )
      }
      else {
          return (
              <Fader Image1={Fader1} Image2={Fader2} Image3={Fader3} Image4={Fader4} Image5={Fader5} andMe='andMe' you='you' faderName='mainFader' mobileSite={this.props.mobileSite}/>
          )
      }
    }

    firstRow = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileAppFirstRow'>
                    <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                menuDropdown='whiteDropdown' searchImage={SearchImageWhite} item="block"
                                sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                navLinks='nav-links' hamburgerColour='white'/>
                    {this.fader()}
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='firstRow'>
                    <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                menuDropdown='whiteDropdown' searchImage={SearchImageWhite} item="block"
                                sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                navLinks='nav-links'/>
                    {this.fader()}
                </Grid.Row>
            )
        }
    }

  secondRow = () => {
      if (this.props.mobileSite === true){
          return (
              <Grid.Row className='homePageRows'>
                  <Image className='mainImage' src={MobileMain2}/>
                  <Container className='mobileMainCopy'>
                      <header className='small_title_w'>OUR JOURNEY</header>
                      <header className='mobileH1_w'>An approach that has proven to be timeless</header>
                  </Container>
                  <Container className='mobileSecondSubCopy'>
                      <h5 className='mobileSubCopy2'>Our sales channel and approach to advertising has changed over the years, but our commitment to customers hasn’t.</h5>
                      {this.MobileButton("OUR JOURNEY", "journey", "/journey")}
                  </Container>
              </Grid.Row>
          )
      }
      else {
          return (
              <Grid.Row className='homePageRows'>
                  <Image id="point1" className='mainImage' src={Main2}/>
                  <Container className='mainCopy'>
                      <header className='small_title_w'>OUR JOURNEY</header>
                      <Container className='spacedHeader1'>
                          <header className='h1_w'>An approach that has proven to be timeless</header>
                      </Container>
                  </Container>
                  <Container className='scrollArrow'>
                      <Link to="point2" spy={true} smooth={true}><Image className='scrollArrowImg2' src={ScrollArrow}/></Link>
                  </Container>
                  <Container className='secondSubCopy'>
                      <text className='h5_w'>Our sales channel and approach to advertising has changed over the years, but our commitment to customers hasn’t.</text>
                      {this.Button("OUR JOURNEY", "journey", "/journey")}
                  </Container>
              </Grid.Row>
          )
      }
  }

  thirdRow = () => {
      if (this.props.mobileSite === true) {
         return (
             <Grid.Row className='homePageRows'>
                 <Image className='mainImage' src={MobileMain3}/>
                 <Container className='mainCopyMobile2'>
                     <header className='small_title_w'>HOW IT WORKS</header>
                     <header className='mobileH1_w'>Customers first, second and third</header>
                 </Container>
                 <Container className='subCopy3'>
                     <header className='subCopy2TextMobile'>Get this right and it powers everything else</header>
                     <TrustBox className="mobiletrustpilot-widget"/>
                     {this.MobileButton("HOW IT WORKS", "works", "/howitworks")}
                 </Container>
             </Grid.Row>
         )
      }
      else {
          return (
              <Grid.Row className='homePageRows'>
                  <Image id="point2" className='mainImage' src={Main3}/>
                  <Container className='mainCopy2'>
                      <header className='small_title_w'>HOW IT WORKS</header>
                      <header className='h1_w'>Customers first, second and third</header>
                  </Container>
                  <Container className='scrollArrow'>
                      <Link to="point3" spy={true} smooth={true}><Image className='scrollArrowImg2' src={ScrollArrow}/></Link>
                  </Container>
                  <Container className='subCopy2'>
                      <header className='subCopy2Text'>Get this right and it powers everything else</header>
                      <TrustBox className="trustpilot-widget"/>
                      <Container className='howItWorksButtonContainer'>
                        {this.Button("HOW IT WORKS", "works", "/howitworks")}
                      </Container>
                  </Container>
              </Grid.Row>
          )
      }
  }

  sharePriceGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid className='mobileSharePriceGrid'>
                    <Grid.Row>
                        <Container className='mobileSharePriceHeaderContainer'>
                            <header className='sharePriceHeader'>SHARE PRICE</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row>
                        <Container className='mobileSharePriceWidgetContainer3'>
                            <iframe title='share price widget' className="mobileSharePriceIFrame" src="https://irtools.co.uk/tools/share_price/2aae5d7d-6d97-444a-b91a-a3545943f1d8"/>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='sharePriceRow2'>
                        <Container className='sharePriceWidgetContainer2'>
                            <header className='sharePriceHeader'>REGULATORY NEWS</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row>
                        <Container className='mobileSharePriceWidgetContainer3'>
                            <iframe title='rns widget' className="sharePriceIFrame2" src="https://irtools.co.uk/tools/news/9f5a6b3a-262c-4474-a2fb-f6c9489b81f1"/>
                        </Container>
                    </Grid.Row>
                    <Grid.Row>
                        <Container className='sharePriceButtonCont1'>
                            <LinkThrough className='shareholderFinancialCalenderButton' to="/regulatorynews">REGULATORY NEWS<img alt='shareholder button arrow' className='shareholderButtonArrow' src={Arrow}/></LinkThrough>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='sharePriceRow3'>
                        <Container className='sharePriceWidgetContainer2'>
                            <Grid stackable className='mobileSharePriceSubGrid'>
                                <Grid.Row>
                                    <Grid.Column className='sharePriceColumns'>
                                        <Grid.Row>
                                            <header className='sharePriceHeader2'>LATEST DOCUMENTS</header>
                                        </Grid.Row>
                                        <Grid.Row className='sharePriceBottomColumn'>
                                            <header className='sharePriceDate'>18 November 2021</header>
                                            <header className='mobileSharePriceBody'>Marks Electrical - FY22 Interim Results Presentation</header>
                                        </Grid.Row>
                                        <Container className='sharePriceButtonCont2'>
                                            <LinkThrough className='shareholderFinancialCalenderButton' to="/results">SEE MORE<img alt='shareholder button arrow' className='shareholderButtonArrow' src={Arrow}/></LinkThrough>
                                        </Container>
                                    </Grid.Column>
                                    <Grid.Column className='sharePriceColumns'>
                                        <Grid.Row>
                                            <header className='sharePriceHeader2'>SHAREHOLDER FAQS</header>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <header className='mobileSharePriceBody'>Read responses to the most commonly asked questions.</header>
                                        </Grid.Row>
                                        <Container className='sharePriceButtonCont2'>
                                            <LinkThrough className='shareholderFinancialCalenderButton' to="/shareholder">SEE MORE<img alt='shareholder button arrow' className='shareholderButtonArrow' src={Arrow}/></LinkThrough>
                                        </Container>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </Grid.Row>
                </Grid>
            )
        }
        else {
            return (
                <Grid className='sharePriceGrid'>
                    <Grid.Row>
                        <Container className='sharePriceHeaderContainer'>
                            <header className='sharePriceHeader'>SHARE PRICE</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row>
                        <Container className='sharePriceWidgetContainer'>
                            <iframe title='share price widget' className="sharePriceIFrame" src="https://irtools.co.uk/tools/share_price/2aae5d7d-6d97-444a-b91a-a3545943f1d8"/>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='sharePriceRow2'>
                        <Container className='sharePriceWidgetContainer2'>
                            <header className='sharePriceHeader'>REGULATORY NEWS</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row>
                        <Container className='sharePriceWidgetContainer3'>
                            <iframe title='rns widget' className="sharePriceIFrame2" src="https://irtools.co.uk/tools/news/9f5a6b3a-262c-4474-a2fb-f6c9489b81f1"/>
                        </Container>
                        <Container className='sharePriceButtonCont1'>
                            <LinkThrough className='shareholderFinancialCalenderButton' to="/regulatorynews">REGULATORY NEWS<img alt='shareholder button arrow' className='shareholderButtonArrow' src={Arrow}/></LinkThrough>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='sharePriceRow3'>
                        <Container className='sharePriceWidgetContainer2'>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column className='sharePriceColumns'>
                                        <Grid.Row>
                                            <header className='sharePriceHeader2'>LATEST DOCUMENTS</header>
                                        </Grid.Row>
                                        <Grid.Row className='sharePriceBottomColumn'>
                                            <header className='sharePriceDate'>18 November 2021</header>
                                            <header className='sharePriceBody'>Marks Electrical - FY22 Interim Results Presentation</header>
                                        </Grid.Row>
                                        <Container className='sharePriceButtonCont2'>
                                            <LinkThrough className='shareholderFinancialCalenderButton' to="/results">SEE MORE<img alt='shareholder button arrow' className='shareholderButtonArrow' src={Arrow}/></LinkThrough>
                                        </Container>
                                    </Grid.Column>
                                    <Grid.Column className='sharePriceColumns'>
                                        <Grid.Row>
                                            <header className='sharePriceHeader2'>SHAREHOLDER FAQS</header>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <header className='sharePriceBody'>Read responses to the most commonly asked questions.</header>
                                        </Grid.Row>
                                        <Container className='sharePriceButtonCont2'>
                                            <LinkThrough className='shareholderFinancialCalenderButton' to="/shareholder">SEE MORE<img alt='shareholder button arrow' className='shareholderButtonArrow' src={Arrow}/></LinkThrough>
                                        </Container>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </Grid.Row>
                </Grid>
            )
        }
  }

  TvAd = () => {
        if(this.props.mobileSite === true){
            return (
                <ReactPlayer
                    url="https://vimeo.com/639864657/8d39e1440a"
                    style={{'margin-left':'10%', 'margin-top':'5%', 'margin-bottom':'5%'}}
                    width="80%"
                    height="200px"
                    playing
                    controls
                    playIcon={<img src={PlayButton}/>}
                    light={TvBackground}
                />
            )
        }
        else {
            return (
                <ReactPlayer
                    url="https://vimeo.com/639864657/8d39e1440a"
                    style={{'margin-left':'10%', 'margin-top':'5%', 'margin-bottom':'5%'}}
                    width="80%"
                    height="45vw"
                    playing
                    controls
                    playIcon={<img src={PlayButton}/>}
                    light={TvBackground}
                />
            )
        }
  }

  render() {
      return (
          <div className="App">
              <Grid className='mainGrid'>
                  {this.firstRow()}
                  {this.secondRow()}
                  {this.thirdRow()}
                  <Grid.Row id="point3" className='homeSharePriceRow'>
                      {this.sharePriceGrid()}
                  </Grid.Row>
                  <Grid.Row className='homeBlueRow'>
                      {this.TvAd()}
                  </Grid.Row>
              </Grid>
              <Footer mobileSite={this.props.mobileSite}/>
          </div>
      );
  }
}

export default App;
