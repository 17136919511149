import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Container} from "semantic-ui-react";
import "./MainHeader.css";
import Navbar from "./Navbar/Navbar";
import MobileNavBar from "./Navbar/MobileNavBar";

class MainHeader extends Component{
    constructor(props) {
        super(props);
        this.state = {
            activeItem: {},
            dropdownMenuStyle: {
                display: "none"
            },
            item: "none"
        }
    }

    static getDerivedStateFromProps(props) {
        return {item: props.item };
    }

    handleToggleDropdownMenu = () => {
        let newState = Object.assign({}, this.state);
        if (newState.dropdownMenuStyle.display === "none") {
            newState.dropdownMenuStyle = { display: "flex" };
        } else {
            newState.dropdownMenuStyle = { display: "none" };
        }

        this.setState(newState);
    };

    openInNewTab = (url) => {
        const newWindow = window.open();
        newWindow.location = url;
    }

    redirectToTarget = (path) => {
        this.props.history.push(path);
    }

    render(){
        const mobileMenu = () => {
            if (this.props.mobileSite === true){
                return(
                    <MobileNavBar logo={this.props.logo} shopButton={this.props.shopButton} sharePriceUrl={this.props.sharePriceUrl} navLinks={this.props.navLinks} hamburgerColour={this.props.hamburgerColour}/>
                )
            }
            else{
                return(
                    <Navbar logo={this.props.logo} shopButton={this.props.shopButton} sharePriceUrl={this.props.sharePriceUrl} navLinks={this.props.navLinks}/>
                )
            }
        }

        const menuContainer = () => {
            if(this.props.mobileSite === true){
                return(
                    <Container className='mobileMenuContainer'>
                        <Container className="logo" fluid>
                            {mobileMenu()}
                        </Container>
                    </Container>
                )
            }
            else{
                return(
                    <Container style={{'top':'2vw'}} className='desktopMenuContainer'>
                        <Container className="logo" fluid>
                            {mobileMenu()}
                        </Container>
                    </Container>
                )
            }
        }

        return(
            <Container className='menuRootContainer'>
                {menuContainer()}
            </Container>
        )
    }
}

export default withRouter(MainHeader);