export const MenuItems1 = [
    {
        title: 'About us',
        path: '/about',
        cName: 'dropdown-link'
    },
    {
        title: 'Leadership',
        path: '/leadership',
        cName: 'dropdown-link'
    },
    {
        title: 'Our journey',
        path: '/journey',
        cName: 'dropdown-link'
    }
];

export const MenuItems2 = [
    {
        title: 'ESG',
        path: '/esg',
        cName: 'dropdown-link'
    },
    {
        title: 'Environment',
        path: '/environment',
        cName: 'dropdown-link'
    },
    {
        title: 'Social',
        path: '/community',
        cName: 'dropdown-link'
    },
    {
        title: 'Governance',
        path: '/governance',
        cName: 'dropdown-link'
    }
];

export const MenuItems3 = [
    {
        title: 'Investors',
        path: '/investors',
        cName: 'dropdown-link'
    },
    {
        title: 'Investment case',
        path: '/investmentcase',
        cName: 'dropdown-link'
    },
    {
        title: 'How it works',
        path: '/howitworks',
        cName: 'dropdown-link'
    },
    {
        title: 'IPO documents',
        path: '/ipo',
        cName: 'dropdown-link'
    },
    {
        title: 'Financial calendar',
        path: '/financialcalendar',
        cName: 'dropdown-link'
    },
    {
        title: 'AIM rule 26',
        path: '/aim26',
        cName: 'dropdown-link'
    },
    {
        title: 'Regulatory news',
        path: '/regulatorynews',
        cName: 'dropdown-link'
    },
    {
        title: 'Shareholder information',
        path: '/shareholder',
        cName: 'dropdown-link'
    },
    {
        title: 'Results, reports & presentations',
        path: '/results',
        cName: 'dropdown-link'
    },
    {
        title: 'Research',
        path: '/research',
        cName: 'dropdown-link'
    }/*,
    {
        title: 'Press',
        path: '/press',
        cName: 'dropdown-link'
    }*/
];

export const MobileMenuItems1 = [
    {
        title: 'About us',
        path: '/about',
        cName: 'nav-item-header',
        subItem: [
            {
                title: 'Leadership',
                path: '/leadership',
                cName: 'nav-item-first-child'
            },
            {
                title: 'Our journey',
                path: '/journey',
                cName: 'nav-item-child'
            }
        ]
    },
    {
        title: 'ESG',
        path: '/esg',
        cName: 'nav-item-header',
        subItem: [
            {
                title: 'Environment',
                path: '/environment',
                cName: 'nav-item-first-child'
            },
            {
                title: 'Governance',
                path: '/governance',
                cName: 'nav-item-child'
            },
            {
                title: 'Social',
                path: '/community',
                cName: 'nav-item-child'
            }
        ]
    },
    {
        title: 'Investors',
        path: '/investors',
        cName: 'nav-item-header',
        subItem: [
            {
                title: 'Investment case',
                path: '/investmentcase',
                cName: 'nav-item-first-child'
            },
            {
                title: 'How it works',
                path: '/howitworks',
                cName: 'nav-item-child'
            },
            {
                title: 'IPO documents',
                path: '/ipo',
                cName: 'nav-item-child'
            },
            {
                title: 'AIM rule 26',
                path: '/aim26',
                cName: 'nav-item-child'
            },
            {
                title: 'Regulatory news',
                path: '/regulatorynews',
                cName: 'nav-item-child'
            },
            {
                title: 'Research',
                path: '/research',
                cName: 'nav-item-child'
            }
        ]
    },
    {
        subItem: [
            {
                title: 'Results, reports & presentations',
                path: '/results',
                cName: 'nav-item-child-second-column-first'
            },
            {
                title: 'Shareholder information',
                path: '/shareholder',
                cName: 'nav-item-child-second-column-second'
            }/*,
            {
                title: 'Press',
                path: '/press',
                cName: 'nav-item-child-second-column-second'
            }*/
        ]
    }
];

export const MobileMenuItems2 = [
    {
        title: 'Contact us',
        path: '/contact',
        cName: 'nav-item-header',
        subItem: [
            {
                title: 'Careers',
                path: '/careers',
                cName: 'nav-item-header-sub1',
                subItem: []
            }
        ]
    }
];