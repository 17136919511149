import React, {Component} from "react";
import {Container, Grid, Image} from "semantic-ui-react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import MainImage from "../Images/DSC_7523-edited.png";
import MobileMainImage from "../Images/Group 8706_9.png";
import Footer from "./Footer";
import Arrow from "../Images/Layer 2_blue.svg";
import {Link} from "react-router-dom";

export default class AIMRule extends Component {
    constructor(props) {
        super(props);
        this.state ={}
    }

    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    Button = (text, tag, link) => {
        return (
            <Container className='investorsButtonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <Link className='recyclingButton' to={link}><div className='buttonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='Button arrow' src={Arrow}/></div></Link>
            </Container>
        );
    }

    MobileButton = (text, tag, link) => {
        return (
            <Container className='buttonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <Link className='mobileRecyclingButton' to={link}><div className='mobileButtonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='Button arrow' src={Arrow}/></div></Link>
            </Container>
        );
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='shareholderTitleRow'>
                    <Container className='shareholderTitle'>
                        <h2 className='mobileShareholderMainHeader'>AIM rule 26</h2>
                        <header className='mobileAimSubHeader'>The following information is disclosed in accordance with Rule 26 of the AIM Rules.</header>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='shareholderTitleRow'>
                    <Container className='shareholderTitle'>
                        <h2 className='h2'>AIM rule 26</h2>
                    </Container>
                </Grid.Row>
            )
        }
    }

    mainGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='shareholderContainer1'>
                    <Grid stackable divided='vertically' className='mobileAimDataGrid'>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='mobileAimTableHeaders'>About the business</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                {this.MobileButton("ABOUT US", "about", "/about")}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='mobileAimTableHeaders'>Country of incorporation and operation</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                <header>Marks Electrical Group plc is incorporated in the United Kingdom and its main country of operation is England.</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='mobileAimTableHeaders'>Directors</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                {this.MobileButton("LEADERSHIP", "leadership", "/leadership")}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='mobileAimTableHeaders'>Committees and Board responsibilities</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                {this.MobileButton("GOVERNANCE", "gov1", "/governance")}
                                <header>Last updated to Compliance with the Quoted Companies Alliance Corporate Governance Code on 10 January 2024.</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='mobileAimTableHeaders'>Company documents</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                {this.MobileButton("IPO DOCUMENTS", "gov2", "/ipo")}
                                <header></header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='mobileAimTableHeaders'>Regulatory annoucements</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                {this.MobileButton("REGULATORY NEWS & ALERTS", "reg", "/regulatorynews")}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='mobileAimTableHeaders'>Details of other exchanges or trading platforms</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                <header>Marks Electrical Group plc has only applied and agreed to have its securities traded on the AIM market of the London Stock Exchange.</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='mobileAimTableHeaders'>Securities in issue</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                <header>Number in issue: 104,949,050, 73.8% not in public hands, none are held as treasury shares, last updated 13 August 2024.</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='mobileAimTableHeaders'>Significant shareholders</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                {this.MobileButton("SHAREHOLDERS", "shareholder", "/shareholder")}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='mobileAimTableHeaders'>Share restrictions</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                <header>There are no restrictions on the transfer of securities.</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='mobileAimTableHeaders'>UK city code on takeovers and mergers</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                <header>The Company is subject to the UK City Code on Takeovers and Mergers</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='mobileAimTableHeaders'>Advisers and registrars</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Company Secretary</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>ONE Advisory Limited</header>
                                            <header>201 Temple Chambers</header>
                                            <header>3-7 Temple Avenue</header>
                                            <header>London EC4Y ODT</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Registered Office</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>4 Boston Road</header>
                                            <header>Leicester LE4 1AU</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Company website</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header style={{'font-size':'11px'}}>www.group.markselectrical.co.uk</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Nominated Adviser and Broker to the Company</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Canaccord Genuity Limited</header>
                                            <header>88 Wood Street</header>
                                            <header>London EC2V 7QR</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Solicitors to the Company</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Travers Smith LLP</header>
                                            <header>10 Snow Hill</header>
                                            <header>London EC1A 2AL</header>
                                        </Grid.Column>
                                    </Grid.Row>

                                    {/*
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Reporting Accountants and Auditors</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>BDO LLP</header>
                                            <header>55 Baker Street</header>
                                            <header>London W1U 7EU</header>
                                        </Grid.Column>
                                    </Grid.Row>*/ }

                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Reporting Accountants and Auditors</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>PwC</header>
                                            <header>One Chamberlain Square</header>
                                            <header>Birmingham B3 3AX</header>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Registrars</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Link Group</header>
                                            <header>10th Floor</header>
                                            <header>Central Square</header>
                                            <header>29 Wellington Street</header>
                                            <header>Leeds LS1 4DL</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Financial public relations advisers</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Dentons Global Advisors</header>
                                            <header>One Fleet Place</header>
                                            <header>London EC4M 7WS</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            )
        }
        else {
            return (
                <Container className='shareholderContainer1'>
                    <Grid stackable divided='vertically' className='aimDataGrid'>
                        <Grid.Row className='aimRuleFirstRow'>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='aimTableHeaders'>About the business</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                {this.Button("ABOUT US", "about", "/about")}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='aimTableHeaders'>Country of incorporation and operation</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                <header>Marks Electrical Group plc is incorporated in the United Kingdom and its main country of operation is England.</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='aimTableHeaders'>Directors</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                {this.Button("LEADERSHIP", "leadership", "/leadership")}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='aimTableHeaders'>Committees and Board responsibilities</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                {this.Button("GOVERNANCE", "gov1", "/governance")}
                                <header>Last updated to Compliance with the Quoted Companies Alliance Corporate Governance Code on 10 January 2024.</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='aimTableHeaders'>Company documents</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                {this.Button("IPO DOCUMENTS", "gov2", "/ipo")}
                                <header></header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='aimTableHeaders'>Regulatory annoucements</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                {this.Button("REGULATORY NEWS & ALERTS", "reg", "/regulatorynews")}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='aimTableHeaders'>Details of other exchanges or trading platforms</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                <header>Marks Electrical Group plc has only applied and agreed to have its securities traded on the AIM market of the London Stock Exchange.</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='aimTableHeaders'>Securities in issue</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                <header>Number in issue: 104,949,050, 73.8% not in public hands, none are held as treasury shares, last updated 13 August 2024.</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='aimTableHeaders'>Significant shareholders</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                {this.Button("SHAREHOLDERS", "shareholder", "/shareholder")}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='aimTableHeaders'>Share restrictions</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                <header>There are no restrictions on the transfer of securities.</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='aimTableHeaders'>UK city code on takeovers and mergers</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                <header>The Company is subject to the UK City Code on Takeovers and Mergers</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='investorsDataColumns3'>
                                <header className='aimTableHeaders'>Advisers and registrars</header>
                            </Grid.Column>
                            <Grid.Column className='investorsDataColumns3'>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Company Secretary</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>ONE Advisory Limited</header>
                                            <header>201 Temple Chambers</header>
                                            <header>3-7 Temple Avenue</header>
                                            <header>London EC4Y ODT</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Registered Office</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>4 Boston Road</header>
                                            <header>Leicester LE4 1AU</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Company website</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>www.group.markselectrical.co.uk</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Nominated Adviser and Broker to the Company</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Canaccord Genuity Limited</header>
                                            <header>88 Wood Street</header>
                                            <header>London EC2V 7QR</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Solicitors to the Company</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Travers Smith LLP</header>
                                            <header>10 Snow Hill</header>
                                            <header>London EC1A 2AL</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Reporting Accountants and Auditors</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>PwC</header>
                                            <header>One Chamberlain Square</header>
                                            <header>Birmingham B3 3AX</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Registrars</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Link Group</header>
                                            <header>10th Floor</header>
                                            <header>Central Square</header>
                                            <header>29 Wellington Street</header>
                                            <header>Leeds LS1 4DL</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Financial public relations advisers</header>
                                        </Grid.Column>
                                        <Grid.Column className='aimSubColumns'>
                                            <header>Dentons Global Advisors</header>
                                            <header>One Fleet Place</header>
                                            <header>London EC4M 7WS</header>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            )
        }
    }

    render() {
        return (
            <div className='aimRule'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                    </Grid.Row>
                    {this.mainHeader()}
                    <header style={{'padding-left':'10%', 'padding-bottom':'3%', 'width':'100%'}}>This area of the website contains all the information which is required to be disclosed in accordance with Rule 26 of the AIM Rules.</header>
                    <header style={{'padding-left':'10%'}}>Last updated on 18 July 2024.</header>
                    <Grid.Row>
                        {this.mainGrid()}
                    </Grid.Row>
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}