import React, { useState } from 'react';
import {Link, withRouter} from 'react-router-dom';
import "./Navbar.css";
import {Button, Icon, Image} from "semantic-ui-react";
import { MobileMenuItems1 } from './MenuItems';
import { MobileMenuItems2 } from './MenuItems';

function MobileNavbar(props) {
    const [click, setClick] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    React.useEffect(() => {
        if(menuOpen){
            document.body.style.overflow = 'hidden';
        }
        else{
            document.body.style.overflow = 'unset';
        }
    })

    const handleClick = () => {
        setClick(!click);
        setMenuOpen(!menuOpen);
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const closeMobileMenu = () => setClick(false);

    return (
        <>
            <nav className='mobileNavbar'>
                <Link to='/' className='mobile-navbar-logo' onClick={closeMobileMenu}>
                    <Image className='logoGridMobile' src={props.logo} size='mini' as='a'/>
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <Icon name={click ? 'close' : 'bars'} style={{color: click ? 'white' : [props.hamburgerColour]}}/>
                </div>
                <ul className={click ? 'nav-menu activeItem' : 'nav-menu'}>
                    <Link
                        className='nav-item-home'
                        to="/"
                        onClick={() => setClick(false)}
                    >
                        Home
                    </Link>
                    {MobileMenuItems1.map((item, index) => {
                        return (
                            <li className='mobileList-item' key={index}>
                                <Link
                                    className={item.cName}
                                    to={item.path}
                                    onClick={() => setClick(false)}
                                >
                                    {item.title}
                                    {item.subItem.map((item2) =>
                                        <Link
                                            className={item2.cName}
                                            to={item2.path}
                                            onClick={() => setClick(false)}
                                        >
                                            {item2.title}
                                        </Link>
                                    )}
                                </Link>
                            </li>
                        );
                    })}
                    <li className='mobileList-item'>
                        <Button onClick={() => openInNewTab("https://markselectrical.co.uk/")} style={{'width':'100%', 'padding-bottom':'0', 'border':'none', 'background-color':'#454545', 'padding-left':'0', 'padding-right':'0'}}><header className='nav-item-header'>Shop with us</header></Button>
                        {MobileMenuItems2.map((item, index) => {
                            return (
                                <Link
                                    className={item.cName}
                                    to={item.path}
                                    onClick={() => setClick(false)}
                                >
                                    {item.title}
                                    {item.subItem.map((item2) =>
                                        <Link
                                            className={item2.cName}
                                            to={item2.path}
                                            onClick={() => setClick(false)}
                                        >
                                            {item2.title}
                                        </Link>
                                    )}
                                </Link>
                            );
                        })}
                    </li>
                </ul>
            </nav>
        </>
    );
}

export default withRouter(MobileNavbar);