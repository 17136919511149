import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Footer from "./Footer";
import Van from "../Images/van.png";
import "./AboutUs.css";
import Logo from "../Images/Logo_Colour.png";
import SearchImageGrey from "../Images/Group 8085_grey.svg"
import MarkImg from "../Images/Mask Group 5.png";
import WhatWeDid from "../Images/What we did when we started.png";
import Football from "../Images/Group 8502_2.png";
import Bosch from "../Images/bosch-logo-simple.svg";
import Samsung from "../Images/Group 8518.svg";
import Miele from "../Images/Miele Logo.svg";
import Neff from "../Images/Group 8248.svg";
import LG from "../Images/g2490.svg";
import AEG from "../Images/g10.svg";
import Smeg from "../Images/Group 8253.svg";
import RangeMaster from "../Images/RM-Master-Brand-logo-220x50.png";
import Siemens from "../Images/siemens-logo-black-and-white.png";
import Fischer from "../Images/fisher-paykel-copy.png";
import Leicester from "../Images/Leicester.svg";
import MobileMark from "../Images/MicrosoftTeams-image-(6)-375X550.png";
import MobileWhatWeDid from "../Images/MicrosoftTeams-image-(8)-375X550.png";
import MobileFootball from "../Images/MicrosoftTeams-image-(7)-375X550.png";
import {Container, Grid, Image} from "semantic-ui-react";
import TrustBox from "./TrustBox";
import Arrow from "../Images/Layer 2.svg";
import {Link} from "react-scroll";
import ScrollArrowBlack from "../Images/Scroll down.svg";
import ScrollArrowAltBlue from "../Images/Down_alt2.svg";
import ScrollArrowAltWhite from "../Images/Down.svg"

export default class AboutUs extends Component{
    constructor(props) {
        super(props);
        this.state = {
            investors: 2
        }
    }

    componentDidMount() {
        const aScript = document.createElement('script');
        aScript.type = 'text/javascript';
        aScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        aScript.async = "true"
        document.head.appendChild(aScript);
        aScript.onload = function () {
            const trustBox = document.getElementById('trustbox');
            window.Trustpilot.loadFromElement(trustBox);
        };
    }

    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    Button = (text, tag) => {
        return (
            <Container className='aboutUsButtonContainer2' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <button className='aboutUsButton'><div className='homeButtonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='Button arrow icon' src={Arrow}/></div></button>
            </Container>
        );
    }

    mainImage = () => {
        if(this.props.mobileSite === true){
            return (
                <Image className='mobileVanImage' src={Van}/>
            )
        }
        else {
            return (
                <Image className='vanImage' src={Van}/>
            )
        }
    }

    mainCopy = () => {
        if(this.props.mobileSite === true){
            return (
                <Container className='mobileAboutUsCopy'>
                    <header className='mobileWhatDrivesUs'>What</header>
                    <header className='mobileWhatDrivesUs'>drives us?</header>
                    <header className='mobileSubAboutUs'>The fleet is different, but so much remains the same.</header>
                </Container>
            )
        }
        else {
            return (
                <Container className='aboutUsCopy'>
                    <header className='whatDrivesUs'>What</header>
                    <header className='whatDrivesUs'>drives us?</header>
                    <header style={{'padding-top':'2%'}} className='subAboutUs'>The fleet is different, but so much remains the same.</header>
                </Container>
            )
        }
    }

    firstRow = () => {
        if(this.props.mobileSite === true){
            return (
                <Grid.Row className='mobileFirstRow'>
                    <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_grey'
                                shopButton='greyShopButton' menuDropdown='greyDropdown' searchImage={SearchImageGrey}
                                sharePriceUrl='https://irtools.co.uk/tools/share_price/573513c7-8ca7-4a5f-8e49-9fc4e41b5775'
                                navLinks='nav-links-grey' hamburgerColour='#454545'/>
                    {this.mainImage()}
                    {this.mainCopy()}
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='aboutUsFirstRow'>
                    <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_grey'
                                shopButton='greyShopButton' menuDropdown='greyDropdown' searchImage={SearchImageGrey}
                                sharePriceUrl='https://irtools.co.uk/tools/share_price/573513c7-8ca7-4a5f-8e49-9fc4e41b5775'
                                navLinks='nav-links-grey'/>
                    {this.mainImage()}
                    <Container className='scrollArrow'>
                        <Link to="aboutPoint1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrowBlack}/></Link>
                    </Container>
                    {this.mainCopy()}
                </Grid.Row>
            )
        }
    }

    blueRow = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileBlueRow'>
                    <Container className='mobileSecondAboutUsCopy'>
                        <header className='mobileSecondAboutCopy'>Our business has always looked deceptively simple.</header>
                    </Container>
                    <Container className='mobileSecondAboutUsSubCopy'>
                        <header style={{'width':'70%', 'margin-left':'15%', 'margin-right':'15%'}} className='mobileSecondSubCopy'>However, in reality everything needs to work like a finely tuned engine to make it consistently successful: The customer is always first. This is backed by investment in a technology driven operating model, a proven distribution network, a scalable, vertically integrated delivery model and an understanding of ever changing market demand and trends. It’s something we think is hard to replicate.</header>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row id="aboutPoint1" className='blueRow'>
                    <Container className='secondAboutUsCopy'>
                        <header className='h1_w'>Our business has always looked deceptively simple.</header>
                    </Container>
                    <Container className='secondAboutUsSubCopy'>
                        <header className='secondSubCopy'>However, in reality everything needs to work like a finely tuned engine to make it consistently successful: The customer is always first. This is backed by investment in a technology driven operating model, a proven distribution network, a scalable, vertically integrated delivery model and an understanding of ever changing market demand and trends.</header>
                    </Container>
                    <Container className='secondAboutUsSubCopy2'>
                        <header className='secondSubCopy'>It’s something we think is hard to replicate.</header>
                    </Container>
                </Grid.Row>
            )
        }
    }

    firstImageRow = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='bodyRow'>
                    <Image className='mainImage' src={MobileMark}/>
                    <Container className='mobileThirdAboutUsCopy'>
                        <header className='mobileThirdAboutCopyText'>We sell a huge range of premium products.</header>
                    </Container>
                    <Container className='mobileThirdSubCopyContainer'>
                        <header className='mobileSubThirdCopy'>We make it easy for our customers to buy from us. From cookers, refrigerators, washers & dryers, dishwashers, audio visual, small appliances, add-on services, microwaves, vacuum cleaners through to sinks & taps.</header>
                        <header className='mobileSubThirdCopy2'>Ensuring this happens everyday requires real expertise.</header>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='bodyRow'>
                    <Image id="aboutPoint2" className='mainImage' src={MarkImg}/>
                    <Container className='thirdAboutUsCopy'>
                        <header className='h1_w'>We sell a huge range of premium products.</header>
                        <header className='subThirdCopy'>We make it easy for our customers to buy from us. From cookers, refrigerators, washers & dryers, dishwashers, audio visual, small appliances, add-on services, microwaves, vacuum cleaners through to sinks & taps.</header>
                        <header className='subThirdCopy2'>Ensuring this happens everyday requires real expertise.</header>
                    </Container>
                    <Container className='scrollArrowAlt2'>
                        <Link to="aboutPoint2" spy={true} smooth={true}><Image className='scrollArrowImgAlt' src={ScrollArrowAltWhite}/></Link>
                    </Container>
                </Grid.Row>
            )
        }
    }

    secondImageRow = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='bodyRow'>
                    <Image className='mainImage' src={MobileWhatWeDid}/>
                    <Container className='mobileFourthAboutUsCopy'>
                        <header className='mobileFourthCopy'>We stick to our four original beliefs:</header>
                    </Container>
                    <Container className='mobileFourthSubCopy'>
                        <header className='mobileStepsList'>1. Try your hardest.</header>
                        <header className='mobileStepsList'>2. Think like a customer.</header>
                        <header className='mobileStepsList'>3. Get the price right.</header>
                        <header className='mobileStepsList'>4. Be honest. Always.</header>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='bodyRow'>
                    <Image className='mainImage' src={WhatWeDid}/>
                    <Container className='fourthAboutUsCopy'>
                        <header className='h1_w'>We stick to our four original beliefs:</header>
                        <header className='stepsList'>1. Try your hardest.</header>
                        <header className='stepsList'>2. Think like a customer.</header>
                        <header className='stepsList'>3. Get the price right.</header>
                        <header className='stepsList'>4. Be honest. Always.</header>
                    </Container>
                </Grid.Row>
            )
        }
    }

    greyRow = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileGreyRow'>
                    <Container className='mobileStepsCopyContainer'>
                        <header className='mobileGreyAboutUsCopy'>We learnt this very early on in our 35 year history. Getting this right everyday helps us deliver the numbers.</header>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row id="aboutPoint4" className='greyRow'>
                    <Container className='stepsCopyContainer'>
                        <header className='greyAboutUsCopy'>We learnt this very early on in our 35 year history. Getting this right everyday helps us deliver the numbers.</header>
                    </Container>
                    <Container className='scrollArrowAlt3'>
                        <Link to="aboutPoint3" spy={true} smooth={true}><Image className='scrollArrowImgAlt' src={ScrollArrowAltWhite}/></Link>
                    </Container>
                </Grid.Row>
            )
        }
    }

    dataGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileGridContainerAbout'>
                    <Grid className='mobileDataGrid' divided='horizontally'>
                        <Grid.Row>
                            <header className='financialHighlights'>FY23 FINANCIAL HIGHLIGHTS</header>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileDataColumns'>
                                <text className='mobileLargeSize'>21.5</text><text className='mobileSmallSize'>%</text>
                                <header className='financialHeaders'>Sales Growth</header>
                            </Grid.Column>
                            <Grid.Column className='mobileDataColumns'>
                                <text className='mobileSmallSize'>£</text><text className='mobileLargeSize'>97.8</text><text className='mobileSmallSize'>m</text>
                                <header className='financialHeaders'>Sales</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileDataColumns'>
                                <text className='mobileSmallSize'>£</text><text className='mobileLargeSize'>7.5</text><text className='mobileSmallSize'>m</text>
                                <header className='financialHeaders'>EBITDA</header>
                            </Grid.Column>
                            <Grid.Column className='mobileDataColumns'>
                                <text className='mobileSmallSize'>£</text><text className='mobileLargeSize'>5.2</text><text className='mobileSmallSize'>m</text>
                                <header className='financialHeaders'>Net Income</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <header className='financialHighlights'>OPERATIONAL HIGHLIGHTS</header>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileDataColumns'>
                                <text className='mobileLargeSize'>4,500</text><text className='mobileSmallSize'></text><text className='mobileSmallSize'>+</text>
                                <header className='financialHeaders'>Number of SKUs</header>
                            </Grid.Column>
                            <Grid.Column className='mobileDataColumns'>
                                <text className='mobileLargeSize'>30</text><text className='mobileSmallSize'>k</text><text className='mobileSmallSize'>+</text>
                                <header className='financialHeaders'>In-stock items</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileDataColumns'>
                                <text className='mobileLargeSize'>50+</text>
                                <header className='financialHeaders'>Number of brands</header>
                            </Grid.Column>
                            <Grid.Column className='mobileDataColumns'>
                                <text className='mobileSmallSize'></text><text className='mobileLargeSize'>245</text><text className='mobileSmallSize'>k</text>
                                <header className='financialHeaders'>Warehouse sqft</header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            )
        }
        else {
            return (
                <Container id="aboutPoint3"  className='aboutDataGridContainer'>
                    <Grid stackable>
                        <Grid.Row>
                            <header className='financialHighlights'>FY23 FINANCIAL HIGHLIGHTS</header>
                        </Grid.Row>
                    </Grid>
                    <Grid stackable divided='vertically'>
                        <Grid.Row>
                            <Grid.Column className='dataColumns'>
                                <text className='largeSize'>21.5</text><text className='smallSize'>%</text>
                            </Grid.Column>
                            <Grid.Column className='dataColumns'>
                                <header className='financialHeaders'>Sales Growth</header>
                            </Grid.Column>
                            <Grid.Column style={{'padding-left':'8%'}} className='dataColumns'>
                                <text className='smallSize'>£</text><text className='largeSize'>97.8</text><text className='smallSize'>m</text>
                            </Grid.Column>
                            <Grid.Column style={{'padding-left':'8%'}} className='dataColumns'>
                                <header className='financialHeaders'>Sales</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='dataColumns'>
                                <text className='smallSize'>£</text><text className='largeSize'>7.5</text><text className='smallSize'>m</text>
                            </Grid.Column>
                            <Grid.Column className='dataColumns'>
                                <header className='financialHeaders'>EBITDA</header>
                            </Grid.Column>
                            <Grid.Column style={{'padding-left':'8%'}}  className='dataColumns'>
                                <text className='smallSize'>£</text><text className='largeSize'>5.2</text><text className='smallSize'>m</text>
                            </Grid.Column>
                            <Grid.Column style={{'padding-left':'8%'}}  className='dataColumns'>
                                <header className='financialHeaders'>Net Income</header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Container className='auditContainer'>
                        <text className='auditMessage'>Audited accounts for 31-Mar-21</text>
                    </Container>
                    <Grid>
                        <Grid.Row>
                            <header className='financialHighlights'>OPERATIONAL HIGHLIGHTS</header>
                        </Grid.Row>
                    </Grid>
                    <Grid stackable divided='vertically'>
                        <Grid.Row>
                            <Grid.Column className='dataColumns'>
                                <text style={{'font-size':'8em'}} className='largeSize'>4,500</text><text className='smallSize'></text><text className='smallSize'>+</text>
                            </Grid.Column>
                            <Grid.Column className='dataColumns'>
                                <header className='financialHeaders'>Number of SKUs</header>
                            </Grid.Column>
                            <Grid.Column style={{'padding-left':'8%'}} className='dataColumns'>
                                <text className='largeSize'>30</text><text className='smallSize'>k</text><text className='smallSize'>+</text>
                            </Grid.Column>
                            <Grid.Column style={{'padding-left':'8%'}} className='dataColumns'>
                                <header className='financialHeaders'>In-stock items</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='dataColumns'>
                                <text className='largeSize'>50+</text>
                            </Grid.Column>
                            <Grid.Column className='dataColumns'>
                                <header className='financialHeaders'>Number of brands</header>
                            </Grid.Column>
                            <Grid.Column style={{'padding-left':'8%'}} className='dataColumns'>
                                <text className='smallSize'></text><text className='largeSize'>245</text><text className='smallSize'>k</text>
                            </Grid.Column>
                            <Grid.Column style={{'padding-left':'8%'}} className='dataColumns'>
                                <header className='financialHeaders'>Warehouse sqft</header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            )
        }
    }

    secondWhiteRow = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileSecondWhiteRow'>
                    {this.dataGrid()}
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='secondWhiteRow'>
                    {this.dataGrid()}
                </Grid.Row>
            )
        }
    }

    footballImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='bodyRow'>
                    <Image className='mainImage' src={MobileFootball}/>
                    <Container className='mobileFootballAboutUsCopy'>
                        <header className='mobileFootballAboutText'>We know what makes people keep coming back for more.</header>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='bodyRow'>
                    <Image className='mainImage' src={Football}/>
                    <Container className='footballAboutUsCopy'>
                        <header className='h1_w'>We know what makes people keep coming back for more.</header>
                    </Container>
                </Grid.Row>
            )
        }
    }

    bottomGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileBlueRow2'>
                    <Container className='mobileBottomGridContainerAbout'>
                        <Grid divided='vertically'>
                            <Grid.Row>
                                <header className='premiumHeader'>1. Premium brands</header>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column className='mobileDataColumns'><img style={{'width':'80%'}} alt='Bosch logo' src={Bosch} /></Grid.Column>
                                        <Grid.Column className='mobileDataColumns'><img style={{'width':'80%'}} alt='Samsung logo' src={Samsung} /></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='mobileDataColumns'><img style={{'width':'80%'}} alt='Miele logo' src={Miele} /></Grid.Column>
                                        <Grid.Column className='mobileDataColumns'><img style={{'width':'80%'}} alt='Neff logo' src={Neff} /></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='mobileDataColumns'><img style={{'width':'60%'}} alt='LG logo' src={LG} /></Grid.Column>
                                        <Grid.Column className='mobileDataColumns'><img style={{'width':'60%'}} alt='AEG logo' src={AEG} /></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='mobileDataColumns'><img style={{'width':'80%'}} alt='Smeg logo' src={Smeg} /></Grid.Column>
                                        <Grid.Column className='mobileDataColumns'><img style={{'width':'80%'}} alt='RangeMaster logo' src={RangeMaster} /></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='mobileDataColumns'><img style={{'width':'80%'}} alt='Siemens logo' src={Siemens} /></Grid.Column>
                                        <Grid.Column style={{'padding-top':'0', 'padding-left':'1%'}} className='mobileDataColumns'><img alt='Fischer & paykel logo' src={Fischer} /></Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Row>
                        </Grid>
                        <Grid>
                            <Grid.Row className='serviceGridColumn'>
                                <Grid divided='vertically'>
                                    <Grid.Row>
                                        <header className='premiumHeader'>2. Great service</header>
                                    </Grid.Row>
                                    <Grid.Row style={{'padding-bottom':'0'}}>
                                        <TrustBox className="aboutus-trustpilot-widget"/>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Row>
                            <Grid.Row className='serviceGridColumn'>
                                <Grid divided='vertically'>
                                    <Grid.Row>
                                        <header className='premiumHeader'>3. Next Day Delivery</header>
                                    </Grid.Row>
                                    <Grid.Row className='leicesterGridRow'>
                                        <Grid.Column className='mobileLeicesterImageColumn'>
                                            <img alt='Leicester outline' src={Leicester} />
                                        </Grid.Column>
                                        <Grid.Column className='mobileLeicesterTextColumn'>
                                            <text className='mobileLeicesterBody'><text className='mobileLeicesterBold'>LEICESTER</text> is not only a great place to live, it’s central location means we can give our customers </text><text className='mobileLeicesterBold'>NEXT DAY DELIVERY.</text>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row id="aboutPoint5" className='blueRow2'>
                    <Container style={{'margin-left':'10% !important', 'margin-right':'10% !important'}}>
                        <Grid divided='vertically'>
                            <Grid.Row className='blueRows'>
                                <header className='premiumHeader'>1. Premium brands</header>
                            </Grid.Row>
                            <Grid.Row className='blueRows'>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column className='dataColumns'><img alt='Bosch logo' src={Bosch} /></Grid.Column>
                                        <Grid.Column style={{'margin-top':'0.5%'}} className='dataColumns'><img alt='Samsung logo' src={Samsung} /></Grid.Column>
                                        <Grid.Column className='dataColumns'><img alt='Miele logo' src={Miele} /></Grid.Column>
                                        <Grid.Column className='dataColumns'><img alt='Neff logo' src={Neff} /></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='dataColumns'><img alt='LG logo' src={LG} /></Grid.Column>
                                        <Grid.Column className='dataColumns'><img alt='AEG logo' src={AEG} /></Grid.Column>
                                        <Grid.Column className='dataColumns'><img alt='Smeg logo' src={Smeg} /></Grid.Column>
                                        <Grid.Column className='dataColumns'><img alt='RangeMaster logo' src={RangeMaster} /></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column className='dataColumns'><img alt='Siemens logo' src={Siemens} /></Grid.Column>
                                        <Grid.Column style={{'padding-top':'1%', 'margin-top':'-2%'}} className='dataColumns'><img alt='Fischer & paykel' src={Fischer} /></Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Row>
                        </Grid>
                        <Grid>
                            <Grid.Column className='serviceGridBottomColumn1'>
                                <Grid divided='vertically'>
                                    <Grid.Row className='blueRows'>
                                        <header className='premiumBottomHeader'>2. Great service</header>
                                        <TrustBox className="aboutus-trustpilot-widget"/>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column className='serviceGridBottomColumn2'>
                                <Grid divided='vertically'>
                                    <Grid.Row className='leicesterGridRow'>
                                        <header className='premiumBottomHeader'>3. Next Day Delivery</header>
                                        <Container className='leicesterImg'>
                                            <img className='leicesterImg' alt='Leicester outline' src={Leicester} />
                                        </Container>
                                        <Container className='leicesterText'>
                                            <text className='leicesterBody'><text className='leicesterBold'>LEICESTER</text> is not only a great place to live, it’s central location means we can give our customers </text><text className='leicesterBold'>NEXT DAY DELIVERY.</text>
                                        </Container>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid>
                    </Container>
                    <Container className='scrollArrowAlt4'>
                        <Link to="aboutPoint5" spy={true} smooth={true}><Image className='scrollArrowImgAlt' src={ScrollArrowAltBlue}/></Link>
                    </Container>
                </Grid.Row>
            )
        }
    }

    render(){
        return(
            <div className='aboutUs'>
                <Grid className='aboutUsGrid'>
                    {this.firstRow()}
                    {this.blueRow()}
                    {this.firstImageRow()}
                    {this.secondImageRow()}
                    {this.greyRow()}
                    {this.secondWhiteRow()}
                    {this.footballImage()}
                    {this.bottomGrid()}
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}