import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_Colour.png";
import SearchImageGrey from "../Images/Group 8085_grey.svg";
import {Container, Grid} from "semantic-ui-react";
import "./MissingPage.css";
import Footer from "./Footer";
import {Link} from "react-router-dom";

export default class MissingPage extends Component {
    mainHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container>
                    <header className='mobileMissingHeader1'>Oops!</header>
                    <h5 className='mobileSubHeader'>We can’t seem to find the page you’re looking for.</h5>
                </Container>
            )
        }
        else {
            return (
                <Container>
                    <header className='mobileMissingHeader1'>Oops!</header>
                    <h5 className='h5'>We can’t seem to find the page you’re looking for.</h5>
                </Container>
            )
        }
    }

    render() {
        return (
            <div className='missingPage'>
                <Grid>
                    <Grid.Row className='missingRow1'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_grey' shopButton='greyShopButton'
                                    menuDropdown='greyDropdown' searchImage={SearchImageGrey}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/573513c7-8ca7-4a5f-8e49-9fc4e41b5775'
                                    navLinks='nav-links-grey' hamburgerColour='#454545'/>
                        <Container className='missingContainer1'>
                            {this.mainHeader()}
                            <header className='missingBody'>Here are some helpful links instead.</header>
                            <Link to='/about'><header className='missingLinks'>About us</header></Link>
                            <Link to='/esg'><header className='missingLinks'>ESG</header></Link>
                            <Link to='/investors'><header className='missingLinks'>Investors</header></Link>
                            <Link to='/contact'><header className='missingLinks'>Contact us</header></Link>
                            <Link to='/careers'><header className='missingLinks'>Careers</header></Link>
                        </Container>
                    </Grid.Row>
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}