import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import {Container, Grid, Image} from "semantic-ui-react";
import MainImage from "../Images/Research-page-header-1.png";
import MobileMainImage from "../Images/Research-page-header-mobile.png";
import Footer from "./Footer";
import jQuery from "jquery";
import Download from "../Images/Download_Icon.png";

export default class Research extends Component {
    constructor(props) {
        super(props);

        this.updateWidgetHeight = this.updateWidgetHeight.bind(this);
    };

    updateWidgetHeight(event) {
        const frames = document.getElementsByTagName('iframe');
        for (let i = 0; i < frames.length; i++) {
            if (frames[i].contentWindow === event.source) {
                jQuery(frames[i]).height(event.data);
                break;
            }
        }
    }

    componentDidMount() {
        window.addEventListener('message', this.updateWidgetHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.updateWidgetHeight)
    }

    openInNewTab = (newWindow, url) => {
        newWindow.location = url;
    }

    fetchSignedUrl = async(newWindow, file) => {
        await Promise.resolve(fetch(`https://group.markselectrical.co.uk/document?name=${file}`)
            .then(response => response.json())
            .then((data) => {
                this.setState({docUrls: data} );
            })
            .catch(console.log))

        if(this.state.docUrls.length > 0){
            this.openInNewTab(newWindow, this.state.docUrls[0].url);
        }
        else{
            console.log("No url retrieved!")
        }
    }

    handleClick = (file) => {
        const newWindow = window.open();
        this.fetchSignedUrl(newWindow, file);
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='resultsContainer2'>
                    <h2 className='mobileResultsHeader'>Research</h2>
                </Container>
            )
        }
        else {
            return (
                <Container className='regContainer2'>
                    <h2 className='h2'>Research</h2>
                </Container>
            )
        }
    }

    mainGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileInvestorsContainer'>
                    <Grid stackable divided='vertically' className='mobileEsgDataGrid'>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarHeadline'>DATE</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarHeadline'>TITLE</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>10 August 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='mobileCalenderBody'>EQUITY DEVELOPMENT: AUG-23 TRADING UPDATE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("Marks-Electrical-10-August-2023-note.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>12 April 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='mobileCalenderBody'>Equity Development: APR-23 TRADING UPDATE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("Marks-Electrical-Group-12th-April-2023-trading-update-finalnote.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>10 January 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='mobileCalenderBody'>Equity Development: Jan-23 Trading update</header>
                                <button className='fileDownload' onClick={() => this.handleClick("Equity-Development-10-01-23.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>08 June 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='mobileCalenderBody'>Equity Development: FY22 Results</header>
                                <button className='fileDownload' onClick={() => this.handleClick("MRK 8 June final v2")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>16 March 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='mobileCalenderBody'>Equity Development: Initiation Note</header>
                                <button className='fileDownload' onClick={() => this.handleClick("Marks Electrical Group  initiation note 16th March 2022.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            )
        }
        else {
            return (
                <Container className='ipoDocsContainer2'>
                    <Grid stackable divided='vertically' className='shareDataGrid'>
                        <Grid.Row style={{'padding-bottom':'0'}}>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarHeadline'>DATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumnsTitle'>
                                <header className='esgCalendarHeadline'>TITLE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarHeadline'>DOCUMENT</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>10 August 2023</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>EQUITY DEVELOPMENT: AUG-23 TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("Marks-Electrical-10-August-2023-note.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>12 April 2023</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>Equity Development: APR-23 TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("Marks-Electrical-Group-12th-April-2023-trading-update-finalnote.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>10 January 2023</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>Equity Development: Jan-23 Trading update</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("Equity-Development-10-01-23.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>08 June 2022</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>Equity Development: FY22 Results</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("MRK 8 June final v2.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>16 March 2022</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>Equity Development: Initiation Note</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("Marks Electrical Group  initiation note 16th March 2022.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row/>
                    </Grid>
                </Container>
            )
        }
    }

    render() {
        return (
            <div className='ipoDocuments'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl="https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4"
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                    </Grid.Row>
                    <Grid.Row className='mobileInvestorsDataGrids'>
                        <Container className='ipoDocsContainer1'>
                            {this.mainHeader()}
                        </Container>
                        {this.mainGrid()}
                    </Grid.Row>
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}