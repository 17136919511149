import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import {Container, Grid, Image} from "semantic-ui-react";
import MainImage from "../Images/newspapercutting.png";
import MobileMainImage from "../Images/mobileJourney.png";
import Van from "../Images/van.png";
import Circle from "../Images/Group 8671.svg";
import NewsPaper from "../Images/What we did when we started_2.png";
import Warehouse1 from "../Images/DSC_7794-edited.jpg";
import DeliveryImage from "../Images/timeline-delivery-image.png";
import BlueVanImage from "../Images/OurJourney-01.jpg";
import Warehouse2 from "../Images/DSC_8262-edited.jpg";
import Warehouse3 from "../Images/DSC_8756.jpg";
import Showroom3 from "../Images/showroom-2022.jpg";
import Footer from "./Footer";
import Dot from "../Images/Ellipse 30.svg";
import LineTo from 'react-lineto';
import CircleLeft from "../Images/circle-left.svg";
import CircleRight from "../Images/circle-right.svg";
import {Link} from "react-scroll";
import ScrollArrow from "../Images/Scroll down_W.svg";

export default class Journey extends Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.resize = this.resize.bind(this);
    }

    drawLine = () => {
        return(
            <LineTo from='topDot' to='bottomDot' borderColor='#0099FF' borderWidth={3}/>
        )
    };

    resize = () => {
        setTimeout(() => {
            requestAnimationFrame(() => {
                this.setState({temp: 1})
                }
            )
        })
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);
        this.resize()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileJourneyMainCopy'>
                    <header className='mobileEsgSmallTitle'>OUR JOURNEY</header>
                    <header className='mobileEnvMainHeader'>An approach that has proven to be timeless.</header>
                </Container>
            )
        }
        else {
            return (
                <Container className='journeyMainCopy'>
                    <header className='small_title_w'>OUR JOURNEY</header>
                    <header className='h1_w'>An approach that has proven to be timeless.</header>
                    <Container className='journeyScrollArrow'>
                        <Link to="point1" spy={true} smooth={true}><Image style={{'width':'unset', 'margin-left':'0'}} className='scrollArrowImg' src={ScrollArrow}/></Link>
                    </Container>
                </Container>
            )
        }
    }

    bodyHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row>
                    <Container className='mobileJourneyContainer1'>
                        <h2 className='mobileJourneyBodyHeader'>Our journey</h2>
                        <header className='mobileJourneySubTitle1'>We have a track record of growing revenue and scaling the business throughout the shift to online.</header>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row>
                    <Container id="point1" className='journeyContainer1'>
                        <h2 className='h2'>Our journey</h2>
                        <header className='journeySubTitle1'>We have a track record of growing revenue and scaling the business throughout the shift to online.</header>
                    </Container>
                </Grid.Row>
            )
        }
    }

    timeline = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid className='journeyMainGrid' container centered>
                    <Grid.Row className='mobileJourneyTopRow'>
                        <img onLoad={this.resize} alt='top pin of timeline' className='topDot' src={Dot}/>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyCircleRows'>
                        <img alt='timeline date circle' className='mobileJourneyCircles' src={Circle}/>
                        <Container className='mobileJourneyDateContainerTop'>
                            <header className='mobileJourneyDate'>1987</header>
                            <header className='mobileJourneyDate'>—</header>
                            <header className='mobileJourneyDate'>2000</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <Container className='mobileJourneyBodyContainerLeft'>
                            <ul style={{'margin-left':'-10%'}} className='journeyUl'>
                                <li className='journeyLi'><text className='mobileJourneyText'>Foundation and development</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Founded by Mark Smithson in Leicester in 1987 reselling used products in a rented Premises</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Grew well locally and purchased a further 4 wholly-owned stores</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Positioned as a high-end MDA* market leader in the Midlands</text></li>
                            </ul>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <img alt='old van' className='mobileJourneyImages' src={Van}/>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyCircleRows'>
                        <img alt='timeline date circle' src={Circle}/>
                        <Container className='mobileJourneyDateContainerTop'>
                            <header className='mobileJourneyDate'>2001</header>
                            <header className='mobileJourneyDate'>—</header>
                            <header className='mobileJourneyDate'>2005</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <Container className='mobileJourneyBodyContainerLeft2'>
                            <ul style={{'margin-left':'-10%'}} className='journeyUl'>
                                <li className='journeyLi'><text className='mobileJourneyText'>Move online</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Launched website in 2003</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Bought first two warehouses</text></li>
                            </ul>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <img alt='newspaper clipping' className='mobileJourneyImages' src={NewsPaper}/>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyCircleRows'>
                        <img alt='timeline date circle' src={Circle}/>
                        <Container className='mobileJourneyDateContainerTop'>
                            <header className='mobileJourneyDate'>2006</header>
                            <header className='mobileJourneyDate'>—</header>
                            <header className='mobileJourneyDate'>2010</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <Container className='mobileJourneyBodyContainerLeft2'>
                            <ul style={{'margin-left':'-10%'}} className='journeyUl'>
                                <li className='journeyLi'><text className='mobileJourneyText'>Challenging trading environment</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Impact of global recession on sales progression</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Reached sales of c.80% online</text></li>
                            </ul>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <img alt='warehouse' className='mobileJourneyImages' src={Warehouse1}/>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyCircleRows'>
                        <img alt='timeline date circle' src={Circle}/>
                        <Container className='mobileJourneyDateContainerTop'>
                            <header className='mobileJourneyDate'>2011</header>
                            <header className='mobileJourneyDate'>—</header>
                            <header className='mobileJourneyDate'>2016</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <Container className='mobileJourneyBodyContainerLeft2'>
                            <ul style={{'margin-left':'-10%'}} className='journeyUl'>
                                <li className='journeyLi'><text className='mobileJourneyText'>Facility expansion and growth</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Added two new warehouses for £3.6m with circa 60,000 sq. ft capacity</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Outstripped capacity rapidly and sought new premises</text></li>
                            </ul>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <img alt='forklift' className='mobileJourneyImages' src={Warehouse2}/>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyCircleRows'>
                        <img alt='timeline date circle' src={Circle}/>
                        <Container className='mobileJourneyDateContainerTop'>
                            <header className='mobileJourneyDate'>2017</header>
                            <header className='mobileJourneyDate'>—</header>
                            <header className='mobileJourneyDate'>2020</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <Container className='mobileJourneyBodyContainerLeft2'>
                            <ul style={{'margin-left':'-10%'}} className='journeyUl'>
                                <li className='journeyLi'><text className='mobileJourneyText'>Funding headroom eased, helping to accelerate growth</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Bought current warehouse for £5.4m with 200,000 s.ft</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Invested proceeds from prior warehouse sales into new facility and stock</text></li>
                            </ul>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <img alt='Marks electrical office building at night' className='mobileJourneyImages' src={Warehouse3}/>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyCircleRows'>
                        <img alt='timeline date circle' src={Circle}/>
                        <Container className='mobileJourneyDateContainerBottom'>
                            <header className='mobileJourneyDate'>2021</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <Container className='mobileJourneyBodyContainerLeft2'>
                            <ul style={{'margin-left':'-10%'}} className='journeyUl'>
                                <li className='journeyLi'><text className='mobileJourneyText'>First TV ad launched</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Rapid sales expansion</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Additional £7m invested in stock</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>78% revenue growth</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Controlled overheads</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>EBITDA margin > 13%</text></li>
                            </ul>
                        </Container>
                    </Grid.Row>

                    <Grid.Row className='mobileJourneyRows'>
                        <img alt='Marks electrical office building at night' className='mobileJourneyImages' src={Showroom3}/>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyCircleRows'>
                        <img alt='timeline date circle' src={Circle}/>
                        <Container className='mobileJourneyDateContainerBottom'>
                            <header className='mobileJourneyDate'>2022</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <Container className='mobileJourneyBodyContainerLeft2'>
                            <ul style={{'margin-left':'-10%'}} className='journeyUl'>
                                <li className='journeyLi'><text className='mobileJourneyText'>IPO on the London Stock Exchange</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Continued revenue growth at 44%</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Operating leverage achieved over fixed cost based</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>9.0% EBITDA margin</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>A new look for our fleet</text></li>
                            </ul>
                        </Container>
                    </Grid.Row>

                    <Grid.Row className='mobileJourneyRows'>
                        <img alt='Marks electrical office building at night' className='mobileJourneyImages' src={Showroom3}/>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyCircleRows'>
                        <img alt='timeline date circle' src={Circle}/>
                        <Container className='mobileJourneyDateContainerBottom'>
                            <header className='mobileJourneyDate'>2023</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyRows'>
                        <Container className='mobileJourneyBodyContainerLeft2'>
                            <ul style={{'margin-left':'-10%'}} className='journeyUl'>
                                <li className='journeyLi'><text className='mobileJourneyText'>New installation team</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Strong revenue growth</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Marks Electrical Academy</text></li>
                                <li className='journeyLi'><text className='mobileJourneyText'>Significant warehouse upgrades &amp; improvements</text></li>
                            </ul>
                        </Container>
                    </Grid.Row>

                    <Grid.Row className='mobileJourneyRows'>
                    </Grid.Row>
                    <Grid.Row className='mobileJourneyBottomRow'>
                        <img alt='timeline bottom dot' className='bottomDot' src={Dot}/>
                    </Grid.Row>
                    {this.drawLine(this.state.temp)}
                </Grid>
            )
        }
        else {
            return (
                <Grid className='journeyMainGrid'>
                    <Grid.Row className='mobileJourneyTopRow'>
                        <img onLoad={this.resize} alt='timeline top dot' className='topDot' src={Dot}/>
                    </Grid.Row>
                    <Grid.Row className='journeyGridRows'>
                        <Grid.Column className='journeyGridColumns'>
                            <img alt='old van' className='journeyTimelineImages' src={Van}/>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyCircleContainerTop'>
                                <img alt='timeline date circle' src={CircleRight}/>
                            </Container>
                            <Container className='journeyDateContainer'>
                                <header className='journeyDate'>1987</header>
                                <header className='journeyDate'>—</header>
                                <header className='journeyDate'>2000</header>
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyBodyContainerLeft'>
                                <ul className='journeyUl'>
                                    <li className='journeyLi'><text className='journeyTextLeft'>Foundation and development</text></li>
                                    <li className='journeyLi'><text className='journeyTextLeft'>Founded by Mark Smithson in Leicester in 1987 reselling used products in a rented Premises</text></li>
                                    <li className='journeyLi'><text className='journeyTextLeft'>Grew well locally and purchased a further 4 wholly-owned stores</text></li>
                                    <li className='journeyLi'><text className='journeyTextLeft'>Positioned as a high-end MDA* market leader in the Midlands</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='journeyGridRows'>
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyBodyContainerRight'>
                                <ul className='journeyUl'>
                                    <li className='journeyLi'><text className='journeyTextRight'>Move online</text></li>
                                    <li className='journeyLi'><text className='journeyTextRight'>Launched website in 2003</text></li>
                                    <li className='journeyLi'><text className='journeyTextRight'>Bought first two warehouses</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyCircleContainerLeft'>
                                <img alt='timeline date circle' className='journeyCirclesLeft' src={CircleLeft}/>
                            </Container>
                            <Container className='journeyDateContainer2'>
                                <header className='journeyDate'>2001</header>
                                <header className='journeyDate'>—</header>
                                <header className='journeyDate'>2005</header>
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <img alt='newspaper clipping' src={NewsPaper}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='journeyGridRows'>
                        <Grid.Column className='journeyGridColumns'>
                            <img alt='warehouse' src={Warehouse1}/>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyCircleContainerRight'>
                                <img alt='timeline date circle' className='journeyCirclesRight' src={CircleRight}/>
                            </Container>
                            <Container className='journeyDateContainer2'>
                                <header className='journeyDate'>2006</header>
                                <header className='journeyDate'>—</header>
                                <header className='journeyDate'>2010</header>
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyBodyContainerLeft'>
                                <ul className='journeyUl'>
                                    <li className='journeyLi'><text className='journeyTextLeft'>Challenging trading environment</text></li>
                                    <li className='journeyLi'><text className='journeyTextLeft'>Impact of global recession on sales progression</text></li>
                                    <li className='journeyLi'><text className='journeyTextLeft'>Reached sales of c.80% online</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='journeyGridRows'>
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyBodyContainerRight'>
                                <ul className='journeyUl'>
                                    <li className='journeyLi'><text className='journeyTextRight'>Facility expansion and growth</text></li>
                                    <li className='journeyLi'><text className='journeyTextRight'>Added two new warehouses for £3.6m with circa 60,000 sq. ft capacity</text></li>
                                    <li className='journeyLi'><text className='journeyTextRight'>Outstripped capacity rapidly and sought new premises</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyCircleContainerLeft'>
                                <img alt='timeline date circle' className='journeyCirclesLeft' src={CircleLeft}/>
                            </Container>
                            <Container className='journeyDateContainer2'>
                                <header className='journeyDate'>2011</header>
                                <header className='journeyDate'>—</header>
                                <header className='journeyDate'>2016</header>
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <img alt='forklift' src={Warehouse2}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='journeyGridRows'>

                        <Grid.Column className='journeyGridColumns'>
                            <img alt='Marks electrical office at night' src={Warehouse3}/>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyCircleContainerRight'>
                                <img alt='timeline date circle' className='journeyCirclesRight' src={CircleRight}/>
                            </Container>
                            <Container className='journeyDateContainer2'>
                                <header className='journeyDate'>2017</header>
                                <header className='journeyDate'>—</header>
                                <header className='journeyDate'>2020</header>
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyBodyContainerLeft'>
                                <ul className='journeyUl'>
                                    <li className='journeyLi'><text className='journeyTextLeft'>Funding headroom eased, helping to accelerate growth</text></li>
                                    <li className='journeyLi'><text className='journeyTextLeft'>Bought current warehouse for £5.4m with 200,000 s.ft</text></li>
                                    <li className='journeyLi'><text className='journeyTextLeft'>Invested proceeds from prior warehouse sales into new facility and stock</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row className='journeyGridRows'>

                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyBodyContainerRight'>
                                <ul className='journeyUl'>
                                    <li className='journeyLi'><text className='journeyTextRight'>First TV ad launched</text></li>
                                    <li className='journeyLi'><text className='journeyTextRight'>Rapid sales expansion</text></li>
                                    <li className='journeyLi'><text className='journeyTextRight'>Additional £7m invested in stock</text></li>
                                    <li className='journeyLi'><text className='journeyTextRight'>78% revenue growth</text></li>
                                    <li className='journeyLi'><text className='journeyTextRight'>Controlled overheads</text></li>
                                    <li className='journeyLi'><text className='journeyTextRight'>EBITDA margin > 13%</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyCircleContainerLeft'>
                                <img alt='timeline date circle' className='journeyCirclesLeft' src={CircleLeft}/>
                            </Container>
                            <Container className='journeyDateContainer3'>
                                <header className='journeyDate'>2021</header>
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <img alt='warehouse' src={DeliveryImage}/>
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row className='journeyGridRows'>

                        <Grid.Column className='journeyGridColumns'>
                            <img alt='warehouse' src={Showroom3}/>
                        </Grid.Column>                  
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyCircleContainerRight'>
                                <img alt='timeline date circle' className='journeyCirclesRight' src={CircleRight}/>
                            </Container>
                            <Container className='journeyDateContainer3'>
                                <header className='journeyDate'>2022</header>
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='journeyGridColumns'>
                            <Container className='journeyBodyContainerRight'>
                                <ul className='journeyUl'>
                                    <li className='journeyLi'><text className='journeyTextLeft'>IPO on the London Stock Exchange</text></li>
                                    <li className='journeyLi'><text className='journeyTextLeft'>Continued revenue growth at 44%</text></li>
                                    <li className='journeyLi'><text className='journeyTextLeft'>Operating leverage achieved over fixed cost based</text></li>
                                    <li className='journeyLi'><text className='journeyTextLeft'>9.0% EBITDA margin</text></li>
                                    <li className='journeyLi'><text className='journeyTextLeft'>A new look for our fleet</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                       
                    </Grid.Row>
                    <Grid.Row className='journeyGridRows'>

                    <Grid.Column className='journeyGridColumns'>
                        <Container className='journeyBodyContainerRight'>
                            <ul className='journeyUl'>
                                <li className='journeyLi'><text className='journeyTextRight'>New installation team</text></li>
                                <li className='journeyLi'><text className='journeyTextRight'>Strong revenue growth</text></li>
                                <li className='journeyLi'><text className='journeyTextRight'>Marks Electrical Academy</text></li>
                                <li className='journeyLi'><text className='journeyTextRight'>Significant warehouse upgrades &amp; improvements</text></li>
                            </ul>
                        </Container>
                    </Grid.Column>
                    <Grid.Column className='journeyGridColumns'>
                        <Container className='journeyCircleContainerLeft'>
                            <img alt='timeline date circle' className='journeyCirclesLeft' src={CircleLeft}/>
                        </Container>
                        <Container className='journeyDateContainer3'>
                            <header className='journeyDate'>2023</header>
                        </Container>
                    </Grid.Column>
                    <Grid.Column className='journeyGridColumns'>
                        <img alt='warehouse' src={BlueVanImage}/>
                    </Grid.Column>

                    </Grid.Row>
                    <Grid.Row className='mobileJourneyBottomRow'>
                        <img alt='timeline bottom dot' className='bottomDot' src={Dot}/>
                    </Grid.Row>
                    {this.drawLine(this.state.temp)}
                </Grid>
            )
        }
    }

    render() {
        return (
            <div className='journey'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                        {this.mainCopy()}
                    </Grid.Row>
                    {this.bodyHeader()}
                    <Grid.Row>
                        {this.timeline()}
                    </Grid.Row>
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}