import React, {Component} from "react";
import {Container, Grid, Image} from "semantic-ui-react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import MainImage from "../Images/DSC_7992-edited.png";
import Footer from "./Footer";
import "./Investors.css";
import MobileMainImage from "../Images/Group 8706_8.png";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import "./Accordian.css";
import Download from "../Images/Download_Icon.png";
import Phone from "../Images/phone.svg";
import Email from "../Images/email.svg";
import Arrow from "../Images/LinkArrow.png";

export default class ShareholderInfo extends Component {
    items = [
        {
            heading: 'About the shares',
            contentHeaders: [
                {
                    uuid: "1",
                    subHeader: 'Where are the Company’s ordinary shares listed?',
                    content: 'London Stock Exchange Alternative Investment Market.'
                },
                {
                    uuid: "2",
                    subHeader: 'Where can I find information on historical share prices?',
                    content: 'https://www.londonstockexchange.com/'
                },
            ]
        },
        {
            heading: 'Your share account',
            contentHeaders: [
                {
                    uuid: "1",
                    subHeader: 'How can I register for electronic communications?',
                    content: 'Email investors@markselectrical.co.uk to be adding to the distribution mailing list.'
                },
                {
                    uuid: "2",
                    subHeader: 'How can I update my address details?',
                    content: 'Contact your chosen broker.'
                },
                {
                    uuid: "3",
                    subHeader: 'I’ve changed my name. How can I update my details?',
                    content: 'Contact your chosen broker.'
                },
                {
                    uuid: "4",
                    subHeader: 'What should I do if a shareholder has died?',
                    content: 'Contact your chosen broker and in failing that contact investors@markselectrical.co.uk.'
                },
            ]
        },
        {
            heading: 'Managing your shares',
            contentHeaders: [
                {
                    uuid: "1",
                    subHeader: 'How can I buy or sell shares in Marks Electrical Group plc?',
                    content: 'You will need to find a broker that gives you access to the AIM exchange. Then, upon opening an account and depositing funds, you will need to choose Marks Electrical Group plc.'
                },
                {
                    uuid: "2",
                    subHeader: 'Can I manage my shareholder online?',
                    content: 'This would need to be managed through your chosen broker platform.'
                },
                {
                    uuid: "3",
                    subHeader: 'How can I confirm the number of shares that I hold?',
                    content: 'Through your account with your chosen broker.'
                },
                {
                    uuid: "4",
                    subHeader: 'How can I transfer ownership of my shares to someone else?',
                    content: 'Contact your chosen broker.'
                },
                {
                    uuid: "5",
                    subHeader: 'What should I do if I have lost/misplaced my share certificate?',
                    content: 'Contact your chosen broker.'
                },
                {
                    uuid: "6",
                    subHeader: 'Can I donate my shares to charity?',
                    content: 'Yes.'
                },
            ]
        },
        {
            heading: 'Managing your dividends',
            contentHeaders: [
                {
                    uuid: "1",
                    subHeader: 'Can I reinvest my dividend?',
                    content: 'Yes, but you would need to discuss this with your chosen broker.'
                },
                {
                    uuid: "2",
                    subHeader: 'Can my dividends be paid directly into my bank account?',
                    content: 'Yes.'
                },
                {
                    uuid: "3",
                    subHeader: 'Can I receive a dividend in my local currency?',
                    content: 'No.'
                },
            ]
        },
        {
            heading: 'Protecting your shares against fraud',
            contentHeaders: [
                {
                    uuid: "1",
                    subHeader: 'Where can I find more information on share scams and fraud?',
                    content: 'Further information can be found here: https://www.actionfraud.police.uk/a-z-of-fraud/share-sale-and-investment-fraud'
                },
                {
                    uuid: "2",
                    subHeader: 'How can I report a scam?',
                    content: 'Boiler Room Scams\n' +
                        'There are many different types of investment fraud. They usually involve criminals contacting people out of the blue and convincing them to invest in schemes or products that are worthless or do not exist. Once the criminals have received payment, they cease contact with the victim. The majority of investment frauds are run out of offices known as boiler rooms. These \'brokers\' can be very persistent and extremely persuasive, research carried out by the FCA indicates that the largest individual loss from such a scam is in the region of £6 million, with seasoned investors amongst those caught out.\n' +
                        'There are a few signs you can look for to spot a boiler room scam. They include:\n' +
                        '•\thigh pressure sales tactics that can be pushed through a variety of mediums, including email, post, seminar, or word of mouth.\n' +
                        '•\temphasis on the short-term availability of a deal to pressure investors into a quick decision.\n' +
                        '•\tan incentive for the investor, such as a free research report on the scam or another investment, a gift, or discounted dealing charges.\n' +
                        '\n' +
                        ' If you receive any unsolicited investment advice:\n' +
                        '•\tMake sure you get the correct name of the person and organization.\n' +
                        '•\tCheck that they are properly authorised by the FSA before getting involved by visiting www.fsa.gov.uk/register/ and contacting the firm using the details on the register.\n' +
                        '•\tReport the matter to the FSA either by calling 0845 606 1234 or visiting www.fsa.gov.uk/consumerinformation.\n' +
                        '•\tIf the calls persist, hang up.\n' +
                        'If you deal with an unauthorised firm, you will not be eligible to receive payment under the Financial Services Compensation Scheme. The FSA can be contacted by completing an online form at www.fsa.gov.uk/Pages/Doing/Regulated/Law/Alerts/form.shtml\n' +
                        'More detailed information on this or similar activity can be found on FSA\'s Consumer Information section at www.fsa.gov.uk/consumerinformation/scamsandswindles/investment_scams/boiler_room\n'
                },
            ]
        }
    ]

    openInNewTab = (newWindow, url) => {
        newWindow.location = url;
    }

    fetchSignedUrl = async(newWindow, file) => {
        await Promise.resolve(fetch(`https://group.markselectrical.co.uk/document?name=${file}`)
            .then(response => response.json())
            .then((data) => {
                this.setState({docUrls: data} );
            })
            .catch(console.log))

        if(this.state.docUrls.length > 0){
            this.openInNewTab(newWindow, this.state.docUrls[0].url);
        }
        else{
            console.log("No url retrieved!")
        }
    }

    handleClick = (file) => {
        const newWindow = window.open();
        this.fetchSignedUrl(newWindow, file);
    }

    handleLinkClick = (url) => {
        const newWindow = window.open();
        this.openInNewTab(newWindow, url)
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainHeaders = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileShareholderTitleRow'>
                    <Grid.Row className='shareholderColumns'>
                        <Container className='shareholderTitle'>
                            <h2 className='mobileShareholderMainHeader'>Shareholder Information</h2>
                        </Container>
                    </Grid.Row>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='shareholderTitleRow'>
                    <Grid.Column className='shareholderColumns'>
                        <Container className='shareholderTitle'>
                            <h2 className='h2'>Shareholder Information</h2>
                        </Container>
                    </Grid.Column>
                    <Grid.Column className='shareholderColumns'>
                    </Grid.Column>
                </Grid.Row>
            )
        }
    }

    mainGrid1 = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row style={{'display':'none'}} className='mobileInvestorsDataGrids'>
                    <Container className='mobileInvestorsContainer'>
                        <Grid stackable divided='vertically' className='mobileEsgDataGrid'>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns4'>
                                    <header className='esgCalendarHeadline'>DATE</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns5'>
                                    <header className='esgCalendarHeadline'>TITLE</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns4'>
                                    <header className='esgCalendarDate'>20 July 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns5'>
                                    <header className='mobileCalenderBody'>Notice of AGM</header>
                                    <header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='contactIcons'/>DOWNLOAD PDF</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns4'>
                                    <header className='esgCalendarDate'>20 July 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns5'>
                                    <header className='mobileCalenderBody'>Proxy form</header>
                                    <header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='contactIcons'/>DOWNLOAD PDF</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns4'>
                                    <header className='esgCalendarDate'>20 July 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns5'>
                                    <header className='mobileCalenderBody'>Results of shareholder voting</header>
                                    <header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='contactIcons'/>DOWNLOAD PDF</header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row style={{'display':'none'}}>
                    <Container className='esgContainer4'>
                        <Grid stackable divided='vertically' className='shareDataGrid'>
                            <Grid.Row style={{'padding-bottom':'0'}}>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarHeadline'>DATE</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarHeadline'>TITLE</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarHeadline'>DOCUMENT</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>20 July 2021</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>Notice of AGM</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='contactIcons'/>DOWNLOAD PDF</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>20 July 2021</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>Proxy form</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='contactIcons'/>DOWNLOAD PDF</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>20 July 2021</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>Results of shareholder voting</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='contactIcons'/>DOWNLOAD PDF</header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
    }

    mainGrid2 = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row>
                    <Container className='mobileEsgContainer4'>
                        <h2 className='mobileEsgStandardsHeader'>Major shareholders</h2>
                        <Grid stackable divided='vertically' className='mobileEsgDataGrid'>
                            <Grid.Row>
                                <Grid.Column className='mobileShareNameColumns'>
                                    <header className='esgCalendarHeadline'>NAME</header>
                                </Grid.Column>
                                <Grid.Column className='mobileShareNumColumns'>
                                    <header className='esgCalendarHeadline'>NUMBER OF SHARES</header>
                                </Grid.Column>
                                <Grid.Column className='mobileShareDataColumns'>
                                    <header className='esgCalendarHeadline'>%</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileShareNameColumns'>
                                    <header className='esgCalendarDate'>Mr Mark Smithson*</header>
                                </Grid.Column>
                                <Grid.Column className='mobileShareNumColumns'>
                                    <header className='mobileEsgCalendarBody'>77,278,181</header>
                                </Grid.Column>
                                <Grid.Column className='mobileShareDataColumns'>
                                    <header className='mobileEsgCalendarBody'>73.6%</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileShareNameColumns'>
                                    <header className='esgCalendarDate'>Canaccord Genuity Wealth Management</header>
                                </Grid.Column>
                                <Grid.Column className='mobileShareNumColumns'>
                                    <header className='mobileEsgCalendarBody'>5,750,000</header>
                                </Grid.Column>
                                <Grid.Column className='mobileShareDataColumns'>
                                    <header className='mobileEsgCalendarBody'>5.5%</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileShareNameColumns'>
                                    <header className='esgCalendarDate'>Stancroft Trust</header>
                                </Grid.Column>
                                <Grid.Column className='mobileShareNumColumns'>
                                    <header className='mobileEsgCalendarBody'>5,454,545</header>
                                </Grid.Column>
                                <Grid.Column className='mobileShareDataColumns'>
                                    <header className='mobileEsgCalendarBody'>5.2%</header>
                                </Grid.Column>
                            </Grid.Row>
                            
                          

                            <Grid.Row>
                                <header className='mobileSharePercentageDate'>Last updated: 09-January-24</header>
                                <header className='mobileSharePercentageDate'>*The current interests of Mark Smithson as set out above include 3,000,000 Ordinary Shares held in his 
                                capacity as trustee of the Mark Smithson Family Trust as well as 1,002,727 Ordinary Shares held by each of his sons, Jack Smithson and Oliver Smithson.</header>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row>
                    <Container className='esgContainer4'>
                        <h2 className='h2'>Major shareholders</h2>
                        <Grid stackable divided='vertically' className='shareDataGrid'>
                            <Grid.Row style={{'padding-bottom':'0'}}>
                                <Grid.Column className='shareholderDataColumnsName'>
                                    <header className='shareholderCalendarHeadline'>NAME</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle'>
                                    <header className='shareholderCalendarHeadline'>NUMBER OF SHARES</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumns2'>
                                    <header className='shareholderCalendarHeadline'>%</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName'>
                                    <header className='shareholderCalendarDate'>Mr Mark Smithson*</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle'>
                                    <header className='shareholderCalendarBody'>77,278,181</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumns2'>
                                    <header className='shareholderCalendarBody'>73.6%</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName'>
                                    <header className='shareholderCalendarDate'>Canaccord Genuity Wealth Management </header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle'>
                                    <header className='shareholderCalendarBody'>5,750,000</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumns2'>
                                    <header className='shareholderCalendarBody'>5.5%</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='shareholderDataColumnsName'>
                                    <header className='shareholderCalendarDate'>Stancroft Trust</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumnsTitle'>
                                    <header className='shareholderCalendarBody'>5,454,545</header>
                                </Grid.Column>
                                <Grid.Column className='shareholderDataColumns2'>
                                    <header className='shareholderCalendarBody'>5.2%</header>
                                </Grid.Column>
                            </Grid.Row>
                      
                            <Grid.Row>
                                <header className='sharePercentageDate'>Last updated: 13 August 2024</header>
                                <header className='sharePercentageDate'>*The current interests of Mark Smithson as set out above include 3,000,000 Ordinary Shares held in his 
                                capacity as trustee of the Mark Smithson Family Trust as well as 1,002,727 Ordinary Shares held by each of his sons, Jack Smithson and Oliver Smithson.</header>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
    }


    mainGrid5 = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row>
                <Container className='mobileEsgContainer4'>
                     <h2 className='mobileEsgStandardsHeader'>AGM Information</h2>
                    <Grid stackable divided='vertically' className='mobileResultsDataGrid'>
                    
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarHeadline'>DATE</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarHeadline'>TITLE</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>08 July 2024</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>2024 AGM NOTICE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("amg-results-july-2024.PDF")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>12 July 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>2023 AGM NOTICE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("AGM-notice-11072023.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>28 June 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>2022 AGM NOTICE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("AGM notice 27082022.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns2'>Last updated 12 July 2023.</Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                
                <Container className='esgContainer4'>           
                    <Grid stackable divided='vertically' className='resultsDataGrid'>
                    <h2 className='h2'>AGM Information</h2>
                        <Grid.Row style={{'padding-bottom':'0'}}>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarHeadline'>DATE</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarHeadline'>TITLE</header>
                            </Grid.Column>
                           
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarHeadline'>LINK</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>08 July 2023</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>2024 AGM NOTICE</header>
                            </Grid.Column>
                        
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("amg-results-july-2024.PDF")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>12 July 2023</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>2023 AGM NOTICE</header>
                            </Grid.Column>
                        
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("AGM-notice-11072023.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>28 June 2022</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>2022 AGM NOTICE</header>
                            </Grid.Column>
                        
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("AGM notice 27082022.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumns2'>Last updated 12 July 2023.</Grid.Column>
                        </Grid.Row>
                    </Grid>    
                </Container>
              
            )
        }
    }
    

    mainGrid3 = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileShareholderContainer1'>
                    <h2 className='mobileShareholderMainHeader'>Registrar contact details</h2>
                    <header className='mobileShareholderText1'>If you want to ask, or need any information, about your shareholding, please contact our registrar:</header>
                    <Container className='registrarContainer'>
                        <header className='mobileShareholderText1'>Link Group</header>
                        <header className='mobileShareholderText1'>Central Square</header>
                        <header className='mobileShareholderText1'>29 Wellington Street</header>
                        <header className='mobileShareholderText1'>Leeds</header>
                        <header className='mobileShareholderText1'>LS1 4DL</header>
                    </Container>
                    <Container className='registrarContainer'>
                        <header className='mobileShareholderText1'><img alt='phone icon' src={Phone} className='contactIcons'/>0371 664 0300</header>
                        <header style={{'padding-top':'1%', 'padding-bottom':'1%'}} className='mobileShareholderText1'>Calls are charged at the standard geographic rate and will vary by provider. Calls outside the United Kingdom will be charged at the applicable international rate. Lines are open between 09:00 - 17:30, Monday to Friday excluding public holidays in England and Wales.</header>
                        <header style={{'padding-top':'1%'}} className='mobileShareholderText1'><img alt='email icon' src={Email} className='contactIcons'/>shareholderenquiries@linkgroup.co.uk</header>
                    </Container>
                </Container>
            )
        }
        else {
            return (
                <Container className='esgContainer4'>
                    <h2 className='h2'>Registrar contact details</h2>
                    <header className='registrarText'>If you want to ask, or need any information, about your shareholding, please contact our registrar:</header>
                    <Container className='registrarContainer'>
                        <header className='registrarText'>Link Group</header>
                        <header className='registrarText'>Central Square</header>
                        <header className='registrarText'>29 Wellington Street</header>
                        <header className='registrarText'>Leeds</header>
                        <header className='registrarText'>LS1 4DL</header>
                    </Container>
                    <Container className='registrarContainer'>
                        <header className='registrarText'><img style={{'width':'25px'}} alt='phone icon' src={Phone} className='contactIcons'/>0371 664 0300</header>
                        <header style={{'padding-top':'1%', 'padding-bottom':'1%'}} className='registrarText'>Calls are charged at the standard geographic rate and will vary by provider. Calls outside the United Kingdom will be charged at the applicable international rate. Lines are open between 09:00 - 17:30, Monday to Friday excluding public holidays in England and Wales.</header>
                        <header style={{'padding-top':'1%'}} className='registrarText'><img style={{'width':'25px'}} alt='email icon' src={Email} className='contactIcons'/>shareholderenquiries@linkgroup.co.uk</header>
                    </Container>
                </Container>
            )
        }
    }

    mainGrid4 = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileShareholderContainer1'>
                    <h2 className='mobileShareholderMainHeader'>FAQs</h2>
                    {this.items.map((item) => (
                        <Container className='shareFaqContainer2'>
                            <h3 className='shareFAQHeaders'>{item.heading}</h3>
                            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                {item.contentHeaders.map((subItem) => (
                                    <AccordionItem uuid={subItem.uuid}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                {subItem.subHeader}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            {subItem.content}
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </Container>
                    ))}
                </Container>
            )
        }
        else {
            return (
                <Container className='shareFaqContainer1'>
                    <h2 className='h2'>FAQs</h2>
                    {this.items.map((item) => (
                        <Container className='shareFaqContainer2'>
                            <h3 className='shareFAQHeaders'>{item.heading}</h3>
                            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                {item.contentHeaders.map((subItem) => (
                                <AccordionItem uuid={subItem.uuid}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <header style={{'width':'80%'}}>{subItem.subHeader}</header>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <header style={{'width':'80%'}}>{subItem.content}</header>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                ))}
                            </Accordion>
                        </Container>
                    ))}
                </Container>
            )
        }
    }

    render() {
        return (
            <div className='shareholderInfo'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl="https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4"
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                    </Grid.Row>
                    {this.mainHeaders()}
                    <Grid.Row>
                        {this.mainGrid1()}
                    </Grid.Row>
                    <Grid.Row>
                        {this.mainGrid2()}
                    </Grid.Row>
                    <Grid.Row>
                        {this.mainGrid5()}
                    </Grid.Row>
                    <Grid.Row>
                        {this.mainGrid3()}
                    </Grid.Row>
                    <Grid.Row>
                        {this.mainGrid4()}
                    </Grid.Row>
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}