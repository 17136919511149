import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import {Container, Grid, Image} from "semantic-ui-react";
//import MainImage from "../Images/ContactUs-01.jpg";
import MainImage from "../Images/ContactUs-01.jpg";
import MobileMainImage from "../Images/Group 8706_5.png";
import Footer from "./Footer";
import "./ContactUs.css";
import Warehouse1 from "../Images/DSC_7650-edited.jpg";
import Arrow from "../Images/invisible box.svg";
import Phone from "../Images/phone.svg";
import Email from "../Images/email.svg";

export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    openInNewTab = (url) => {
        const newWindow = window.open();
        newWindow.location = url;
    }

    Button = (text, tag) => {
        return (
            <Container className='buttonButtonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <button className='googleButton' onClick={() => this.openInNewTab('https://www.google.com/maps/place/Marks+Electrical/@52.6633523,-1.1749886,17z/data=!3m2!4b1!5s0x487760650f3ef3d5:0x8873bea640268b71!4m5!3m4!1s0x487760e81496273d:0x6e1504b861e21892!8m2!3d52.6633491!4d-1.1727999')}><div className='googleMapsButtonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></button>
            </Container>
        );
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    bottomRow = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='contactBlueRow'>
                    <Grid className='mainGrid'>
                        <Grid.Row className='contactBottomGrid'>
                            <Container className='mobileContactAddressContainer'>
                                <header className='mobileContactSubHeader'>Head office & registered address</header>
                                <header className='mobileContactAddress'>Marks Electrical Group plc</header>
                                <header className='mobileContactAddress'>4 Boston Rd</header>
                                <header className='mobileContactAddress'>Leicester</header>
                                <header className='mobileContactAddress'>LE4 1AU</header>
                                {this.Button("GOOGLE MAPS", "maps")}
                            </Container>
                        </Grid.Row>
                        <Grid.Row className='mobileContactRow'>
                            <img alt='front of ME office building' src={Warehouse1}/>
                        </Grid.Row>
                    </Grid>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='contactBlueRow'>
                    <Grid className='contactBottomGrid'>
                        <Grid.Column className='contactBottomGridColumn'>
                            <Container className='contactAddressContainer'>
                                <header className='contactSubHeader'>Head office & registered address</header>
                                <header className='contactAddress'>Marks Electrical Group plc</header>
                                <header className='contactAddress'>4 Boston Rd</header>
                                <header className='contactAddress'>Leicester</header>
                                <header className='contactAddress'>LE4 1AU</header>
                                {this.Button("GOOGLE MAPS", "maps")}
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='contactBottomGridColumn'>
                            <img alt='front of the ME office building' src={Warehouse1} className='contactBottomImg'/>
                        </Grid.Column>
                    </Grid>
                </Grid.Row>
            )
        }
    }

    mainGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileContactContainer2'>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>INVESTOR RELATIONS</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>investors@markselectrical.co.uk</header>
                            </Grid.Column>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>GOVERNANCE AND SHAREHOLDERS</header>
                                <header className='contactNumber'><img alt='phone icon' src={Phone} className='contactIcons'/>0116 2 515 515</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>investors@markselectrical.co.uk</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>MARKETING ENQUIRIES?</header>
                                <header className='contactText'>For advertising, media and press enquiries please contact:</header>
                                <header className='contactNumber'><img alt='phone icon' src={Phone} className='contactIcons'/>020 3128 8100</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>investors@markselectrical.co.uk</header>
                            </Grid.Column>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>SALES AND CUSTOMER SERVICE</header>
                                <header className='contactNumber'><img alt='phone icon' src={Phone} className='contactIcons'/>0116 2515 515</header>
                                <header className='contactText'>Alternatively email:</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>sales@markselectrical.co.uk</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>customerservices@markselectrical.co.uk</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>TRADE ENQUIRIES</header>
                                <header className='contactNumber'><img alt='phone icon' src={Phone} className='contactIcons'/>0116 2 515 515</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>trade@markselectrical.co.uk</header>
                            </Grid.Column>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>COMPANY REGISTRATION</header>
                                <header className='contactNumber'>Company No: 13509635</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>FINANCIAL AND CORPORATE PR</header>
                                <header className='contactText'>For all financial and corporate media enquiries, please contact:</header>
                                <header className='contactNumber'><img alt='phone icon' src={Phone} className='contactIcons'/>020 7664 5095</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>markselectrical@dentonsglobaladvisors.com</header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            )
        }
        else {
            return (
                <Container className='contactContainer2'>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>INVESTOR RELATIONS</header>
                                <header className='contactNumber'><img alt='phone icon' src={Phone} className='contactIcons'/>0116 2 515 515</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>investors@markselectrical.co.uk</header>
                            </Grid.Column>
                            <Grid.Column className='contactGridMiddle'/>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>GOVERNANCE AND SHAREHOLDERS</header>
                                <header className='contactNumber'><img alt='phone icon' src={Phone} className='contactIcons'/>0116 2 515 515</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>investors@markselectrical.co.uk</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>MARKETING ENQUIRIES?</header>
                                <header className='contactText'>For advertising, media and press enquiries please contact:</header>
                                <header className='contactNumber'><img alt='phone icon' src={Phone} className='contactIcons'/>020 3128 8100</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>investors@markselectrical.co.uk</header>
                            </Grid.Column>
                            <Grid.Column className='contactGridMiddle'/>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>SALES AND CUSTOMER SERVICE</header>
                                <header className='contactText'>Call our sales helpline and customer service team on:</header>
                                <header className='contactNumber'><img alt='phone icon' src={Phone} className='contactIcons'/>0116 2515 515</header>
                                <header className='contactText'>Alternatively email:</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>sales@markselectrical.co.uk</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>customerservices@markselectrical.co.uk</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>TRADE ENQUIRIES</header>
                                <header className='contactNumber'><img alt='phone icon' src={Phone} className='contactIcons'/>0116 2 515 515</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>trade@markselectrical.co.uk</header>
                            </Grid.Column>
                            <Grid.Column className='contactGridMiddle'/>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>COMPANY REGISTRATION</header>
                                <header className='contactNumber'>Company No: 13509635</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='contactGrid'>
                                <header className='contactHeader'>FINANCIAL AND CORPORATE PR</header>
                                <header className='contactText'>For all financial and corporate media enquiries, please contact:</header>
                                <header className='contactNumber'><img alt='phone icon' src={Phone} className='contactIcons'/>020 7664 5095</header>
                                <header className='contactEmail'><img alt='email icon' src={Email} className='contactIcons'/>markselectrical@dentonsglobaladvisors.com</header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            )
        }
    }

    mainHeader = () => {
        if(this.props.mobileSite === true){
            return(
                <Container className='mobileContactContainer1'>
                    <h2 style={{'margin-left':'5%'}} className='mobileEsgHeader'>Contact us</h2>
                </Container>
            )
        }
        else {
            return(
                <Container className='contactContainer1'>
                    <h2 className='h2'>Contact us</h2>
                </Container>
            )
        }
    }

    render() {
        return (
            <div className='contactUs'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                    </Grid.Row>
                    <Grid.Row>
                        {this.mainHeader()}
                    </Grid.Row>
                    <Grid.Row className='contactBottomRow'>
                        {this.mainGrid()}
                    </Grid.Row>
                    {this.bottomRow()}
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}