import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import {Container, Grid, Image} from "semantic-ui-react";
import MainImage from "../Images/emailsignupdesktop.png";
import MobileMainImage from "../Images/emailsignupmobile.png";
import Footer from "./Footer";
import jQuery from "jquery";

export default class EmailSignUp extends Component {
    constructor(props) {
        super(props);

        this.updateWidgetHeight = this.updateWidgetHeight.bind(this);
    };

    updateWidgetHeight(event) {
        const frames = document.getElementsByTagName('iframe');
        for (let i = 0; i < frames.length; i++) {
            if (frames[i].contentWindow === event.source) {
                jQuery(frames[i]).height(event.data);
                break;
            }
        }
    }

    componentDidMount() {
        window.addEventListener('message', this.updateWidgetHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.updateWidgetHeight)
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='resultsContainer2'>
                    <h2 className='mobileResultsHeader'>E-mail sign up</h2>
                </Container>
            )
        }
        else {
            return (
                <Container className='resultsContainer2'>
                    <h2 className='h2'>E-mail sign up</h2>
                </Container>
            )
        }
    }

    render() {
        return (
            <div className='EmailSignUp'>
                <Grid>
                    <Grid.Row>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                    </Grid.Row>
                    <Grid.Row>
                        <Container className='resultsContainer1'>
                            {this.mainHeader()}
                            <Container className='rnsWidgetContainer'>
                                <iframe title='rns widget' className="rnsIFrame" src="https://irtools.co.uk/tools/news_alerts/fff72203-94cb-4f5e-a464-f7961cd3eab6"/>
                            </Container>
                        </Container>
                    </Grid.Row>
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}