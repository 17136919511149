import React, { Component } from "react";
import {Container, Image} from 'semantic-ui-react';
import Slider from "react-slick";
import AndMe from '../Images/you and me.png';
import AndMe1 from '../Images/suppliers and me.png';
import AndMe2 from '../Images/communities and me.png';
import AndMe3 from '../Images/employees and me.png';
import AndMe4 from '../Images/environment and me.png';
import ScrollArrow from "../Images/Scroll down_W.svg";
import {Link} from "react-scroll";
import {Link as LinkThrough} from "react-router-dom";
import Arrow from "../Images/invisible box.svg";

export default class Fade extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    Button = (text, tag, link) => {
        return (
            <Container className='aboutUsButtonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <LinkThrough className='aboutUsButton' to={link}><div className='homeButtonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></LinkThrough>
            </Container>
        );
    }

    MobileButton = (text, tag, link) => {
        return (
            <Container className='mobileAboutUsButtonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <LinkThrough className='aboutUsButton' to={link}><div className='homeButtonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></LinkThrough>
            </Container>
        );
    }

    firstCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileSubCopy'>
                    <h5 className='mobileSubCopy2'>Although the business has my name on it, it's always been about how we work and grow as a team.</h5>
                    {this.MobileButton("ABOUT US", "about", "/about")}
                </Container>
            )
        }
        else {
            return (
                <Container className='subCopyAlt'>
                    <h5 className='h5_w'>Although the business has my name on it, it's always been about how we work and grow as a team.</h5>
                    {this.Button("ABOUT US", "about", "/about")}
                </Container>
            )
        }
    }

    secondCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileSubCopy'>
                    <h5 className='mobileSubCopy2'>We’ve always focussed on ensuring our employees are proud to work for Marks Electrical, as our employees are the face of our company and our number one brand ambassador.</h5>
                    {this.MobileButton("CAREERS", "careers", "/careers")}
                </Container>
            )
        }
        else {
            return (
                <Container className='subCopyAlt'>
                    <h5 className='h5_w'>We’ve always focussed on ensuring our employees are proud to work for Marks Electrical, as our employees are the face of our company and our number one brand ambassador.</h5>
                    {this.Button("CAREERS", "careers", "/careers")}
                </Container>
            )
        }
    }

    thirdCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileSubCopy'>
                    <h5 className='mobileSubCopy2'>Throughout the years, the development of our relationships with our brand partners has been critical to our business success.</h5>
                    {this.MobileButton("HOW IT WORKS", "works", "/howitworks")}
                </Container>
            )
        }
        else {
            return (
                <Container className='subCopyAlt'>
                    <h5 className='h5_w'>Throughout the years, the development of our relationships with our brand partners has been critical to our business success.</h5>
                    {this.Button("HOW IT WORKS", "works", "/howitworks")}
                </Container>
            )
        }
    }

    fourthCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileSubCopy'>
                    <h5 className='mobileSubCopy2'>As a company, we are focused on ensuring that we minimise our environmental impact and that of our customers.</h5>
                    {this.MobileButton("ENVIRONMENT", "env", "/environment")}
                </Container>
            )
        }
        else {
            return (
                <Container className='subCopyAlt'>
                    <h5 className='h5_w'>As a company, we are focused on ensuring that we minimise our environmental impact and that of our customers.</h5>
                    {this.Button("ENVIRONMENT", "env", "/environment")}
                </Container>
            )
        }
    }

    fifthCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileSubCopy'>
                    <h5 className='mobileSubCopy2'>Over the years, we’ve invested in our local community through sponsorship and support.</h5>
                    {this.MobileButton("COMMUNITIES", "communities", "/community")}
                </Container>
            )
        }
        else {
            return (
                <Container className='subCopyAlt'>
                    <h5 className='h5_w'>Over the years, we’ve invested in our local community through sponsorship and support.</h5>
                    {this.Button("COMMUNITIES", "communities", "/community")}
                </Container>
            )
        }
    }

    render() {
        const settings = {
            dots: false,
            fade: true,
            infinite: true,
            speed: 700,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            draggable: false,
            autoplaySpeed: 6000,
            pauseOnHover: false,
            arrows: false
        };
        return (
            <Slider className={this.props.faderName} {...settings}>
                <div>
                    <Image className='mainImage' src={this.props.Image1} />
                    <Container className='andMeContainer'>
                        <Image className={this.props.andMe} src={AndMe} />
                    </Container>
                    <Container className='scrollArrow'>
                        <Link to="point1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                    </Container>
                    {this.firstCopy()}
                </div>
                <div>
                    <Image className='mainImage' src={this.props.Image2} />
                    <Container className='andMeContainer'>
                        <Image className={this.props.andMe} src={AndMe3} />
                    </Container>
                    <Container className='scrollArrow'>
                        <Link to="point1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                    </Container>
                    {this.secondCopy()}
                </div>
                <div>
                    <Image className='mainImage' src={this.props.Image3} />
                    <Container className='andMeContainer'>
                        <Image className={this.props.andMe} src={AndMe1} />
                    </Container>
                    <Container className='scrollArrow'>
                        <Link to="point1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                    </Container>
                    {this.thirdCopy()}
                </div>
                <div>
                    <Image className='mainImage' src={this.props.Image4} />
                    <Container className='andMeContainer'>
                        <Image className={this.props.andMe} src={AndMe4} />
                    </Container>
                    <Container className='scrollArrow'>
                        <Link to="point1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                    </Container>
                    {this.fourthCopy()}
                </div>
                <div>
                    <Image className='mainImage' src={this.props.Image5} />
                    <Container className='andMeContainer'>
                        <Image className={this.props.andMe} src={AndMe2} />
                    </Container>
                    <Container className='scrollArrow'>
                        <Link to="point1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                    </Container>
                    {this.fifthCopy()}
                </div>
            </Slider>
        );
    }
}