import React, {Component} from "react";
import {Container, Grid, Image} from "semantic-ui-react";
import MainImage from "../Images/What we did when we started.png";
import MobileMainImage from "../Images/MicrosoftTeams-image-(8)-375X550.png";
import MainHeader from "./MainHeader";
import Footer from "./Footer";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import "./Investors.css";
import HowItWorks from "../Images/newspapercuttinginvestors.png";
import ESG from "../Images/DSC_8093-editedinvestors.png";
import MobileHowItWorks from "../Images/newspapercutting-342x342.jpg";
import MobileESG from "../Images/olly-342x342.png";
import "./App.css";
import Arrow from "../Images/invisible box.svg";
import jQuery from "jquery";
import {Link} from "react-scroll";
import {Link as LinkThrough} from "react-router-dom";
import ScrollArrow from "../Images/Scroll down_W.svg";
import Download from "../Images/Download_Icon.png";
import LinkArrow from "../Images/LinkArrow.png";

export default class Investors extends Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.updateWidgetHeight = this.updateWidgetHeight.bind(this);
    };

    updateWidgetHeight(event) {
        const frames = document.getElementsByTagName('iframe');
        for (let i = 0; i < frames.length; i++) {
            if (frames[i].contentWindow === event.source) {
                jQuery(frames[i]).height(event.data);
                break;
            }
        }
    }

    componentDidMount() {
        window.addEventListener('message', this.updateWidgetHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.updateWidgetHeight)
    }

    openInNewTab = (newWindow, url) => {
        newWindow.location = url;
    }

    fetchSignedUrl = async(newWindow, file) => {
        await Promise.resolve(fetch(`https://group.markselectrical.co.uk/document?name=${file}`)
            .then(response => response.json())
            .then((data) => {
                this.setState({docUrls: data} );
            })
            .catch(console.log))

        if(this.state.docUrls.length > 0){
            this.openInNewTab(newWindow, this.state.docUrls[0].url);
        }
        else{
            console.log("No url retrieved!")
        }
    }

    handleClick = (file) => {
        const newWindow = window.open();
        this.fetchSignedUrl(newWindow, file);
    }

    handleLinkClick = (url) => {
        const newWindow = window.open();
        this.openInNewTab(newWindow, url)
    }

    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    Button = (text, tag, link) => {
        return (
            <Container className='aboutUsButtonContainer2' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <LinkThrough className='aboutUsButton' to={link}><div className='homeButtonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow'  src={Arrow}/></div></LinkThrough>
            </Container>
        );
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileInvestorsMainCopyContainer'>
                    <header className='mobileWorksSubCopy'>OPPORTUNITY</header>
                    <header className='mobileInvestorsMainCopy'>In 2006 we said 6% of MDA sales would come via the internet and this would grow to 20%</header>
                    <Container className='mobileInvestorsSubCopy'>
                        <header className='mobileSubCopy2'>Today, over 50% of sales come from this channel and it’s still growing</header>
                        {this.Button("INVESTMENT CASE", "case", "/investmentcase")}
                    </Container>
                </Container>
            )
        }
        else {
            return (
                <Container className='investorsMainCopyContainer'>
                    <header className='small_title_w'>OPPORTUNITY</header>
                    <header className='investorsMainCopy'>In 2006 we said 6% of MDA sales would come via the internet and this would grow to 20%</header>
                    <header className='investorsSubCopyText'>Today, over 50% of sales come from this channel and it’s still growing</header>
                    {this.Button("INVESTMENT CASE", "case", "/investmentcase")}
                    <Container className='investorsScrollArrow'>
                        <Link to="point1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                    </Container>
                </Container>
            )
        }
    }

    greyRow = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='investorsRows'>
                    <header className='investorsSharePriceHeader'>SHARE PRICE</header>
                    <Container className='mobileSharePriceWidgetContainer'>
                        <iframe title='share price widget' className="mobileSharePriceIFrame" src="https://irtools.co.uk/tools/share_price/2aae5d7d-6d97-444a-b91a-a3545943f1d8"/>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='investorsGreyRow'>
                    <Container className='investorsSharePrice'>
                        <header className='investorsSharePriceHeader'>SHARE PRICE</header>
                        <Container className='investorsSharePriceWidgetContainer1'>
                            <iframe title='share price widget' className="sharePriceIFrame" src="https://irtools.co.uk/tools/share_price/2aae5d7d-6d97-444a-b91a-a3545943f1d8"/>
                        </Container>
                    </Container>
                </Grid.Row>
            )
        }
    }

    imageRows = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row>
                    <Grid.Column className='mobileInvestorsDataColumns'>
                        <img className='investorsImage' alt='old ME newspaper advert clipping' src={MobileHowItWorks}/>
                        <Container className='mobileInvestorsSubContainers'>
                            <header className='mobileInvestorsSubCopy3'>Customers first, second and third</header>
                            {this.Button("HOW IT WORKS", "how", "/howitworks")}
                        </Container>
                    </Grid.Column>
                    <Grid.Column className='mobileInvestorsDataColumns'>
                        <img className='investorsImage' alt='polystyrene recycling' src={MobileESG}/>
                        <Container className='mobileInvestorsSubContainers2'>
                            <header className='mobileInvestorsSubCopy3'>It just makes sense</header>
                            {this.Button("ESG", "esg", "/esg")}
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid stackable className='investorsImagesGrid'>
                    <Grid.Row>
                        <Grid.Column className='investorsDataColumns'>
                            <img alt='old ME newspaper advert clipping' src={HowItWorks}/>
                            <Container className='investorsSubContainers'>
                                <header className='investorsSubCopy3'>Customers first, second and third</header>
                                {this.Button("HOW IT WORKS", "how", "/howitworks")}
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='investorsDataColumns'>
                            <img alt='polystyrene recycling' src={ESG}/>
                            <Container className='investorsSubContainers'>
                                <header className='investorsSubCopy3'>It just makes sense</header>
                                {this.Button("ESG", "esg", "/esg")}
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    dataGrids = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileInvestorsDataGrids'>
                    <Container className='mobileInvestorsContainer'>
                        <Grid stackable divided='vertically' className='mobileEsgDataGrid'>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns4'>
                                    <header className='esgCalendarHeadline'>DATE</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns5'>
                                    <header className='esgCalendarHeadline'>TITLE</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns4'>
                                    <header className='esgCalendarDate'>28 June 2022</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>Annual Report</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("FY22 Full Results Annual Report 27082022.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                    <header className='esgCalendarBody'>Announcement</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("FY22 Full Results Announcement 2022.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                    <header className='esgCalendarBody'>Presentation</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("FY22 Full Results Presentation 2022.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                    <header className='esgCalendarBody'>Webcast</header>
                                    <button className='fileDownload' onClick={() => this.handleLinkClick("https://www.equitydevelopment.co.uk/research/marks-electrical-investor-presentation-fy22-results?utm_medium=email&_hsmi=216126321&_hsenc=p2ANqtz-8tx1oRAHQKfISeUbD2akbwPLY1K-mriaB8sg9YqCQw7CDwFZHvu6pbUQADIiHQTGmcOxKMqqGyhaLt9ycWiDXjuh2VZw-U99sdg5Pxt6m3WsLGdmQ&utm_content=216126321&utm_source=hs_email")}><header className='mobileCalendarDownload'><img style={{'width':'20%', 'margin-bottom':'-3px'}} alt='download button icon' src={Arrow} className='downloadIcons'/>OPEN LINK</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns4'>
                                    <header className='esgCalendarDate'>11 April 2022</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns5'>
                                    <header className='esgCalendarBody'>Q4-22 TRADING UPDATE</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("ME Group FY22 Pre Close.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns4'>
                                    <header className='esgCalendarDate'>10 January 2022</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns5'>
                                    <header className='esgCalendarBody'>Q3-22 TRADING UPDATE</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("ME Group Q3-22.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns4'>
                                    <header className='esgCalendarDate'>18 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns5'>
                                    <header className='esgCalendarBody'>FY22 Interim Results Webcast</header>
                                    <button className='fileDownload' onClick={() => this.handleLinkClick("https://www.lsegissuerservices.com/spark/MARKSELECTRICALGROUP/events/328424c1-2af1-4617-9c9a-409818955438")}><header className='mobileCalendarDownload'><img style={{'width':'20%', 'margin-bottom':'-3px'}} alt='download button icon' src={Arrow} className='downloadIcons'/>OPEN LINK</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns4'>
                                    <header className='esgCalendarDate'>18 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns5'>
                                    <header className='esgCalendarBody'>FY22 Interim Results Presentation</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("Marks Electrical - FY22 Interim Results Presentation.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns4'>
                                    <header className='esgCalendarDate'>18 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns5'>
                                    <header className='esgCalendarBody'>FY22 Interim Results Announcement</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("FY22 Interim Results Announcement.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns4'>
                                    <header className='esgCalendarDate'>05 November 2021</header>
                                </Grid.Column>
                                <Grid.Column className='mobileEsgDataColumns5'>
                                    <header className='esgCalendarBody'>Admission document</header>
                                    <button className='fileDownload' onClick={() => this.handleClick("MRK - Admission Document.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='mobileEsgDataColumns2'>Last updated 28 June 2022.</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                    <Grid stackable className='investorsDataGrid'>
                        <Grid.Row>
                            <Container className='mobileInvestorsNewsTitle'>
                                <h2 className='mobileH2'>Latest news</h2>
                            </Container>
                        </Grid.Row>
                        <Grid.Row>
                            <Container className='investorsSharePriceWidgetContainer'>
                                <iframe title='rns widget' className="sharePriceIFrame2" src="https://irtools.co.uk/tools/news/9f5a6b3a-262c-4474-a2fb-f6c9489b81f1"/>
                            </Container>
                            <Container className='investorsSharePriceButtonCont1'>
                                <LinkThrough className='shareholderFinancialCalenderButton' to="/regulatorynews">REGULATORY NEWS<img alt='button icon' className='shareholderButtonArrow' src={Arrow}/></LinkThrough>
                            </Container>
                        </Grid.Row>
                    </Grid>
                    <Grid style={{'padding-left':'0', 'padding-right':'0', 'display':'none'}} divided='vertically' className='investorsDataGrid'>
                        <h2 className='mobileH2'>Financial calendar</h2>
                        <Grid.Row>
                            <Grid.Column className='mobileInvestorsDataColumns2'>
                                <header className='mobileInvestorsTableHeaders'>DATE</header>
                            </Grid.Column>
                            <Grid.Column className='mobileInvestorsDataColumns2'>
                                <header className='mobileInvestorsTableHeaders'>HEADLINE</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileInvestorsDataColumns2'>
                                <header className='investorsTableDate'>21/07/2021</header>
                            </Grid.Column>
                            <Grid.Column className='mobileInvestorsDataColumns2'>
                                <header className='investorsTableTitle'>2020 report 1</header>
                                <header>DOWNLOAD PDF</header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileInvestorsDataColumns2'>
                                <header className='investorsTableDate'>21/07/2021</header>
                            </Grid.Column>
                            <Grid.Column className='mobileInvestorsDataColumns2'>
                                <header className='investorsTableTitle'>2020 report 1</header>
                                <header>DOWNLOAD PDF</header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Row>
            )
        }
        else {
            return(
                <Grid.Row>
                    <Container className='esgContainer4'>
                        <h2 className='h2'>Results, reports & presentations</h2>
                        <Grid stackable divided='vertically' className='shareDataGrid'>
                            <Grid.Row style={{'padding-bottom':'0'}}>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarHeadline'>DATE</header>
                                </Grid.Column>
                                <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                    <header className='esgCalendarHeadline'>TITLE</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarHeadline'>DOCUMENT</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarHeadline'>LINK</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>28 June 2022</header>
                                </Grid.Column>
                                <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>FY22 FULL YEAR RESULTS</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarBody'>ANNUAL REPORT</header>
                                    <header className='esgCalendarBody'>ANNOUNCEMENT</header>
                                    <header className='esgCalendarBody'>PRESENTATION</header>
                                    <header className='esgCalendarBody'>WEBCAST</header>
                                </Grid.Column>
                                <Grid.Column style={{'width':'15%'}} className='esgDataColumns2'>
                                    <button className='fileDownload' onClick={() => this.handleClick("FY22 Full Results Annual Report 27082022.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                    <button className='fileDownload' onClick={() => this.handleClick("FY22 Full Results Announcement 2022.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                    <button className='fileDownload' onClick={() => this.handleClick("FY22 Full Results Presentation 2022.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                    <button className='fileDownload' onClick={() => this.handleLinkClick("https://www.equitydevelopment.co.uk/research/marks-electrical-investor-presentation-fy22-results?utm_medium=email&_hsmi=216126321&_hsenc=p2ANqtz-8tx1oRAHQKfISeUbD2akbwPLY1K-mriaB8sg9YqCQw7CDwFZHvu6pbUQADIiHQTGmcOxKMqqGyhaLt9ycWiDXjuh2VZw-U99sdg5Pxt6m3WsLGdmQ&utm_content=216126321&utm_source=hs_email")}><header className='esgCalendarDownload'><img alt='download button icon' src={LinkArrow} className='downloadIcons'/>OPEN LINK</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>11 April 2022</header>
                                </Grid.Column>
                                <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>Q4-22 TRADING UPDATE</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarBody'>TRADING UPDATE</header>
                                </Grid.Column>
                                <Grid.Column style={{'width':'15%'}} className='esgDataColumns2'>
                                    <button className='fileDownload' onClick={() => this.handleClick("ME Group FY22 Pre Close.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>10 January 2022</header>
                                </Grid.Column>
                                <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>Q3-22 TRADING UPDATE</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarBody'>TRADING UPDATE</header>
                                </Grid.Column>
                                <Grid.Column style={{'width':'15%'}} className='esgDataColumns2'>
                                    <button className='fileDownload' onClick={() => this.handleClick("ME Group Q3-22.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>18 November 2021</header>
                                </Grid.Column>
                                <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>FY22 INTERIM RESULTS</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarBody'>ANNOUNCEMENT</header>
                                    <header className='esgCalendarBody'>PRESENTATION</header>
                                    <header className='esgCalendarBody'>WEBCAST</header>
                                </Grid.Column>
                                <Grid.Column style={{'width':'15%'}} className='esgDataColumns2'>
                                    <button className='fileDownload' onClick={() => this.handleClick("FY22 Interim Results Announcement.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                    <button className='fileDownload' onClick={() => this.handleClick("Marks Electrical - FY22 Interim Results Presentation.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                    <button style={{'margin-left':'2%'}} className='fileDownload' onClick={() => this.handleLinkClick("https://www.lsegissuerservices.com/spark/MARKSELECTRICALGROUP/events/328424c1-2af1-4617-9c9a-409818955438")}><header className='esgCalendarDownload'><img style={{'width':'18%', 'margin-bottom':'-2%'}} alt='link button icon' src={Arrow} className='downloadIcons'/>OPEN LINK</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumnsDate'>
                                    <header className='esgCalendarDate'>05 November 2021</header>
                                </Grid.Column>
                                <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                    <header className='esgCalendarBody'>IPO</header>
                                </Grid.Column>
                                <Grid.Column className='esgDataColumns2'>
                                    <header className='esgCalendarBody'>ADMISSION DOCUMENT</header>
                                </Grid.Column>
                                <Grid.Column style={{'width':'15%'}} className='esgDataColumns2'>
                                    <button className='fileDownload' onClick={() => this.handleClick("MRK - Admission Document.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='esgDataColumns2'>Last updated 28 June 2022.</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                    <Container className='esgContainer4'>
                        <Grid.Row>
                            <Container className='investorsNewsTitle'>
                                <h2 className='h2'>Latest news</h2>
                            </Container>
                        </Grid.Row>
                        <Grid.Row>
                            <Container className='investorsSharePriceWidgetContainer1'>
                                <iframe title='rns widget' className="sharePriceIFrame2" src="https://irtools.co.uk/tools/news/9f5a6b3a-262c-4474-a2fb-f6c9489b81f1"/>
                            </Container>
                            <Container className='investorsSharePriceButtonCont1'>
                                <LinkThrough className='shareholderFinancialCalenderButton' to="/regulatorynews">REGULATORY NEWS<img alt='button arrow' className='shareholderButtonArrow' src={Arrow}/></LinkThrough>
                            </Container>
                        </Grid.Row>
                    </Container>
                    <Container style={{'display':'none'}} className='esgContainer4'>
                        <h2 className='h2'>Financial calendar</h2>
                        <Grid stackable divided='vertically' className='shareDataGrid'>
                            <Grid.Row>
                                <Grid.Column className='investorsDataColumns4'>
                                    <header className='investorsCalendarHeadline'>DATE</header>
                                </Grid.Column>
                                <Grid.Column className='investorsDataColumns2'>
                                    <header className='investorsCalendarHeadline'>HEADLINE</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='investorsDataColumns4'>
                                    <header className='esgCalendarDate'>20 July 2021</header>
                                </Grid.Column>
                                <Grid.Column className='investorsDataColumns2'>
                                    <header className='esgCalendarBody'>Price monitoring extention</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='investorsDataColumns4'>
                                    <header className='esgCalendarDate'>20 July 2021</header>
                                </Grid.Column>
                                <Grid.Column className='investorsDataColumns2'>
                                    <header className='esgCalendarBody'>Price monitoring extention</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row/>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
    }

    render() {
        return (
            <div className='investors'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                        {this.mainCopy()}
                    </Grid.Row>
                    {this.greyRow()}
                    <Grid.Row className='investorsRows'>
                        {this.imageRows()}
                    </Grid.Row>
                    {this.dataGrids()}
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}