import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import {Container, Grid, Image} from "semantic-ui-react";
import MainImage from "../Images/DSC_8191-edited.png";
import MobileMainImage from "../Images/Group 8706_6.png";
import Footer from "./Footer";
import Download from "../Images/Download_Icon.png";
import Arrow from "../Images/LinkArrow.png";
import "./Results.css";

export default class Results extends Component {
    openInNewTab = (newWindow, url) => {
        newWindow.location = url;
    }

    fetchSignedUrl = async(newWindow, file) => {
        await Promise.resolve(fetch(`https://group.markselectrical.co.uk/document?name=${file}`)
            .then(response => response.json())
            .then((data) => {
                this.setState({docUrls: data} );
            })
            .catch(console.log))

        if(this.state.docUrls.length > 0){
            this.openInNewTab(newWindow, this.state.docUrls[0].url);
        }
        else{
            console.log("No url retrieved!")
        }
    }

    handleClick = (file) => {
        const newWindow = window.open();
        this.fetchSignedUrl(newWindow, file);
    }

    handleLinkClick = (url) => {
        const newWindow = window.open();
        this.openInNewTab(newWindow, url)
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainParagraph = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='resultsContainer2'>
                    <h2 className='mobileResultsHeader'>Results, reports &amp; presentations</h2>
                </Container>
            )
        }
        else {
            return (
                <Container style={{'padding-top':'5%'}}>
                    <h2 className='h2'>Results, reports &amp; presentations</h2>
                </Container>
            )
        }
    }

    mainGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container>
                    <Grid stackable divided='vertically' className='mobileResultsDataGrid'>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarHeadline'>DATE</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarHeadline'>TITLE</header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>08 July 2024</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY24 FULL YEAR RESULTS - ANNUAL REPORT</header>
                                <button className='fileDownload' onClick={() => this.handleClick("fy24-full-results-july-2024.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>26 June 2024</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY24 PRELIMINARY RESULTS - ANNOUNCEMENT</header>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-FY24-Announcement-26062024.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>26 June 2024</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY24 PRELIMINARY RESULTS - PRESENTATION</header>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-FY24-Results-Presentation-26062024.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>15 April 2024</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY24 TRADING UPDATE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-FY-24-trading-update.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>10 January 2024</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>Q3-24 TRADING UPDATE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-HY24-january-2024.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>16 November 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>HY24 INTERIM RESULTS - ANNOUNCEMENT</header>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-HY24-november-ann-2023.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>16 November 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>HY24 INTERIM RESULTS - PRESENTATION</header>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-HY24-november-pres-2023.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>12 October 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>HY24 TRADING UPDATE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-HY24-october-2023.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>10 August 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>AGM AND TRADING UPDATE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-AGM_FY24_F-august-2023.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>10 July 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY23 FULL YEAR RESULTS - ANNUAL REPORT</header>
                                <button className='fileDownload' onClick={() => this.handleClick("FY23-Marks-Electrical-Group-Annual Report_july-2023.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>14 June 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY23 PRELIMINARY RESULTS - ANNOUNCEMENT</header>
                                <button className='fileDownload' onClick={() => this.handleClick("FY23-preliminary-results-announcement-13062023.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>14 June 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY23 PRELIMINARY RESULTS - PRESENTATION</header>
                                <button className='fileDownload' onClick={() => this.handleClick("FY23-preliminary-results-presentation-13062023.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>14 June 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY23 PRELIMINARY RESULTS - WEBCAST</header>
                                <button className='fileDownload' onClick={() => this.handleLinkClick("https://www.youtube.com/watch?v=HbvBpa5V4D4&feature=youtu.be")}><header className='mobileCalendarDownload'><img style={{'width':'20%', 'margin-bottom':'-3px'}} alt='download button icon' src={Arrow} className='downloadIcons'/>OPEN LINK</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>12 April 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY23 TRADING UPDATE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("FY23-Preliminary-Results-Announcement-11042023.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>10 January 2023</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>Q3-23 TRADING UPDATE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-Q3-23-Trading-Update.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>08 November 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>HY23 Interim Results - Announcement</header>
                                <button className='fileDownload' onClick={() => this.handleClick("FY23-Interim-Results-Announcement-112022.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>08 November 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>HY23 Interim Results - Presentation</header>
                                <button className='fileDownload' onClick={() => this.handleClick("HY23-Interim-Presentation-112022.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>08 November 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>HY23 Interim Results - Webcast</header>
                                <button className='fileDownload' onClick={() => this.handleClick("https://www.youtube.com/watch?v=UcHqhHq8zG4")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>11 October 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>HY23 Trading Update</header>
                                <button className='fileDownload' onClick={() => this.handleClick("ME Group HY23 Trading Update.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>11 August 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>AGM and Trading Update</header>
                                <button className='fileDownload' onClick={() => this.handleClick("AGM and Trading Update August 2022.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>28 June 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY22 Full Results Annual Report</header>
                                <button className='fileDownload' onClick={() => this.handleClick("FY22 Full Results Annual Report 27082022.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>28 June 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY22 Full Results Announcement</header>
                                <button className='fileDownload' onClick={() => this.handleClick("FY22 Full Results Announcement 2022.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>28 June 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY22 Full Results Presentation</header>
                                <button className='fileDownload' onClick={() => this.handleClick("FY22 Full Results Presentation 2022.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>28 June 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY22 Full Results Webcast</header>
                                <button className='fileDownload' onClick={() => this.handleLinkClick("https://www.equitydevelopment.co.uk/research/marks-electrical-investor-presentation-fy22-results?utm_medium=email&_hsmi=216126321&_hsenc=p2ANqtz-8tx1oRAHQKfISeUbD2akbwPLY1K-mriaB8sg9YqCQw7CDwFZHvu6pbUQADIiHQTGmcOxKMqqGyhaLt9ycWiDXjuh2VZw-U99sdg5Pxt6m3WsLGdmQ&utm_content=216126321&utm_source=hs_email")}><header className='mobileCalendarDownload'><img style={{'width':'20%', 'margin-bottom':'-3px'}} alt='download button icon' src={Arrow} className='downloadIcons'/>OPEN LINK</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>11 April 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>Q4-22 TRADING UPDATE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("ME Group FY22 Pre Close.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>10 January 2022</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>Q3-22 TRADING UPDATE</header>
                                <button className='fileDownload' onClick={() => this.handleClick("ME Group Q3-22.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>18 November 2021</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY22 Interim Results Webcast</header>
                                <button className='fileDownload' onClick={() => this.handleLinkClick("https://www.lsegissuerservices.com/spark/MARKSELECTRICALGROUP/events/328424c1-2af1-4617-9c9a-409818955438")}><header className='mobileCalendarDownload'><img style={{'width':'20%', 'margin-bottom':'-3px'}} alt='download button icon' src={Arrow} className='downloadIcons'/>OPEN LINK</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>18 November 2021</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>FY22 Interim Results Presentation</header>
                                <button className='fileDownload' onClick={() => this.handleClick("Marks Electrical - FY22 Interim Results Presentation.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>18 November 2021</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>HY22 Interim Results Announcement</header>
                                <button className='fileDownload' onClick={() => this.handleClick("FY22 Interim Results Announcement.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>05 November 2021</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarBody'>Admission document</header>
                                <button className='fileDownload' onClick={() => this.handleClick("MRK - Admission Document.pdf")}><header className='mobileCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns2'>Last updated 15 April 2024.</Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            )
        }
        else {
            return (
                <Container>
                    <Grid stackable divided='vertically' className='resultsDataGrid'>
                        <Grid.Row style={{'padding-bottom':'0'}}>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarHeadline'>DATE</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarHeadline'>TITLE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarHeadline'>DOCUMENT</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarHeadline'>LINK</header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>08 July 2024</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>FY24 FULL YEAR RESULTS</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>ANNUAL REPORT</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>                        
                                <button className='fileDownload' onClick={() => this.handleClick("fy24-full-results-july-2024.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>26 June 2024</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>FY24 PRELIMINARY RESULTS</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>ANNOUNCEMENT</header>
                                <header className='esgCalendarBody'>PRESENTATION</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-FY24-Announcement-26062024.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-FY24-Results-Presentation-26062024.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>15 April 2024</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>FY24 TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-FY-24-trading-update.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>10 January 2024</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>Q3-24 TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-HY24-january-2024.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>16 November 2023</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>HY24 INTERIM RESULTS</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>ANNOUNCEMENT</header>
                                <header className='esgCalendarBody'>PRESENTATION</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-HY24-november-ann-2023.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-HY24-november-pres-2023.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>12 October 2023</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>HY24 TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-HY24-october-2023.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>10 August 2023</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>AGM AND TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-AGM_FY24_F-august-2023.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>10 July 2023</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>FY23 FULL YEAR RESULTS</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>ANNUAL REPORT</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("FY23-Marks-Electrical-Group-Annual Report_july-2023.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>14 June 2023</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>FY23 PRELIMINARY RESULTS</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>ANNOUNCEMENT</header>
                                <header className='esgCalendarBody'>PRESENTATION</header>
                                <header className='esgCalendarBody'>WEBCAST</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("FY23-preliminary-results-announcement-13062023.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                <button className='fileDownload' onClick={() => this.handleClick("FY23-preliminary-results-presentation-13062023.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                <button style={{'margin-left':'2%'}} className='fileDownload' onClick={() => this.handleLinkClick("https://www.youtube.com/watch?v=HbvBpa5V4D4&feature=youtu.be")}><header className='esgCalendarDownload'><img style={{'width':'18%', 'margin-bottom':'-2%'}} alt='link button icon' src={Arrow} className='downloadIcons'/>OPEN LINK</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>12 April 2023</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>FY23 TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("FY23-Preliminary-Results-Announcement-11042023.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>10 January 2023</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>Q3-23 TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("ME-Group-Q3-23-Trading-Update.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>08 November 2022</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>HY23 INTERIM RESULTS</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>ANNOUNCEMENT</header>
                                <header className='esgCalendarBody'>PRESENTATION</header>
                                <header className='esgCalendarBody'>WEBCAST</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("FY23-Interim-Results-Announcement-112022.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                <button className='fileDownload' onClick={() => this.handleClick("HY23-Interim-Presentation-112022.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                <button style={{'margin-left':'2%'}} className='fileDownload' onClick={() => this.handleLinkClick("https://www.youtube.com/watch?v=UcHqhHq8zG4")}><header className='esgCalendarDownload'><img style={{'width':'18%', 'margin-bottom':'-2%'}} alt='link button icon' src={Arrow} className='downloadIcons'/>OPEN LINK</header></button>
                                </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>11 OCTOBER 2022</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>HY23 TRADING UPDATE </header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>TRADING UPDATE</header>
                                
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("ME Group HY23 Trading Update.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>11 AUGUST 2022</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>AGM AND TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>TRADING UPDATE</header>
                                
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("AGM and Trading Update August 2022.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>28 June 2022</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>FY22 FULL YEAR RESULTS</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>ANNUAL REPORT</header>
                                <header className='esgCalendarBody'>ANNOUNCEMENT</header>
                                <header className='esgCalendarBody'>PRESENTATION</header>
                                <header className='esgCalendarBody'>WEBCAST</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("FY22 Full Results Annual Report 27082022.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                <button className='fileDownload' onClick={() => this.handleClick("FY22 Full Results Announcement 2022.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                <button className='fileDownload' onClick={() => this.handleClick("FY22 Full Results Presentation 2022.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                <button style={{'margin-left':'2%'}} className='fileDownload' onClick={() => this.handleLinkClick("https://www.equitydevelopment.co.uk/research/marks-electrical-investor-presentation-fy22-results?utm_medium=email&_hsmi=216126321&_hsenc=p2ANqtz-8tx1oRAHQKfISeUbD2akbwPLY1K-mriaB8sg9YqCQw7CDwFZHvu6pbUQADIiHQTGmcOxKMqqGyhaLt9ycWiDXjuh2VZw-U99sdg5Pxt6m3WsLGdmQ&utm_content=216126321&utm_source=hs_email")}><header className='esgCalendarDownload'><img style={{'width':'18%', 'margin-bottom':'-2%'}} alt='link button icon' src={Arrow} className='downloadIcons'/>OPEN LINK</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>11 April 2022</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>Q4-22 TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("ME Group FY22 Pre Close.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>10 January 2022</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>Q3-22 TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>TRADING UPDATE</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("ME Group Q3-22.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>18 November 2021</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>HY22 INTERIM RESULTS</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>ANNOUNCEMENT</header>
                                <header className='esgCalendarBody'>PRESENTATION</header>
                                <header className='esgCalendarBody'>WEBCAST</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("FY22 Interim Results Announcement.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                <button className='fileDownload' onClick={() => this.handleClick("Marks Electrical - FY22 Interim Results Presentation.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                                <button style={{'margin-left':'2%'}} className='fileDownload' onClick={() => this.handleLinkClick("https://www.lsegissuerservices.com/spark/MARKSELECTRICALGROUP/events/328424c1-2af1-4617-9c9a-409818955438")}><header className='esgCalendarDownload'><img style={{'width':'18%', 'margin-bottom':'-2%'}} alt='link button icon' src={Arrow} className='downloadIcons'/>OPEN LINK</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>05 November 2021</header>
                            </Grid.Column>
                            <Grid.Column style={{'width':'35%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>IPO</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <header className='esgCalendarBody'>ADMISSION DOCUMENT</header>
                            </Grid.Column>
                            <Grid.Column className='esgDataColumns2'>
                                <button className='fileDownload' onClick={() => this.handleClick("MRK - Admission Document.pdf")}><header className='esgCalendarDownload'><img alt='download button icon' src={Download} className='downloadIcons'/>DOWNLOAD PDF</header></button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='esgDataColumns2'>Last updated 15 April 2024.</Grid.Column>
                        </Grid.Row>
                    </Grid>    
                </Container>
            )
        }
    }

    render() {
        return (
            <div className='results'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl="https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4"
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                    </Grid.Row>
                    <Grid.Row>
                        <Container className='resultsContainer1'>
                            {this.mainParagraph()}
                            {this.mainGrid()}
                        </Container>
                    </Grid.Row>
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}