import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import {Container, Grid, Image} from "semantic-ui-react";
//import MainImage from "../Images/Warehouse-loadingbay-night.png";
import MainImage from "../Images/IPODocuments-01.jpg";
//import MobileMainImage from "../Images/Group 8706_7.png";
import MobileMainImage from "../Images/IPODocuments-01.jpg";
import Footer from "./Footer";
import jQuery from "jquery";

export default class FinancialCalendar extends Component {
    constructor(props) {
        super(props);

        this.updateWidgetHeight = this.updateWidgetHeight.bind(this);
    };

    updateWidgetHeight(event) {
        const frames = document.getElementsByTagName('iframe');
        for (let i = 0; i < frames.length; i++) {
            if (frames[i].contentWindow === event.source) {
                jQuery(frames[i]).height(event.data);
                break;
            }
        }
    }

    componentDidMount() {
        window.addEventListener('message', this.updateWidgetHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.updateWidgetHeight)
    }

    openInNewTab = (newWindow, url) => {
        newWindow.location = url;
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='resultsContainer2'>
                    <h2 className='mobileResultsHeader'>Financial Calendar</h2>
                </Container>
            )
        }
        else {
            return (
                <Container className='regContainer2'>
                    <h2 className='h2'>Financial Calendar</h2>
                </Container>
            )
        }
    }

    mainGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileInvestorsContainer'>
                    <Grid stackable divided='vertically' className='mobileEsgDataGrid'>
                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarHeadline'>DATE</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='esgCalendarHeadline'>EVENT</header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>12 November 2024</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='mobileCalenderBody'>Proposed Interim Results date</header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className='mobileEsgDataColumns4'>
                                <header className='esgCalendarDate'>08 Agusut 2024</header>
                            </Grid.Column>
                            <Grid.Column className='mobileEsgDataColumns5'>
                                <header className='mobileCalenderBody'>AGM Date</header>
                            </Grid.Column>
                        </Grid.Row>
                        
                       
                      
                    </Grid>
                </Container>
            )
        }
        else {
            return (
                <Container className='ipoDocsContainer2'>
                    <Grid stackable divided='vertically' className='shareDataGrid'>
                        <Grid.Row style={{'padding-bottom': '0'}}>
                            <Grid.Column style={{'width': '50%'}} className='esgDataColumnsDate'>
                                <header className='esgCalendarHeadline'>DATE</header>
                            </Grid.Column>
                            <Grid.Column style={{'width': '50%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarHeadline'>EVENT</header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column style={{'width': '50%'}} className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>12 November 2024</header>
                            </Grid.Column>
                            <Grid.Column style={{'width': '50%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>Proposed Interim results date</header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column style={{'width': '50%'}} className='esgDataColumnsDate'>
                                <header className='esgCalendarDate'>08 August 2024</header>
                            </Grid.Column>
                            <Grid.Column style={{'width': '50%'}} className='esgDataColumnsTitle'>
                                <header className='esgCalendarBody'>AGM date</header>
                            </Grid.Column>
                        </Grid.Row>
                 
                       
                        
                    </Grid>
                </Container>
            );
        }
    }

    render() {
        return (
            <div className='ipoDocuments'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl="https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4"
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                    </Grid.Row>
                    <Grid.Row className='mobileInvestorsDataGrids'>
                        <Container className='ipoDocsContainer1'>
                            {this.mainHeader()}
                        </Container>
                        {this.mainGrid()}
                    </Grid.Row>
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}