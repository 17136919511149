import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import {Container, Grid, Image} from "semantic-ui-react";
import MainImage from "../Images/DSC_7729-edited.png";
import MobileMainImage from "../Images/mobileEnvImage.png";
import Recycling1 from "../Images/Mask Group 26.png";
//import Recycling2 from "../Images/Mask Group 27.png";
//import Recycling3 from "../Images/Group 29.png";
import Recycling2 from "../Images/Environment-01.jpg";
import Recycling3 from "../Images/Environment-02.jpg";
import Recycling4 from "../Images/Rainforest.png";
import Footer from "./Footer";
import {Link} from "react-scroll";
import ScrollArrow from "../Images/Scroll down_W.svg";
import PlayButton from "../Images/videoPlayButton.svg";
import TvBackground from "../Images/RecyclingBackground.png";
import ReactPlayer from "react-player";


export default class Environment extends Component {
    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileEnvironmentMainCopy'>
                    <header className='mobileEsgSmallTitle'>ENVIRONMENT</header>
                    <header className='mobileH1_w'>Doing our bit</header>
                    <Container className='environmentContainer1'>
                        <header className='mobileSubCopy2'>Our in-house recycling plant.</header>
                    </Container>
                </Container>
            )
        }
        else {
            return (
                <Container className='environmentMainCopy'>
                    <header className='small_title_w'>ENVIRONMENT</header>
                    <header className='h1_w'>Doing our bit</header>
                    <Container className='environmentContainer1'>
                        <header style={{'text-align':'center'}} className='h5_w'>Our in-house recycling plant.</header>
                    </Container>
                    <Container className='envScrollArrow'>
                        <Link to="recycling" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                    </Container>
                </Container>
            )
        }
    }

    bodyHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row>
                    <Container id="recycling" className='mobileEnvironmentContainer2'>
                        <h2 className='mobileEnvBodyHeader'>We recycle packaging, so our customers don’t throw it away.</h2>
                        <Container className='environmentSubContainer1'>
                            <header className='mobileEnvironmentSubHeader'>Our in-house recycling plant enables us to prepare all our waste materials for recycling on-site and has tripled the amount we recycle to approximately 750 tons per year. And successfully reducing our waste from one large 40-yard skip with two collections per week to zero.</header>
                        </Container>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row id="recycling">
                    <Container className='environmentContainer2'>
                        <h2 className='h2'>We recycle packaging, so our customers don’t throw it away.</h2>
                        <Container className='environmentSubContainer1'>
                            <header className='environmentSubHeader'>Our in-house recycling plant enables us to prepare all our waste materials for recycling on-site and has tripled the amount we recycle to approximately 750 tons per year. And successfully reducing our waste from one large 40-yard skip with two collections per week to zero.</header>
                        </Container>
                    </Container>
                </Grid.Row>
            )
        }
    }

    switchGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid stackable className='mobileEsgSwitchGrid' style={{'padding-bottom': '5%'}}>
                    <Grid.Row>
                        <Grid.Column className='worksStackColumns'>
                            <img alt='recycling baler' className='mobileEsgListImages' src={Recycling1}/>
                        </Grid.Column>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='mobileEsgStackContainers1'>
                                <header className='mobileEsgStackHeaders'>Benefits of recycling our waste materials</header>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='mobileEsgListText'>We have full control and traceability over where our waste goes</text></li>
                                    <li className='journeyLi'><text className='mobileEsgListText'>Stops our waste from going to landfill</text></li>
                                    <li className='journeyLi'><text className='mobileEsgListText'>We can report on how much we have recycled on an annual basis</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='worksStackColumns'>
                            <img alt='eps compactor' className='mobileEsgListImages' src={Recycling2}/>
                        </Grid.Column>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='mobileEsgStackContainers1'>
                                <header className='mobileEsgStackHeaders'>Our recycling activities</header>
                                <text className='mobileEsgListText'>The recycling plant comprises an HSM500 Vertical Baler, CK600 Horizontal Baler, and a CP250 EPS Compactor which are all compliant with CE regulations. </text>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='mobileEsgListText'>The HSM500 Vertical Baler is economical, simple to operate and is ideal for all waste but we will be using it for soft plastics. </text></li>
                                    <li className='journeyLi'><text className='mobileEsgListText'>The CK600 Horizontal Baler will be used solely for cardboard and reduces waste with 60 tonnes of compression. It also has a long feed-opening which allows us to cope with larger volumes of waste. </text></li>
                                    <li className='journeyLi'><text className='mobileEsgListText'>The CP250 EPS Compacter uses cold compaction to reduce our polystyrene into compact blocks. This features an innovative cooling jacket to prevent overheating. Plus, in the hopper, there is a pre-shredder and high impact crusher which can be used to condense large pieces of polystyrene.</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='worksStackColumns'>
                            <img alt='eps compactor' className='mobileEsgListImages' src={Recycling3}/>
                        </Grid.Column>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='mobileEsgStackContainers1'>
                                <header className='mobileEsgStackHeaders'>Out with the old, in with the new</header>
                                <header className='mobileEsgListText'>We help customers dispose of their old appliances in an environmentally friendly manner.</header>
                                <header className='mobileEsgListText'>The Waste Electrical and Electronic Equipment (WEEE) Directive is now UK law, aiming to make producers pay for the collection, treatment and recovery of waste electrical equipment. The regulations also mean that suppliers of equipment like high street shops and internet retailers must allow consumers to return their waste equipment for recycling.</header>
                                <header className='mobileEsgListText'>Marks Electrical are doing their bit under these regulations to offer our customers take-back of their WEEE on a like-for-like basis when you buy from us a new Electrical or Electronic product, by taking away your old appliance when we deliver your new one.</header>
                                <header className='mobileEsgListText'>Marks Electrical partners with a reputable used appliances recycling company to facilitate with the WEEE recycling process </header>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
        else {
            return (
                <Grid stackable className='investorsImagesGrid'>
                    <Grid.Row>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='environmentFirstStackContainers'>
                                <header className='esgStackHeaders'>Benefits of recycling our waste materials</header>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='environmentListText'>We have full control and traceability over where our waste goes</text></li>
                                    <li className='journeyLi'><text className='environmentListText'>Stops our waste from going to landfill</text></li>
                                    <li className='journeyLi'><text className='environmentListText'>We can report on how much we have recycled on an annual basis</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='recycling baler' className='worksListImages' src={Recycling1}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='eps compactor' className='worksListImages' src={Recycling2}/>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='environmentSecondStackContainers'>
                                <header className='esgStackHeaders'>Our recycling activities</header>
                                <text className='environmentListText'>The recycling plant comprises an HSM500 Vertical Baler, CK600 Horizontal Baler, and a CP250 EPS Compactor which are all compliant with CE regulations. </text>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='environmentListText'>The HSM500 Vertical Baler is economical, simple to operate and is ideal for all waste but we will be using it for soft plastics. </text></li>
                                    <li className='journeyLi'><text className='environmentListText'>The CK600 Horizontal Baler will be used solely for cardboard and reduces waste with 60 tonnes of compression. It also has a long feed-opening which allows us to cope with larger volumes of waste. </text></li>
                                    <li className='journeyLi'><text className='environmentListText'>The CP250 EPS Compacter uses cold compaction to reduce our polystyrene into compact blocks. This features an innovative cooling jacket to prevent overheating. Plus, in the hopper, there is a pre-shredder and high impact crusher which can be used to condense large pieces of polystyrene.</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{'padding-bottom':'8%'}}>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='environmentStackContainers'>
                                <header className='esgStackHeaders'>Out with the old, in with the new</header>
                                <header className='environmentListText'>We help customers dispose of their old appliances in an environmentally friendly manner.</header>
                                <header className='environmentListText'>The Waste Electrical and Electronic Equipment (WEEE) Directive is now UK law, aiming to make producers pay for the collection, treatment and recovery of waste electrical equipment. The regulations also mean that suppliers of equipment like high street shops and internet retailers must allow consumers to return their waste equipment for recycling.</header>
                                <header className='environmentListText'>Marks Electrical are doing their bit under these regulations to offer our customers take-back of their WEEE on a like-for-like basis when you buy from us a new Electrical or Electronic product, by taking away your old appliance when we deliver your new one.</header>
                                <header className='environmentListText'>Marks Electrical partners with a reputable used appliances recycling company to facilitate with the WEEE recycling process </header>
                            </Container>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='eps compactor' className='worksListImages' src={Recycling3}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    videoEmbed = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileEnvironmentRow1'>
                    <ReactPlayer
                        url="https://youtu.be/VbSeVXKJFvs"
                        style={{'margin-left':'10%', 'margin-top':'5%', 'margin-bottom':'1%'}}
                        width="80%"
                        height="200px"
                        playing
                        controls
                        playIcon={<img src={PlayButton}/>}
                        light={TvBackground}
                    />
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='EnvironmentRow1'>
                    <ReactPlayer
                        url="https://youtu.be/VbSeVXKJFvs"
                        style={{'margin-left':'10%', 'margin-top':'5%', 'margin-bottom':'1%'}}
                        width="80%"
                        height="45vw"
                        playing
                        controls
                        playIcon={<img src={PlayButton}/>}
                        light={TvBackground}
                    />
                </Grid.Row>
            )
        }
    }

    secondSwitchGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid stackable className='mobileEsgSwitchGrid'>
                    <Grid.Row id='carbonOffSetting'>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='mobileEsgContainer3'>
                                <h2 className='mobileEsgHeader'>Carbon off-setting</h2>
                            </Container>
                            <img alt='polystyrene recycling' className='mobileEsgListImages' src={Recycling4}/>
                        </Grid.Column>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='mobileEsgStackContainers1'>
                                <header className='mobileEsgStackHeaders'>Putting the planet first</header>
                                <header className='mobileEsgListText'>At Marks Electrical we are dedicated to offsetting our carbon footprint to help the environment. Below are just some of the great ways we work to achieve that.</header>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid inverted divided className='envBottomGrid'>
                            <Grid.Row>
                                <Grid.Column style={{'width':'100%', 'padding-top': '3%'}} className='envBottomColumns'>
                                    <text style={{'color':'#454545'}} className='mobileEnvLargeSize'>2952</text><text style={{'color':'#454545'}} className='mobileEnvSmallSize'>tonnes</text>
                                    <header style={{'color':'#454545'}} className='mobileEnvBottomHeaders'>Carbon reduction</header>
                                    <header className='mobileEnvironmentListText'>Marks Electrical funding directly impacts atmospheric CO2 levels. Our first step in our carbon offsetting journey started in FY21, with further plans for future years.</header>
                                </Grid.Column>
                            </Grid.Row>

                            {/*
                            <Grid.Row>
                                <Grid.Column style={{'width':'100%', 'padding-top': '3%'}} className='envBottomColumns'>
                                    <text style={{'color':'#454545'}} className='mobileEnvLargeSize'>1182</text><text style={{'color':'#454545'}} className='mobileEnvSmallSize'>tonnes</text>
                                    <header style={{'color':'#454545'}} className='mobileEnvBottomHeaders'>CO2 avoided</header>
                                    <header className='mobileEnvironmentListText'>In FY21 we contributed towards onshore wind energy generation in Taiwan, providing renewable energy for thousands of homes.</header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column style={{'width':'100%', 'padding-top': '3%'}} className='envBottomColumns'>
                                    <text style={{'color':'#454545'}} className='mobileEnvLargeSize'>394</text><text style={{'color':'#454545'}} className='mobileEnvSmallSize'>tonnes</text>
                                    <header style={{'color':'#454545'}} className='mobileEnvBottomHeaders'>CO2 avoided</header>
                                    <header className='mobileEnvironmentListText'>During FY21 we also helped to preserve old-growth rainforest in Peru, protecting it from degradation and deforestation.</header>
                                </Grid.Column>
                            </Grid.Row>*/ }

                        </Grid>
                    </Grid.Row>
                </Grid>
            )
        }
        else {
            return (

                <Container style={{'width':'100%', 'padding-right':'0', 'margin-bottom':'-5px'}}>
                    <img alt='polystyrene recycling'  className='worksListImages' src={Recycling4}/>
                    <Container className='forestContainer'>
                        <header className='small_title_w'>Putting the planet first</header>
                        <header className='subCopy2Text'>At Marks Electrical we are dedicated to offsetting our carbon footprint to help the environment. Below are just some of the great ways we work to achieve that.</header>
                        <Grid inverted divided className='envBottomGrid'>
                            <Grid.Row>
                                <Grid.Column className='envBottomColumns'>
                                    <text className='envLargeSize'>2952</text><text className='envSmallSize'>tonnes</text>
                                    <header style={{'color':'white'}} className='envBottomHeaders'>Carbon reduction</header>
                                    <header style={{'color':'white'}} className='environmentListText'>Marks Electrical funding directly impacts atmospheric CO2 levels. Our first step in our carbon offsetting journey started in FY21, with further plans for future years.</header>
                                </Grid.Column>

                                {/*
                                <Grid.Column className='envBottomColumns'>
                                    <text className='envLargeSize'>1182</text><text className='envSmallSize'>tonnes</text>
                                    <header style={{'color':'white'}} className='envBottomHeaders'>CO2 avoided</header>
                                    <header style={{'color':'white'}} className='environmentListText'>In FY21 we contributed towards onshore wind energy generation in Taiwan, providing renewable energy for thousands of homes.</header>
                                </Grid.Column>
                                <Grid.Column className='envBottomColumns'>
                                    <text className='envLargeSize'>394</text><text className='envSmallSize'>tonnes</text>
                                    <header style={{'color':'white'}} className='envBottomHeaders'>CO2 avoided</header>
                                    <header style={{'color':'white'}} className='environmentListText'>During FY21 we also helped to preserve old-growth rainforest in Peru, protecting it from degradation and deforestation.</header>
                                </Grid.Column>*/ }


                            </Grid.Row>
                        </Grid>
                    </Container>
                </Container>
            )
        }
    }

    dataGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid stackable divided className='mobileEnvLastRow'>
                    <Grid.Row>
                        <Grid.Column className='worksBottomColumns'>
                            <text className='mobileLargeSize'>1576</text><text className='mobileEnvSmallSize'>tonnes</text>
                            <header className='mobileEnvBottomHeaders'>Carbon reduction</header>
                            <header className='mobileEnvBottomBody'>Marks Electrical funding directly impacts atmospheric CO2 levels. Our first step in our carbon offsetting journey started in FY21, with further plans for future years.</header>
                        </Grid.Column>
                        <Grid.Column className='worksBottomColumns'>
                            <text className='mobileLargeSize'>1182</text><text className='mobileEnvSmallSize'>tonnes</text>
                            <header className='mobileEnvBottomHeaders'>CO2 avoided</header>
                            <header className='mobileEnvBottomBody'>In FY21 we contributed towards onshore wind energy generation in Taiwan, providing renewable energy for thousands of homes.</header>
                        </Grid.Column>
                        <Grid.Column className='worksBottomColumns'>
                            <text className='mobileLargeSize'>394</text><text className='mobileEnvSmallSize'>tonnes</text>
                            <header className='mobileEnvBottomHeaders'>CO2 avoided</header>
                            <header className='mobileEnvBottomBody'>During FY21 we also helped to preserve old-growth rainforest in Peru, protecting it from degradation and deforestation.</header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
        else {
            return (
                <Grid className='envBottomGrid'>
                    <Grid.Row divided>
                        <Grid.Column className='envBottomColumns'>
                            <text className='envLargeSize'>1576</text><text className='envSmallSize'>tonnes</text>
                            <header className='envBottomHeaders'>Carbon reduction</header>
                            <header className='environmentListText'>Marks Electrical funding directly impacts atmospheric CO2 levels. Our first step in our carbon offsetting journey started in FY21, with further plans for future years.</header>
                        </Grid.Column>
                        <Grid.Column className='envBottomColumns'>
                            <text className='envLargeSize'>1182</text><text className='envSmallSize'>tonnes</text>
                            <header className='envBottomHeaders'>CO2 avoided</header>
                            <header className='environmentListText'>In FY21 we contributed towards onshore wind energy generation in Taiwan, providing renewable energy for thousands of homes.</header>
                        </Grid.Column>
                        <Grid.Column className='envBottomColumns'>
                            <text className='envLargeSize'>394</text><text className='envSmallSize'>tonnes</text>
                            <header className='envBottomHeaders'>CO2 avoided</header>
                            <header className='environmentListText'>During FY21 we also helped to preserve old-growth rainforest in Peru, protecting it from degradation and deforestation.</header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    scrollArrow = () => {
        if(this.props.mobileSite !== true) {
            return (
                <Container className='scrollArrow'>
                    <Link to="envPoint1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                </Container>
            )
        }
    }

    render() {
        return (
            <div className='environment'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.scrollArrow()}
                        {this.mainImage()}
                        {this.mainCopy()}
                    </Grid.Row>
                    {this.bodyHeader()}
                    {this.switchGrid()}
                    {this.secondSwitchGrid()}
                    {this.videoEmbed()}
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}