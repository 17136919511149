import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import {Container, Grid, Image} from "semantic-ui-react";
import Footer from "./Footer";
import MainImage from "../Images/Group 8632.png";
import MobileMainImage from "../Images/Group 8706_2.png";
import Warehouse1 from "../Images/DSC_9216-edited.jpg";
//import Warehouse2 from "../Images/DSC_7460.jpg";
import Warehouse2 from "../Images/InvestmentCase-01.jpg";
//import Warehouse3 from "../Images/DSC_8958-edited.jpg";
import Warehouse3 from "../Images/InvestmentCase-02.jpg";
import Arrow from "../Images/Layer 2_blue.svg";
import {Link} from "react-scroll";
import {Link as LinkThrough} from "react-router-dom";
import ScrollArrow from "../Images/Scroll down_W.svg";


export default class InvestmentCase extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    Button = (text, tag, link) => {
        return (
            <Container className='investorsButtonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <LinkThrough className='recyclingButton' to={link}><div className='buttonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></LinkThrough>
            </Container>
        );
    }

    MobileButton = (text, tag, link) => {
        return (
            <Container className='buttonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <LinkThrough className='mobileRecyclingButton' to={link}><div className='mobileButtonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow'  src={Arrow}/></div></LinkThrough>
            </Container>
        );
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileInvestmentCaseSubContainers'>
                    <header className='mobileWorksSubCopy'>INVESTMENT CASE</header>
                    <header className='mobileH1_w'>3% share of a big opportunity</header>
                </Container>
            )
        }
        else {
            return (
                <Container className='investmentCaseSubContainers'>
                    <header className='small_title_w'>INVESTMENT CASE</header>
                    <header className='h1_w'>3% share of a big opportunity</header>
                    <Container className='esgScrollArrow'>
                        <Link to="point1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                    </Container>
                </Container>
            )
        }
    }

    bodyHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row>
                    <Container className='caseContainer1'>
                        <h2 className='mobileEsgHeader'>Investment case</h2>
                        <header className='mobileEsgBody'>In FY23, we had 2.5% market share of a £4.2 billion major domestic appliances market…</header>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row id="point1">
                    <Container className='caseContainer1'>
                        <h2 className='h2'>Investment case</h2>
                        <header className='investmentCaseHeaderText'>In FY23, we had 2.5% market share of a £4.2 billion major domestic appliances market…</header>
                    </Container>
                </Grid.Row>
            )
        }
    }

    switchGrid = () => {
        if(this.props.mobileSite === true){
            return (
                <Grid stackable className='mobileEsgSwitchGrid'>
                    <Grid.Row>
                        <Grid.Column className='worksStackColumns'>
                            <img alt='keyboard on desk' className='mobileEsgListImages' src={Warehouse1}/>
                        </Grid.Column>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='mobileEsgStackContainers1'>
                                <header className='mobileEsgStackHeaders'>Fast growing and scalable pure play ecommerce platform</header>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='mobileEsgListText'>21.5% sales growth in FY23</text></li>
                                    <li className='journeyLi'><text className='mobileEsgListText'>30+ years of retail expertise</text></li>
                                    <li className='journeyLi'><text className='mobileEsgListText'>Highly cash generative</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='worksStackColumns'>
                            <img alt='line of ME vans' className='mobileEsgListImages' src={Warehouse2}/>
                        </Grid.Column>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='mobileEsgStackContainers1'>
                                <header className='mobileEsgStackHeaders'>Differentiated business model</header>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='mobileEsgListText'>Significant investments in proprietary technology</text></li>
                                    <li className='journeyLi'><text className='mobileEsgListText'>Market leading customer service</text></li>
                                    <li className='journeyLi'><text className='mobileEsgListText'>Scalable, vertically integrated delivery model</text></li>
                                </ul>
                                {this.MobileButton("OUR BUSINESS MODEL", "business", "/howitworks")}
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='worksStackColumns'>
                            <img alt='people in office' className='mobileEsgListImages' src={Warehouse3}/>
                        </Grid.Column>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='mobileEsgStackContainers1'>
                                <header className='mobileEsgStackHeaders'>Multiple levers to accelerate growth</header>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='mobileEsgListText'>Strengthening digital marketing expertise</text></li>
                                    <li className='journeyLi'><text className='mobileEsgListText'>Investing in brand awareness</text></li>
                                    <li className='journeyLi'><text className='mobileEsgListText'>Enhanced focus on underserved and new product lines</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
        else {
            return (
                <Grid stackable className='investorsImagesGrid'>
                    <Grid.Row>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='investmentCaseStackContainers1'>
                                <header className='worksStackHeaders'>Fast growing and scalable pure play ecommerce platform</header>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='worksStackBody'>21.5% sales growth in FY23</text></li>
                                    <li className='journeyLi'><text className='worksStackBody'>30+ years of retail expertise</text></li>
                                    <li className='journeyLi'><text className='worksStackBody'>Highly cash generative</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='keyboard on desk' className='worksListImages' src={Warehouse1}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='line of ME vans' className='worksListImages' src={Warehouse2}/>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='investmentCaseStackContainers2'>
                                <header className='worksStackHeaders'>Differentiated business model</header>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='worksStackBody'>Significant investments in proprietary technology</text></li>
                                    <li className='journeyLi'><text className='worksStackBody'>Market leading customer service</text></li>
                                    <li className='journeyLi'><text className='worksStackBody'>Scalable, vertically integrated delivery model</text></li>
                                </ul>
                                {this.Button("OUR BUSINESS MODEL", "business", "/howitworks")}
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{'padding-bottom':'8%'}}>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='investmentCaseStackContainers1'>
                                <header className='worksStackHeaders'>Multiple levers to accelerate growth</header>
                                <ul className='leadershipUl'>
                                    <li className='journeyLi'><text className='worksStackBody'>Strengthening digital marketing expertise</text></li>
                                    <li className='journeyLi'><text className='worksStackBody'>Investing in brand awareness</text></li>
                                    <li className='journeyLi'><text className='worksStackBody'>Enhanced focus on underserved and new product lines</text></li>
                                </ul>
                            </Container>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='people in office' className='worksListImages' src={Warehouse3}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    render() {
        return (
            <div className='investmentCase'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                        {this.mainCopy()}
                    </Grid.Row>
                    {this.bodyHeader()}
                    {this.switchGrid()}
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}