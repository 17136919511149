import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Footer from "./Footer";
import {Container, Grid, Image} from "semantic-ui-react";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import MainImage from "../Images/DSC_8654-edited.png";
import MobileMainImage from "../Images/mobileLeadershipImage.png";
import "./Leadership.css";
import Mark from "../Images/MARKS 6 RS.png";
import Josh from "../Images/DSC_8021-edited.png";
import Marnie from "../Images/MarnieFinal.png";
import David from "../Images/davidwilkinson.png";
import Alyson from "../Images/Aly Fadil 1.png";
import Plus from "../Images/DropDown.png";
import Cross from "../Images/Cross-icon-45x45.svg";
import Arrow from "../Images/Layer 2_blue.svg";
import {Link} from "react-scroll";
import ScrollArrow from "../Images/Scroll down_W.svg";

export default class Leadership extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markOpacity: 1,
            joshOpacity: 1,
            marnieOpacity: 1,
            davidOpacity: 1,
            alysonOpacity: 1,
            markIcon: Plus,
            joshIcon: Plus,
            marnieIcon: Plus,
            davidIcon: Plus,
            alysonIcon: Plus,
            open: false,
            source: null
        }

        this.markText = this.markText.bind(this)
        this.joshText = this.joshText.bind(this)
        this.marnieText = this.marnieText.bind(this)
        this.marnieCommittee = this.marnieCommittee.bind(this)
        this.davidText = this.davidText.bind(this)
        this.davidCommittee = this.davidCommittee.bind(this)
        this.alysonText = this.alysonText.bind(this)
        this.alysonCommittee = this.alysonCommittee.bind(this)
    }

    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    Button = (text, tag) => {
        return (
            <Container className='investorsButtonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <button className='recyclingButton'><div className='buttonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></button>
            </Container>
        );
    }

    toggleImage = (source) => {
        if(this.state.source === null) {
            this.setState({source: source})
            this.setState(state => ({ open: !state.open }))
            const opacity = this.getOpacity();
            this.setOpacity(source, opacity);
            const imageName = this.getImageName();
            const stateName = `${source}Icon`;
            this.setState({ [stateName]: imageName });
        }
        else if(this.state.open === true && source === this.state.source) {
            this.setState({source: null})
            this.setState(state => ({ open: !state.open }))
            const opacity = this.getOpacity();
            this.setOpacity(source, opacity);
            const imageName = this.getImageName();
            const stateName = `${source}Icon`;
            this.setState({ [stateName]: imageName });
        }
    }

    getImageName = () => this.state.open ? Plus : Cross

    getOpacity = () => this.state.open ? 1 : 0.2

    setOpacity = (source, opacity) => {
        if(source !== "mark") {
            this.setState({ markOpacity: opacity })
        }
        if(source !== "josh") {
            this.setState({ joshOpacity: opacity })
        }
        if(source !== "marnie") {
            this.setState({ marnieOpacity: opacity })
        }
        if(source !== "david") {
            this.setState({ davidOpacity: opacity })
        }
        if(source !== "alyson") {
            this.setState({ alysonOpacity: opacity })
        }
    }

    markText(markIcon) {
        if(markIcon !== Plus) {
            return (
                <Container className='leadershipImgContainer'>
                    <header className='leadershipExpandedText'>Founded Marks Electrical in 1987, aged 21, and has overseen the growth of the company ever since.</header>
                    <header className='leadershipExpandedText'>Since founding the business, Mark has built strong relationships with key manufacturers over a long period of time and continues to drive and develop the Group's strategy and daily business operations.</header>
                    <header className='leadershipExpandedText'>Under Mark's leadership, the Group has developed into a fast growing online electrical retailer in the MDA market in the UK.</header>
                </Container>
            )
        }
    }

    joshText(joshIcon) {
        if(joshIcon !== Plus) {
            return (
                <Container className='leadershipImgContainer'>
                    <header className='leadershipExpandedText'>Joined Marks Electrical as chief financial officer in 2021.</header>
                    <header className='leadershipExpandedText'>Josh is an experienced finance professional with strong listed company experience, having held regional CFO, head of investor relations, and business development roles at Intertek Group plc and Inchcape plc.</header>
                    <header className='leadershipExpandedText'>Prior to this, Josh worked at Greenhill International &amp; Co and before joining Greenhill, qualified as a Chartered Accountant with Ernst &amp; Young.</header>
                </Container>
            )
        }
    }

    marnieText(marnieIcon) {
        if(marnieIcon !== Plus) {
            return (
                <Container className='leadershipImgContainer'>
                    <header className='leadershipExpandedText'>Marnie joined as independent Non-Executive Chair in 2021.</header>
                    <header className='leadershipExpandedText'>Marnie is an experienced public company executive and Non-Executive Director, having previously acted as Chief Executive Officer of the AIM-listed Nichols plc from 2012 until 2020.</header>
                    <header className='leadershipExpandedText'>Marnie is also currently a Non-Executive Director of Applied Nutrition Limited, a position she has held since May 2024.</header>
                </Container>
            )
        }
    }

    marnieCommittee(marnieIcon) {
        if(marnieIcon !== Plus && this.props.mobileSite === true) {
            return (
                <Container className='leadershipImgContainer'>
                    <header className='mobileLeadershipExpandedCommittee1'>External Appointments</header>
                    <ul className='leadershipUl'>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Chair of University Academy 92 Limited</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Chair of MyPura Group Limited</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Director of Applied Nutrition Limited</text></li>
                    </ul>
                    <header className='mobileLeadershipExpandedCommittee1'>Committee Membership</header>
                    <ul className='leadershipUl'>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Chair of the nominations committee</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Member of the audit committee </text></li>
                    </ul>
                </Container>
            )
        }
        else if(marnieIcon !== Plus && this.props.mobileSite !== true){
            return(
                <Container className='leadershipImgContainer'>
                    <header className='leadershipExpandedCommittee1'>External Appointments</header>
                    <ul className='leadershipUl'>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Chair of University Academy 92 Limited</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Chair of MyPura Group Limited</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Director of Applied Nutrition Limited</text></li>
                    </ul>
                    <header className='leadershipExpandedCommittee1'>Committee Membership</header>
                    <ul className='leadershipUl'>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Chair of the nominations committee</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Member of the audit committee </text></li>
                    </ul>
                </Container>
            )
        }
    }

    davidText(davidIcon) {
        if(davidIcon !== Plus) {
            return (
                <Container className='leadershipImgContainer'>
                    <header className='leadershipExpandedText'>David joined as non-executive director in 2021.</header>
                    <header className='leadershipExpandedText'>He is an experienced non-executive chairman and director and an ex-Big 4 senior partner with a specific background and experience in retail and entrepreneurial led businesses.</header>
                    <header className='leadershipExpandedText'>David is currently Senior Independent Director and Audit committee Chair at Saietta Group plc and Remuneration committee Chair at RBG Holdings plc, as well as Chair of two private companies.</header>
                </Container>
            )
        }
    }

    davidCommittee(davidIcon) {
        if(davidIcon !== Plus && this.props.mobileSite === true) {
            return (
                <Container className='leadershipImgContainer'>
                    <header className='mobileLeadershipExpandedCommittee1'>External Appointments</header>
                    <ul className='leadershipUl'>
                      {/*    <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Deputy Chair and Audit Committee chair for Saietta Group plc</text></li>*/}
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Remuneration committee chair for RBG Holdings plc</text></li>
                     {/*   <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Chair of C.H. Bailey Group Limited (formerly CH Bailey plc)</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Director of RBG Holdings PLC</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Senior Independent Director of Saietta Group plc</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Chair of Goal Group Limited</text></li>
                         <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Director of Verso Biosense Group Limited</text></li>*/}
                    </ul>
                    <header className='mobileLeadershipExpandedCommittee1'>Committee Membership</header>
                    <ul className='leadershipUl'>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Chair of the audit committee</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Member of the remuneration committee </text></li>
                    </ul>
                </Container>
            )
        }
        else if(davidIcon !== Plus && this.props.mobileSite !== true){
            return(
                <Container className='leadershipImgContainer'>
                    <header className='leadershipExpandedCommittee1'>External Appointments</header>
                    <ul className='leadershipUl'>
                         {/* <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Deputy Chair and Audit Committee chair for Saietta Group plc</text></li>*/}
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Remuneration committee chair for RBG Holdings plc</text></li>
                      {/*  <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Chair of C.H. Bailey Group Limited (formerly CH Bailey plc)</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Director of RBG Holdings PLC</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Senior Independent Director of Saietta Group plc</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Chair of Goal Group Limited</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Non-Executive Director of Verso Biosense Group Limited</text></li>*/}
                    </ul>
                    <header className='leadershipExpandedCommittee1'>Committee Membership</header>
                    <ul className='leadershipUl'>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Chair of the audit committee</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Member of the remuneration committee </text></li>
                    </ul>
                </Container>
            )
        }
    }

    alysonText(alysonIcon) {
        if(alysonIcon !== Plus) {
            return (
                <Container className='leadershipImgContainer'>
                    <header className='leadershipExpandedText'>Alyson joined as Non-Executive Director in 2021.</header>
                    <header className='leadershipExpandedText'>Alyson has over 20 years’ experience in recruitment, internal communications, talent development and building employee engaged cultures.</header>
                    <header className='leadershipExpandedText'>Alyson is the current Chief People Officer of fashion and homeware retailer Matalan, a role she has held since February 2024.</header>
                    <header className='leadershipExpandedText'>Alyson has worked in senior positions in other dynamic, fast-paced retail businesses, including Sofology and Selfridges</header>
                </Container>
            )
        }
    }

    alysonCommittee(alysonIcon) {
        if(alysonIcon !== Plus && this.props.mobileSite === true) {
            return (
                <Container className='leadershipImgContainer'>
                    <header className='mobileLeadershipExpandedCommittee1'>External appointments</header>
                    <ul className='leadershipUl'>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Chief People Officer of Matalan</text></li>
                    </ul>
                    <header className='mobileLeadershipExpandedCommittee1'>Committees</header>
                    <ul className='leadershipUl'>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Chair of the remuneration committee</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Member of the nominations committee </text></li>
                    </ul>
                </Container>
            )
        }
        else if(alysonIcon !== Plus && this.props.mobileSite !== true){
            return(
                <Container className='leadershipImgContainer'>
                    <header className='leadershipExpandedCommittee1'>External appointments</header>
                    <ul className='leadershipUl'>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Chief People Officer of Matalan</text></li>
                    </ul>
                    <header className='leadershipExpandedCommittee1'>Committees</header>
                    <ul className='leadershipUl'>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Chair of the remuneration committee</text></li>
                        <li className='journeyLi'><text className='leadershipExpandedCommittee2'>Member of the nominations committee </text></li>
                    </ul>
                </Container>
            )
        }
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image alt='main background image' className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image alt='main background image' className='mainImage' src={MainImage}/>
            )
        }
    }

    mainCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileLeadershipMainCopy'>
                    <header className='mobileEsgSmallTitle'>LEADERSHIP</header>
                    <header className='mobileH1_w'>The team to deliver.</header>
                    <header style={{'width':'80%', 'margin-left':'10%', 'margin-right':'10%'}} className='mobileSubCopy2'>Our leadership team brings a wealth of public and private company experience, positioning us well to deliver the best results for our stakeholders.</header>
                </Container>
            )
        }
        else {
            return (
                <Container className='leadershipMainCopy'>
                    <header className='small_title_w'>LEADERSHIP</header>
                    <Container className='leadershipMainText'>
                        <header className='h1_w'>The team to deliver.</header>
                    </Container>
                    <Container className='leadershipSubCopy1'>
                        <header style={{'text-align':'center'}} className='h5_w'>Our leadership team brings a wealth of public and private company experience, positioning us well to deliver the best results for our stakeholders.</header>
                    </Container>
                    <Container className='leadershipScrollArrow'>
                        <Link to="point1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                    </Container>
                </Container>
            )
        }
    }

    mainGrid = () => {
        if(this.props.mobileSite === true){
            return (
                <Grid stackable className='mobileLeadershipGrid'>
                    <Grid.Row>
                        <Grid.Column className='mobileLeadershipFirstColumn'>
                            <header className='leadershipTitle'>THE BOARD</header>
                            <header className='mobileLeadershipBody'>The Board’s primary role is to create value for shareholders, lead the Group and help the company management team implement and achieve the Group’s strategic objectives.</header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='leadershipColumns'>
                            <Container className='leadershipNamesContainer' style={{opacity: this.state.markOpacity}}>
                                <img alt='mark smithson' className='leadershipImages' src={Mark} />
                                <header className='mobileLeadershipNames'>Mark Smithson</header>
                                <header className='mobileLeadershipJobTitle'>CEO</header>
                                <Container className='mobileButtonContainer' style={{opacity: this.state.markOpacity}}>
                                    <button className='leadershipButton'><img alt='expand profile' src={this.state.markIcon} style={{width: '45px'}} onClick={() => this.toggleImage("mark")}/></button>
                                </Container>
                            </Container>
                            {this.markText(this.state.markIcon)}
                        </Grid.Column>
                        <Grid.Column className='leadershipColumns'>
                            <Container className='leadershipNamesContainer' style={{opacity: this.state.joshOpacity}}>
                                <img alt='josh egan' className='leadershipImages' src={Josh} />
                                <header className='mobileLeadershipNames'>Josh Egan</header>
                                <header className='mobileLeadershipJobTitle'>CFO</header>
                            </Container>
                            <Container className='mobileButtonContainer' style={{opacity: this.state.joshOpacity}}>
                                <button className='leadershipButton'><img alt='expand profile' src={this.state.joshIcon} style={{width: '45px'}} onClick={() => this.toggleImage("josh")}/></button>
                            </Container>
                            {this.joshText(this.state.joshIcon)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='leadershipColumns'>
                            <Container className='leadershipNamesContainer' style={{opacity: this.state.marnieOpacity}}>
                                <img alt='marnie millard' className='leadershipImages' src={Marnie} />
                                <header className='mobileLeadershipNames'>Marnie Millard OBE</header>
                                <header className='mobileLeadershipJobTitle'>Independent Non-Executive Chair</header>
                            </Container>
                            <Container className='mobileButtonContainer' style={{opacity: this.state.marnieOpacity}}>
                                <button className='leadershipButton'><img alt='expand profile' src={this.state.marnieIcon} style={{width: '45px'}} onClick={() => this.toggleImage("marnie")}/></button>
                            </Container>
                            {this.marnieText(this.state.marnieIcon)}
                            {this.marnieCommittee(this.state.marnieIcon)}
                        </Grid.Column>
                        <Grid.Column className='leadershipColumns'>
                            <Container className='leadershipNamesContainer' style={{opacity: this.state.davidOpacity}}>
                                <img alt='david wilkinson' className='leadershipImages' src={David} />
                                <header className='mobileLeadershipNames'>David Wilkinson</header>
                                <header className='mobileLeadershipJobTitle'>Independent Non-Executive</header>
                                <header className='mobileLeadershipJobTitle'> Director</header>
                            </Container>
                            <Container className='mobileButtonContainer' style={{opacity: this.state.davidOpacity}}>
                                <button className='leadershipButton'><img alt='expand profile' src={this.state.davidIcon} style={{width: '45px'}} onClick={() => this.toggleImage("david")}/></button>
                            </Container>
                            {this.davidText(this.state.davidIcon)}
                            {this.davidCommittee(this.state.davidIcon)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='leadershipFirstColumn'>
                        </Grid.Column>
                        <Grid.Column className='leadershipColumns'>
                            <Container className='leadershipNamesContainer' style={{opacity: this.state.alysonOpacity}}>
                                <img alt='alyson fadil' className='leadershipImages' src={Alyson} />
                                <header className='mobileLeadershipNames'>Alyson Fadil</header>
                                <header className='mobileLeadershipJobTitle'>Independent Non-Executive</header>
                                <header className='mobileLeadershipJobTitle'> Director</header>
                            </Container>
                            <Container className='mobileButtonContainer' style={{opacity: this.state.alysonOpacity}}>
                                <button className='leadershipButton'><img alt='expand profile' src={this.state.alysonIcon} style={{width: '45px'}} onClick={() => this.toggleImage("alyson")}/></button>
                            </Container>
                            {this.alysonText(this.state.alysonIcon)}
                            {this.alysonCommittee(this.state.alysonIcon)}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
        else {
            return (
                <Grid id="point1" className='leadershipButtonContainer'>
                    <Grid.Row>
                        <Grid.Column className='leadershipFirstColumn'>
                            <header className='leadershipTitle'>THE BOARD</header>
                            <header className='leadershipBody'>The Board’s primary role is to create value for shareholders, lead the Group and help the company management team implement and achieve the Group’s strategic objectives.</header>
                        </Grid.Column>
                        <Grid.Column className='leadershipColumns'>
                            <Container className='leadershipImgContainer' style={{opacity: this.state.markOpacity}}>
                                <img alt='mark smithson' className='leadershipImages' src={Mark} />
                                <header className='leadershipBody'>Mark Smithson</header>
                                <header className='leadershipJobTitle'>CEO</header>
                                <Container className='leadershipButtonContainer' style={{opacity: this.state.markOpacity}}>
                                    <button className='leadershipButton'><img src={this.state.markIcon} style={{width: '45px'}} alt="plus button icon" onClick={() => this.toggleImage("mark")}/></button>
                                </Container>
                            </Container>
                            {this.markText(this.state.markIcon)}
                        </Grid.Column>
                        <Grid.Column className='leadershipColumns'>
                            <Container className='leadershipImgContainer' style={{opacity: this.state.joshOpacity}}>
                                <img alt='josh egan' className='leadershipImages' src={Josh} />
                                <header className='leadershipBody'>Josh Egan</header>
                                <header className='leadershipJobTitle'>CFO</header>
                            </Container>
                            <Container className='leadershipButtonContainer' style={{opacity: this.state.joshOpacity}}>
                                <button className='leadershipButton'><img src={this.state.joshIcon} style={{width: '45px'}} alt="plus button icon" onClick={() => this.toggleImage("josh")}/></button>
                            </Container>
                            {this.joshText(this.state.joshIcon)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='leadershipFirstColumn2'>
                        </Grid.Column>
                        <Grid.Column className='leadershipColumns'>
                            <Container className='leadershipImgContainer' style={{opacity: this.state.marnieOpacity}}>
                                <img alt='marnie millard' className='leadershipImages' src={Marnie} />
                                <header className='leadershipBody'>Marnie Millard OBE</header>
                                <header className='leadershipJobTitle'>Independent Non-Executive Chair</header>
                            </Container>
                            <Container className='leadershipButtonContainer' style={{opacity: this.state.marnieOpacity}}>
                                <button className='leadershipButton'><img src={this.state.marnieIcon} style={{width: '45px'}} alt="plus button icon" onClick={() => this.toggleImage("marnie")}/></button>
                            </Container>
                            {this.marnieText(this.state.marnieIcon)}
                            {this.davidText(this.state.davidIcon)}
                        </Grid.Column>
                        <Grid.Column className='leadershipColumns'>
                            <Container className='leadershipImgContainer' style={{opacity: this.state.davidOpacity}}>
                                <img alt='david wilkinson' className='leadershipImages' src={David} />
                                <header className='leadershipBody'>David Wilkinson</header>
                                <header className='leadershipJobTitle'>Independent Non-Executive Director</header>
                            </Container>
                            <Container className='leadershipButtonContainer' style={{opacity: this.state.davidOpacity}}>
                                <button className='leadershipButton'><img src={this.state.davidIcon} style={{width: '45px'}} alt="plus button icon" onClick={() => this.toggleImage("david")}/></button>
                            </Container>
                            {this.davidCommittee(this.state.davidIcon)}
                            {this.marnieCommittee(this.state.marnieIcon)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='leadershipFirstColumn2'>
                        </Grid.Column>
                        <Grid.Column className='leadershipColumns'>
                            <Container className='leadershipImgContainer' style={{opacity: this.state.alysonOpacity}}>
                                <img alt='alyson fadil' className='leadershipImages' src={Alyson} />
                                <header className='leadershipBody'>Alyson Fadil</header>
                                <header className='leadershipJobTitle'>Independent Non-Executive Director</header>
                            </Container>
                            <Container className='leadershipButtonContainer' style={{opacity: this.state.alysonOpacity}}>
                                <button className='leadershipButton'><img src={this.state.alysonIcon} style={{width: '45px'}} alt="plus button icon" onClick={() => this.toggleImage("alyson")}/></button>
                            </Container>
                        </Grid.Column>
                        <Grid.Column className='leadershipColumns'>
                            {this.alysonText(this.state.alysonIcon)}
                            {this.alysonCommittee(this.state.alysonIcon)}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    scrollArrow = () => {
        if(this.props.mobileSite !== true) {
            return (
                <Container className='scrollArrow'>
                    <Link to="leadershipPoint1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                </Container>
            )
        }
    }


    render() {
        return (
            <div className='leadership'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.scrollArrow()}
                        {this.mainImage()}
                        {this.mainCopy()}
                    </Grid.Row>
                    <Grid.Row style={{'padding-top':'5%'}} className='leadershipRow'>
                        {this.mainGrid()}
                    </Grid.Row>
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}
