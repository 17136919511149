import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import Footer from "./Footer";
import {Container, Grid, Image} from "semantic-ui-react";
import MainImage from "../Images/Group 8088.png";
import MobileMainImage from "../Images/MicrosoftTeams-image-375X550.png";
import TrustBox from "./TrustBox";
//import Laptop1 from "../Images/Group 8621.png";
import Laptop1 from "../Images/Howitworks.png";
import Laptop2 from "../Images/Group 8622.png";
//import Van from "../Images/Mask Group 13.png";
import Van from "../Images/HowItWorks-01.jpg";
import Repeat from "../Images/Group 8623.png";
//import Driver from "../Images/DSC_8437-edited.png";
import Driver from "../Images/HowItWorks-05.jpg";
//import Warehouse1 from "../Images/DSC_9169-edited.jpg";
import Warehouse1 from "../Images/HowItWorks-02.jpg";
//import Warehouse2 from "../Images/DSC_8979-edited.jpg";
import Warehouse2 from "../Images/HowItWorks-04.jpg";
//import Warehouse3 from "../Images/DSC_8942-edited.jpg";
import Warehouse3 from "../Images/HowItWorks-03.jpg";
//import Warehouse4 from "../Images/DSC_7446-edited.png";
import Warehouse4 from "../Images/HowItWorks-06.jpg";
import Miele from "../Images/Miele Logo2.svg";
import Samsung from "../Images/Samsung_Logo2.svg";
import Bosch from "../Images/bosch-logo-simple2.svg";
import Neff from "../Images/Group 82482.svg";
import Hotpoint from "../Images/Path 21552.svg";
import LG from "../Images/g24902.svg";
import AEG from "../Images/g102.svg";
import Smeg from "../Images/Group 82532.svg";
import RangeMaster from "../Images/RM-Master-Brand-logo copy.png";
import Hoover from "../Images/g30272.svg";
import Arrow from "../Images/Layer 2_blue.svg";
import { StickyContainer, Sticky } from 'react-sticky';
import {Link} from "react-scroll";

export default class HowItWorks extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const aScript = document.createElement('script');
        aScript.type = 'text/javascript';
        aScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        aScript.async = "true"
        document.head.appendChild(aScript);
        aScript.onload = function () {
            const trustbox = document.getElementById('trustbox');
            window.Trustpilot.loadFromElement(trustbox);
        };
    }

    handleMouseEnter = (tag) => {
        this.setState({
            [tag.tag]: 7,
        });
    }

    handleMouseLeave = (tag) => {
        this.setState({
            [tag.tag]: 2,
        });
    }

    Button = (text, tag) => {
        return (
            <Container className='investorsButtonContainer' onMouseEnter={() => this.handleMouseEnter({tag})} onMouseLeave={() => this.handleMouseLeave({tag})}>
                <button className='recyclingButton'><div className='buttonText'>{text}</div><div className='arrow' style={{ left: this.state[tag]}}><img alt='button arrow' src={Arrow}/></div></button>
            </Container>
        );
    }

    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileWorksMainCopy'>
                    <header className='mobileWorksSubCopy'>HOW IT WORKS</header>
                    <header className='mobileH1_w'>Customers first,</header>
                    <header className='mobileH1_w'>second and third.</header>
                    <header style={{'margin-left':'10%', 'margin-right':'10%', 'margin-top':'3%'}} className='mobileSubCopy2'>Get this right and it powers everything else.</header>
                    <TrustBox className='mobileworkstrustbox-widget'/>
                </Container>
            )
        }
        else {
            return (
                <Container className='worksMainCopy'>
                    <header className='small_title_w'>HOW IT WORKS</header>
                    <header className='h1_w'>Customers first,</header>
                    <header className='h1_w'>second and third.</header>
                    <Container className='worksMainSubCopy'>
                        <header className='worksSubHeader2'>Get this right and it powers everything else.</header>
                    </Container>
                    <TrustBox className='workstrustbox-widget'/>
                </Container>
            )
        }
    }

    bodyHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileWorksContainer1'>
                    <h2 style={{'text-align':'center'}} className='mobileH2'>An excellent  online shopping experience</h2>
                    <header className='mobileEnvironmentSubHeader'>Simple, clear and intuitive website developed on in-house platform to provide a frictionless customer experience. We take pride in putting our customers first and delivering market leading customer services.</header>
                </Container>
            )
        }
        else {
            return (
                <Container className='worksContainer1'>
                    <h2 className='h2'>An excellent  online shopping experience</h2>
                    <header className='environmentSubHeader'>Simple, clear and intuitive website developed on in-house platform to provide a frictionless customer experience. We take pride in putting our customers first and delivering market leading customer services.</header>
                </Container>
            )
        }
    }

    stepsGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid className='worksGrid1'>
                    <Grid.Column className='mobileWorksColumns1'>
                        <img alt='laptop with ME shop website open' className='worksListImages' src={Laptop1}/>
                        <header className='mobileWorksListBody'>1. Great customer site. Drives sales.</header>
                    </Grid.Column>
                    <Grid.Column className='mobileWorksColumns1'>
                        <img alt='ME delivery van' className='worksListImages' src={Van}/>
                        <header className='mobileWorksListBody'>2. We provide a market leading next day installation service.</header>
                    </Grid.Column>
                    <Grid.Column className='mobileWorksColumns1'>
                        <img alt='laptop with ME trust pilot page open' className='worksListImages' src={Laptop2}/>
                        <header className='mobileWorksListBody'>3. Great service and brilliant price.</header>
                    </Grid.Column>
                    <Grid.Column className='mobileWorksColumns1'>
                        <img alt='loop icon' className='worksListImages' src={Repeat}/>
                        <header className='mobileWorksListBody'>4. Repeat.</header>
                    </Grid.Column>
                   
                </Grid>
            )
        }
        else {
            return (
                <Grid stackable className='worksGrid1'>
                    <Grid.Column className='worksColumns1'>
                        <img alt='laptop with ME shop website open' className='worksListImages' src={Laptop1}/>
                        <header className='worksListBody'>1. Great customer site. Drives sales.</header>
                        <ul className='leadershipUl'>
                            <li className='journeyLi'><text className='worksListSubBody'>Frictionless website and online shopping experience</text></li>
                        </ul>
                    </Grid.Column>
                    <Grid.Column className='worksColumns1'>
                        <img alt='ME delivery van' className='worksListImages' src={Van}/>
                        <header className='worksListBody'>2. We provide a market leading next day installation service.</header>
                        <ul className='leadershipUl'>
                            <li className='journeyLi'><text className='worksListSubBody'>Friendly and expertly trained delivery and installation team put customers at ease</text></li>
                            <li className='journeyLi'><text className='worksListSubBody'>Vertically integrated delivery with in-house fleet of vehicles and drivers</text></li>
                            <li className='journeyLi'><text className='worksListSubBody'>Old goods collected and recycled as part of the delivery service</text></li>
                        </ul>
                    </Grid.Column>
                    <Grid.Column className='worksColumns1'>
                        <img alt='laptop with ME trust pilot page open' className='worksListImages' src={Laptop2}/>
                        <header className='worksListBody'>3. Great service and brilliant price.</header>
                        <ul className='leadershipUl'>
                            <li className='journeyLi'><text className='worksListSubBody'>Staff are incentivised to offer superior customer service</text></li>
                            <li className='journeyLi'><text className='worksListSubBody'>Knowledgeable staff with regular product training from manufacturers</text></li>
                            <li className='journeyLi'><text className='worksListSubBody'>Minimal-to-no hold time and ‘one call resolution’ ethos at the dedicated UK call centre</text></li>
                            <li className='journeyLi'><text className='worksListSubBody'>Seamless returns process for any faulty goods</text></li>
                        </ul>
                    </Grid.Column>
                    <Grid.Column className='worksColumns1'>
                        <img alt='loop icon' className='worksListImages' src={Repeat}/>
                        <header className='worksListBody'>4. Repeat.</header>
                        <ul className='leadershipUl'>
                            <li className='journeyLi'><text className='worksListSubBody'>Repeat customer targeting to increase repeat rates</text></li>
                        </ul>
                    </Grid.Column>
                </Grid>
            )
        }
    }

    switchGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid stackable className='mobileEsgSwitchGrid' id="worksPoint2">
                    <Grid.Row className='worksRow'>
                        <Container className='mobileWorksContainer5'>
                            <h2 className='mobileH2'>Technology-driven operating model</h2>
                            <header className='mobileWorksBody'>We utilise technology throughout our business model to minimise human intervention and maximise efficiency, keeping overheads controlled.</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='worksRow'>
                        <Grid.Column className='mobileWorksStackColumns'>
                            <img alt='people in office' className='mobileEsgListImages' src={Warehouse1}/>
                        </Grid.Column>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='mobileEsgStackContainers1'>
                                <header className='mobileEsgStackHeaders'>Operating Platform</header>
                                <header className='mobileEsgListText'>Our highly bespoke operating platform allows for seamless and frictionless process control from order placement to delivery.</header>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='worksRow'>
                        <Grid.Column className='mobileWorksStackColumns'>
                            <img alt='man at computer' className='mobileEsgListImages' src={Warehouse2}/>
                        </Grid.Column>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='mobileEsgStackContainers1'>
                                <header className='mobileEsgStackHeaders'>Logistics Management</header>
                                <header className='mobileEsgListText'>We utilise software to optimise our routing and ensure efficient deliveries for both our customer and our drivers.</header>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='worksRow'>
                        <Grid.Column className='mobileWorksStackColumns'>
                            <img alt='man at computer' className='mobileEsgListImages' src={Warehouse3}/>
                        </Grid.Column>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='mobileEsgStackContainers1'>
                                <header className='mobileEsgStackHeaders'>Customer Interface</header>
                                <header className='mobileEsgListText'>Its essential to our customer and essential to us that we keep the customer updated throughout every step of the delivery process.</header>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
        else {
            return (
                <Grid stackable className='investorsImagesGrid' id="worksPoint2">
                    <Grid.Row className='worksRow'>
                        <Container style={{'padding-top':'0'}} className='worksContainer4'>
                            <h2 className='h2'>Technology-driven operating model</h2>
                            <header className='worksBodyText1'>We utilise technology throughout our business model to minimise human intervention and maximise efficiency, keeping overheads controlled.</header>
                        </Container>
                    </Grid.Row>
                    <Grid.Row className='worksRow'>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='worksStackContainers1'>
                                <header className='worksStackHeaders'>Operating Platform</header>
                                <header className='worksBodyText1'>Our highly bespoke operating platform allows for seamless and frictionless process control from order placement to delivery.</header>
                            </Container>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='people in office' className='worksListImages' src={Warehouse1}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='worksRow'>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='man at computer' className='worksListImages' src={Warehouse2}/>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='worksStackContainers2'>
                                <header className='worksStackHeaders'>Logistics Management</header>
                                <header className='worksBodyText1'>We utilise software to optimise our routing and ensure efficient deliveries for both our customer and our drivers.</header>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='worksRow'>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container className='worksStackContainers1'>
                                <header className='worksStackHeaders'>Customer Interface</header>
                                <header className='worksBodyText1'>Its essential to our customer and essential to us that we keep the customer updated throughout every step of the delivery process.</header>
                            </Container>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='man at computer' className='worksListImages' src={Warehouse3}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    secondSwitchGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid stackable className='mobileEsgSwitchGrid'>
                    <Grid.Row id="worksPoint3" className='worksRow'>
                        <Container className='mobileWorksContainer5'>
                            <h2 className='mobileH2'>Simple and proven distribution network</h2>
                            <header className='mobileWorksBody'>Single national distribution centre that caters for next day delivery to 90% of England’s population, with our own drivers and fleet, delivering a high-quality customer service.</header>
                            <header className='mobileWorksBody'>Allows for cost- and time-effective delivery to our customers across the country.</header>
                        </Container>
                        <Grid.Column className='mobileWorksStackColumns'>
                            <img alt='man in warehouse moving package' className='mobileEsgListImages' src={Driver}/>
                        </Grid.Column>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='worksStackContainers2'>
                                <header className='mobileEsgStackHeaders'>Operating Platform</header>
                                <header className='mobileEsgListText'>Positioned close to the M1 in the centre of England leading to fast and efficient access to the majority of the UK population</header>
                            </Container>
                            <Container className='worksStackContainers2'>
                                <header className='mobileEsgStackHeaders'>Simple but optimal</header>
                                <header className='mobileEsgListText'>One distribution warehouse with no additional facilities or overnight trunking, leading to reduced overheads, simplicity in the operating model and improved profitability</header>
                            </Container>
                            <Container className='worksStackContainers2'>
                                <header className='mobileEsgStackHeaders'>Next day delivery</header>
                                <header className='mobileEsgListText'>Orders placed on in-stock items before 5:30pm are eligible for next day delivery to > 90% of the UK population using Marks Electricals’ owned and managed Mercedes fleet of delivery vehicles.</header>
                            </Container>
                            <Container className='worksStackContainers2'>
                                <header className='mobileEsgStackHeaders'>Installation</header>
                                <header className='mobileEsgListText'>We can offer next day installation for products ordered before 5:30pm depending on availability.</header>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row id="worksPoint4"  className='worksRow'>
                        <Container className='mobileWorksContainer5'>
                            <h2 className='mobileH2'>Scalable, vertically integrated delivery model</h2>
                            <header className='mobileWorksBody'>Operating our own highly trained team that specialise in the delivery, installation and removal of unfriendly freight</header>
                        </Container>
                        <Grid.Column className='mobileWorksStackColumns'>
                            <img alt='line of ME delivery vans' className='mobileWorksImages' src={Warehouse4}/>
                        </Grid.Column>
                        <Grid.Column className='worksStackColumns'>
                            <Container className='worksStackContainers2'>
                                <header className='mobileEsgStackHeaders'>Skilled and fully trained driving and installation team</header>
                                <header className='mobileEsgListText'>Positioned close to the M1 in the centre of England leading to fast and efficient access to the majority of the UK population</header>
                            </Container>
                            <Container className='worksStackContainers2'>
                                <header className='mobileEsgStackHeaders'>Fully aligned team</header>
                                <header className='mobileEsgListText'>Incentivisation scheme encourages the highest quality of customer service throughout the company</header>
                            </Container>
                            <Container className='worksStackContainers2'>
                                <header className='mobileEsgStackHeaders'>ME deliveries</header>
                                <header className='mobileEsgListText'>Over 94% of orders are delivered by ME’s delivery team straight to customer’s house – not outsourced</header>
                            </Container>
                            <Container className='worksStackContainers2'>
                                <header className='mobileEsgStackHeaders'>High quality fleet</header>
                                <header className='mobileEsgListText'>Current fleet of owned Mercedes vans with an additional vehicles on order – all with 3 year unlimited mileage warranty</header>
                            </Container>
                            <Container className='worksStackContainers2'>
                                <header className='mobileEsgStackHeaders'>Customised design</header>
                                <header className='mobileEsgListText'>ME branded and customised fleet drive maximum efficiency</header>
                            </Container>
                            <Container className='worksStackContainers2'>
                                <header className='mobileEsgStackHeaders'>On-site capabilities</header>
                                <header className='mobileEsgListText'>On-site mechanic and refuelling station allows vehicles to be fuelled and repaired overnight</header>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
        else {
            return (
                <Grid stackable className='investorsImagesGrid'>
                    <Grid.Row id="worksPoint3" className='worksRow'>
                        <Container className='worksContainer4'>
                            <h2 className='h2'>Simple and proven distribution network</h2>
                            <header className='worksBodyText1'>Single national distribution centre that caters for next day delivery to 90% of England’s population, with our own drivers and fleet, delivering a high-quality customer service.</header>
                            <header className='worksBodyText1'>Allows for cost- and time-effective delivery to our customers across the country.</header>
                        </Container>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='man in warehouse moving package' className='worksListImages' src={Driver}/>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'0'}} className='investorsDataColumns'>
                            <Container className='worksStackContainers2'>
                                <header className='worksStackHeaders'>Operating Platform</header>
                                <header className='worksBodyText1'>Positioned close to the M1 in the centre of England leading to fast and efficient access to the majority of the UK population</header>
                            </Container>
                            <Container className='worksStackContainers2'>
                                <header className='worksStackHeaders'>Simple but optimal</header>
                                <header className='worksBodyText1'>One distribution warehouse with no additional facilities or overnight trunking, leading to reduced overheads, simplicity in the operating model and improved profitability</header>
                            </Container>
                            <Container className='worksStackContainers2'>
                                <header className='worksStackHeaders'>Next day delivery</header>
                                <header className='worksBodyText1'>Orders placed on in-stock items before 5:30pm are eligible for next day delivery to > 90% of the UK population using Marks Electricals’ owned and managed Mercedes fleet of delivery vehicles.</header>
                            </Container>
                            <Container className='worksStackContainers2'>
                                <header className='worksStackHeaders'>Installation</header>
                                <header className='worksBodyText1'>We can offer next day installation for products ordered before 5:30pm depending on availability.</header>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row id="worksPoint4" className='worksRow'>
                        <Container className='worksContainer5'>
                            <h2 className='h2'>Scalable, vertically integrated delivery model</h2>
                            <header className='worksBodyText1'>Operating our own highly trained team that specialise in the delivery, installation and removal of unfriendly freight</header>
                        </Container>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <Container style={{'padding-top':'0'}} className='worksStackContainers3'>
                                <header className='worksStackHeaders'>Skilled and fully trained driving and installation team</header>
                                <header className='worksBodyText1'>Positioned close to the M1 in the centre of England leading to fast and efficient access to the majority of the UK population</header>
                            </Container>
                            <Container className='worksStackContainers3'>
                                <header className='worksStackHeaders'>Fully aligned team</header>
                                <header className='worksBodyText1'>Incentivisation scheme encourages the highest quality of customer service throughout the company</header>
                            </Container>
                            <Container className='worksStackContainers3'>
                                <header className='worksStackHeaders'>ME deliveries</header>
                                <header className='worksBodyText1'>Over 94% of orders are delivered by ME’s delivery team straight to customer’s house – not outsourced</header>
                            </Container>
                            <Container className='worksStackContainers3'>
                                <header className='worksStackHeaders'>High quality fleet</header>
                                <header className='worksBodyText1'>Current fleet of owned Mercedes vans with an additional vehicles on order – all with 3 year unlimited mileage warranty</header>
                            </Container>
                            <Container className='worksStackContainers3'>
                                <header className='worksStackHeaders'>Customised design</header>
                                <header className='worksBodyText1'>ME branded and customised fleet drive maximum efficiency</header>
                            </Container>
                            <Container className='worksStackContainers3'>
                                <header className='worksStackHeaders'>On-site capabilities</header>
                                <header className='worksBodyText1'>On-site mechanic and refuelling station allows vehicles to be fuelled and repaired overnight</header>
                            </Container>
                        </Grid.Column>
                        <Grid.Column style={{'padding-top':'4%'}} className='investorsDataColumns'>
                            <img alt='line of ME delivery vans' className='worksListImages' src={Warehouse4}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    bottomGridHeader = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row id="worksPoint5" className='worksRow'>
                    <Container className='mobileWorksContainer5'>
                        <h2 className='mobileH2'>Stocking products that meet changing market demand and trends</h2>
                        <header className='mobileWorksBody'>Buying the right products at the right time, understanding our customers with knowledge built
                            over 30+ years
                        </header>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row id="worksPoint5" className='worksRow'>
                    <Container className='worksContainer6'>
                        <h2 className='h2'>Stocking products that meet changing market demand and trends</h2>
                        <header className='worksBodyText1'>Buying the right products at the right time, understanding our customers with knowledge built
                            over 30+ years
                        </header>
                    </Container>
                </Grid.Row>
            )
        }
    }

    bottomGrid = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid style={{'margin-bottom':'5%'}} className='mobileWorksBottomGrid' stackable>
                    <Grid.Row className='workBrandsRow1'>
                        <Grid.Column className='mobileWorksBrands'>
                            <img style={{ 'width':'80%'}} alt='Bosch logo' src={Bosch}/>
                        </Grid.Column>
                        <Grid.Column className='mobileWorksBrands'>
                            <img style={{ 'width':'80%'}} alt='Samsung logo' src={Samsung}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='worksRow'>
                        <Grid.Column className='mobileWorksBrands'>
                            <img style={{ 'width':'80%'}} alt='Miele logo' src={Miele}/>
                        </Grid.Column>
                        <Grid.Column className='mobileWorksBrands'>
                            <img style={{ 'width':'80%'}} alt='Neff logo' src={Neff}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='worksRow'>
                        <Grid.Column className='mobileWorksBrands'>
                            <img style={{ 'width':'80%', 'padding-top':'5%'}} alt='Hotpoint logo' src={Hotpoint}/>
                        </Grid.Column>
                        <Grid.Column className='mobileWorksBrands'>
                            <img style={{ 'width':'60%'}} alt='LG logo' src={LG}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='worksRow'>
                        <Grid.Column className='mobileWorksBrands'>
                            <img style={{ 'width':'60%'}} alt='AEG logo' src={AEG}/>
                        </Grid.Column>
                        <Grid.Column className='mobileWorksBrands'>
                            <img style={{ 'width':'80%'}} alt='Smeg logo' src={Smeg}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='workBrandsRow2'>
                        <Grid.Column className='mobileWorksBrands'>
                            <img style={{ 'width':'80%', 'padding-top':'16%'}} alt='RangeMaster logo' src={RangeMaster}/>
                        </Grid.Column>
                        <Grid.Column className='mobileWorksBrands'>
                            <img style={{ 'width':'50%'}} alt='Hoover logo' src={Hoover}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
        else {
            return (
                <Grid>
                    <Grid.Row className='workBrandsRow1'>
                        <Grid.Column className='worksBrands'>
                            <img style={{ 'width':'80%', 'padding-top':'2%' }} alt='Bosch logo' src={Bosch}/>
                        </Grid.Column>
                        <Grid.Column className='worksBrands'>
                            <img style={{ 'width':'80%', 'padding-top':'5%' }} alt='Samsung logo' src={Samsung}/>
                        </Grid.Column>
                        <Grid.Column className='worksBrands'>
                            <img style={{ 'width':'80%' }} alt='Miele logo' src={Miele}/>
                        </Grid.Column>
                        <Grid.Column className='worksBrands'>
                            <img style={{ 'width':'80%' }} alt='Neff logo' src={Neff}/>
                        </Grid.Column>
                        <Grid.Column className='worksBrands'>
                            <img style={{ 'width':'80%' }} alt='Hotpoint logo' src={Hotpoint}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='workBrandsRow2'>
                        <Grid.Column className='worksBrands'>
                            <img style={{ 'width':'60%' }} alt='LG logo' src={LG}/>
                        </Grid.Column>
                        <Grid.Column className='worksBrands'>
                            <img style={{ 'width':'55%', 'padding-top':'5%' }} alt='AEG logo' src={AEG}/>
                        </Grid.Column>
                        <Grid.Column className='worksBrands'>
                            <img style={{ 'width':'80%', 'padding-top':'12%' }} alt='Smeg logo' src={Smeg}/>
                        </Grid.Column>
                        <Grid.Column className='worksBrands'>
                            <img style={{ 'width':'80%', 'padding-top':'12%'  }} alt='RangeMaster logo' src={RangeMaster}/>
                        </Grid.Column>
                        <Grid.Column className='worksBrands'>
                            <img style={{ 'width':'35%' }} alt='Hoover logo' src={Hoover}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    lastRow = () => {
        if(this.props.mobileSite === true) {/*
            return (
                <Grid stackable className='mobileWorksLastRow'>
                    <Grid.Column className='worksBottomColumns'>
                        <header className='mobileWorksBottomHeader'>50 brand partners</header>
                        <header className='mobileWorksBody'>We stock a wide range of premium brands</header>
                    </Grid.Column>
                    <Grid.Column className='worksBottomColumns'>
                        <header className='mobileWorksBottomHeader'>Top 10 brands</header>
                        <header className='mobileWorksBody'>Accounted for 66% of sales in 2022</header>
                    </Grid.Column>
                    <Grid.Column className='worksBottomColumns'>
                        <header className='mobileWorksBottomHeader'>Low concentration risk</header>
                        <header className='mobileWorksBody'>No single brand accounted for more than 12% of sales in FY22 </header>
                    </Grid.Column>
                </Grid>
            ) */
        }
        else {/*
            return (
                <Grid.Row className='workBottomRow'>
                    <Grid.Column className='worksBottomColumns'>
                        <header className='worksBottomHeader'>50 brand partners</header>
                        <header className='worksStackBody'>We stock a wide range of premium brands</header>
                    </Grid.Column>
                    <Grid.Column className='worksBottomColumns'>
                        <header className='worksBottomHeader'>Top 10 brands</header>
                        <header className='worksStackBody'>Accounted for 66% of sales in 2022</header>
                    </Grid.Column>
                    <Grid.Column className='worksBottomColumns'>
                        <header className='worksBottomHeader'>Low concentration risk</header>
                        <header className='worksStackBody'>No single brand accounted for more than 12% of sales in FY22 </header>
                    </Grid.Column>
                </Grid.Row>
            )*/
        }
    }

    stickyNavBar = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='mobileWorksBlueBar'>
                    <Grid stackable className='stickyGrid'>
                        <Grid.Column className='mobileStickyItemsColumns'>
                            <Link to="worksPoint1" className='mobileStickyItems' activeClassName='worksActive' spy={true} smooth={true}>EXCELLENT SHOPPING EXPERIENCE</Link>
                        </Grid.Column>
                        <Grid.Column className='mobileStickyItemsColumns'>
                            <Link to="worksPoint2" className='mobileStickyItems' activeClassName='worksActive' spy={true} smooth={true}>TECHNOLOGY DRIVEN OPERATING MODEL</Link>
                        </Grid.Column>
                        <Grid.Column className='mobileStickyItemsColumns'>
                            <Link to="worksPoint3" className='mobileStickyItems' activeClassName='worksActive' spy={true} smooth={true}>SIMPLE DISTRIBUTION NETWORK</Link>
                        </Grid.Column>
                        <Grid.Column className='mobileStickyItemsColumns'>
                            <Link to="worksPoint4" className='mobileStickyItems' activeClassName='worksActive' spy={true} smooth={true}>VERTICALLY INTEGRATED DELIVERY MODEL</Link>
                        </Grid.Column>
                        <Grid.Column className='mobileStickyItemsColumns'>
                            <Link to="worksPoint5" className='mobileStickyItems' activeClassName='worksActive' spy={true} smooth={true}>STOCKING RIGHT PRODUCTS</Link>
                        </Grid.Column>
                    </Grid>
                </Container>
            )
        }
        else {
            return (
                <Container className='worksBlueBar'>
                    <Grid stackable className='stickyGrid'>
                        <Grid.Column className='stickyItemsColumns'>
                            <Link to="worksPoint1" className='stickyItems' activeClassName='worksActive' spy={true} smooth={true}><header>EXCELLENT SHOPPING EXPERIENCE</header></Link>
                        </Grid.Column>
                        <Grid.Column className='stickyItemsColumns'>
                            <Link to="worksPoint2" className='stickyItems' activeClassName='worksActive' spy={true} smooth={true}><header>TECHNOLOGY DRIVEN OPERATING MODEL</header></Link>
                        </Grid.Column>
                        <Grid.Column className='stickyItemsColumns'>
                            <Link to="worksPoint3" className='stickyItems' activeClassName='worksActive' spy={true} smooth={true}><header>SIMPLE DISTRIBUTION NETWORK</header></Link>
                        </Grid.Column>
                        <Grid.Column className='stickyItemsColumns'>
                            <Link to="worksPoint4" className='stickyItems' activeClassName='worksActive' spy={true} smooth={true}><header>VERTICALLY INTEGRATED DELIVERY MODEL</header></Link>
                        </Grid.Column>
                        <Grid.Column className='stickyItemsColumns'>
                            <Link to="worksPoint5" className='stickyItems' activeClassName='worksActive' spy={true} smooth={true}><header>STOCKING RIGHT PRODUCTS</header></Link>
                        </Grid.Column>
                    </Grid>
                </Container>
            )
        }
    }


    render() {
        return (
            <div className='howItWorks'>
                <Grid className='mainGrid'>
                    <Grid.Row style={{'padding-bottom': '0'}} className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white' shopButton='shopButton'
                                    menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                        {this.mainCopy()}
                    </Grid.Row>
                    <StickyContainer className='stickyContainer'>
                        <Sticky>
                            {({
                                  style,
                              }) => (
                                <header style={style}>
                                    {this.stickyNavBar()}
                                </header>
                            )}
                        </Sticky>
                        <Grid.Row id="worksPoint1" className='worksContentRow'>
                            {this.bodyHeader()}
                            <Container style={{'text-align':'left'}} className='worksContainer3'>
                                {this.stepsGrid()}
                            </Container>
                        </Grid.Row>
                        {this.switchGrid()}
                        {this.secondSwitchGrid()}
                        <div id="worksPoint5">
                            {this.bottomGridHeader()}
                            {this.bottomGrid()}
                        </div>
                    </StickyContainer>
                    {this.lastRow()}
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}