import React, {Component} from "react";
import MainHeader from "./MainHeader";
import Logo from "../Images/Logo_White.png";
import SearchImageWhite from "../Images/Group 8085.svg";
import {Container, Grid, Image} from "semantic-ui-react";
import MainImage from "../Images/COMMUNITY.png";
import MobileMainImage from "../Images/mobileFader5.png";
import Footer from "./Footer";
import Image1 from "../Images/Paul-Nixon.png";
import Image2 from "../Images/Newspaper-Mural.png";
import Image3 from "../Images/Aylestone-fc.png";
import Image4 from "../Images/LCCP.png";
import {Link} from "react-scroll";
import ScrollArrow from "../Images/Scroll down_W.svg";

export default class Community extends Component {
    mainImage = () => {
        if(this.props.mobileSite === true) {
            return (
                <Image className='mainImage' src={MobileMainImage}/>
            )
        }
        else {
            return (
                <Image className='mainImage' src={MainImage}/>
            )
        }
    }

    mainCopy = () => {
        if(this.props.mobileSite === true) {
            return (
                <Container className='communityMainCopy'>
                    <header className='mobileWorksSubCopy'>SOCIAL</header>
                    <header className='mobileWorksMainCopyText'>Our work in the community</header>
                </Container>
            )
        }
        else {
            return (
                <Container className='environmentMainCopy'>
                    <header className='small_title_w'>SOCIAL</header>
                    <header className='h1_w'>Our work in the community</header>
                    <Container className='envScrollArrow'>
                        <Link to="Point1" spy={true} smooth={true}><Image className='scrollArrowImg' src={ScrollArrow}/></Link>
                    </Container>
                </Container>
            )
        }
    }

    lightBlueRow = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileCommunityBlueBox2'>
                    <Container className='mobileCommunityWhiteContainer'>
                        <Grid className='mobileCommunityGrid'>
                            <Grid.Row>
                                <Container className='mobileCommunityContainer2'>
                                    <h2 className='mobileCommunityHeaders'>Leicestershire County Cricket Club</h2>
                                    {/*<header className='mobileCommunitySubHeader'>Leicestershire County Cricket Club</header>*/}
                                    <text className='mobileCommunityBody'>Marks Electrical holds official partner status with Leicestershire County Cricket Club (CCC). This gives Marks four sponsorship boards in one of Leicester’s most prominent sporting facilities, adverts on the LED scoreboards during each game, sponsor boards at the training facility and a direct link from the partners page on the Leicestershire CCC website.</text>
                                </Container>
                            </Grid.Row>
                            <Grid.Row>
                                <Image className='mainImage' src={Image1} />
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='communityBlueBox2'>
                    <Container className='communityWhiteContainer'>
                        <Grid>
                            <Grid.Row  id="Point1">
                                <Grid.Column className='communityImageColumn'>
                                    <Image className='mainImage' src={Image1} />
                                </Grid.Column>
                                <Grid.Column className='communityImageColumn'>
                                    <Container className='communityContainer2'>
                                        <h2 className='communityHeaders'>Leicestershire County Cricket Club</h2>
                                        {/*<header className='mobileCommunitySubHeader'>Leicestershire County Cricket Club</header>*/}
                                        <text className='communityBody'>Marks Electrical holds official partner status with Leicestershire County Cricket Club (CCC). This gives Marks four sponsorship boards in one of Leicester’s most prominent sporting facilities, adverts on the LED scoreboards during each game, sponsor boards at the training facility and a direct link from the partners page on the Leicestershire CCC website.</text>
                                    </Container>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
    }

    greyRow = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileCommunityGreyBox'>
                    <Container className='mobileCommunityWhiteContainer'>
                        <Grid className='mobileCommunityGrid'>
                            <Grid.Row>
                                <Container className='mobileCommunityContainer2'>
                                    <h2 className='mobileCommunityHeaders'>Leicester City Mural</h2>
                                    {/*<header className='mobileCommunitySubHeader'>Saving the Marks Electrical Leicester City Mural</header>*/}
                                    <text className='mobileCommunityBody'>Against the odds, during the 2015/16 Premier League season, Leicester City Football Club won the league, sparking intense intrigue internationally. As a lifelong Leicester City fan, Mark Smithson, founder and CEO of Marks Electrical commissioned a mural of the winning squad on the walls of the then Marks Electrical flagship store. The mural attracted global media attention with stations such as CNN and Sky News holding interviews at the shop. The mural became a local phenomenon and a symbol of celebration within the city.</text>
                                </Container>
                            </Grid.Row>
                            <Grid.Row>
                                <Image className='mainImage' src={Image2} />
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='communityGreyBox'>
                    <Container className='communityWhiteContainer'>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column className='communityImageColumn'>
                                    <Container className='communityContainer2'>
                                        <h2 className='communityHeaders'>Leicester City Mural</h2>
                                        {/*<header className='communitySubHeader'>Saving the Marks Electrical Leicester City Mural</header>*/}
                                        <text className='communityBody'>Against the odds, during the 2015/16 Premier League season, Leicester City Football Club won the league, sparking intense intrigue internationally. As a lifelong Leicester City fan, Mark Smithson, founder and CEO of Marks Electrical commissioned a mural of the winning squad on the walls of the then Marks Electrical flagship store. The mural attracted global media attention with stations such as CNN and Sky News holding interviews at the shop. The mural became a local phenomenon and a symbol of celebration within the city.</text>
                                    </Container>
                                </Grid.Column>
                                <Grid.Column className='communityImageColumn communityImageColumnRight'>
                                    <Image className='mainImage' src={Image2} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
    }

    blueRow2 = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileCommunityBlueBox2'>
                    <Container className='mobileCommunityWhiteContainer'>
                        <Grid className='mobileCommunityGrid'>
                            <Grid.Row>
                                <Container className='mobileCommunityContainer2'>
                                    <h2 className='mobileCommunityHeaders'>Grassroots Football</h2>
                                    {/*<header className='mobileCommunitySubHeader'>Grassroots</header>*/}
                                    <text className='mobileCommunityBody'>Founded in 1968, Aylestone Park is a local football club hosting over 50 teams, from 4yrs to an adult first team, as well as being home to the oldest women’s club in the country, Leicester City Ladies FC. Marks Electrical has been a long-standing sponsor of the club, providing necessary funding to Grassroots football, encouraging future generations to keep fit and get active. Grassroots enables new players to improve their skills, whilst having fun. It helps to develop local talent and promote diversity within local communities, values that are core to Marks Electrical.<br /><br />In addition to Aylestone Park, Marks Electrical also continues to fund several Grassroots clubs within Leicestershire.</text>
                                </Container>
                            </Grid.Row>
                            <Grid.Row>
                                <Image className='mainImage' src={Image3} />
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='communityBlueBox2'>
                    <Container className='communityWhiteContainer'>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column className='communityImageColumn'>
                                    <Image className='mainImage' src={Image3} />
                                </Grid.Column>
                                <Grid.Column className='communityImageColumn'>
                                    <Container className='communityContainer2'>
                                        <h2 className='communityHeaders'>Grassroots Football</h2>
                                        {/*<header className='communitySubHeader'>Grassroots</header>*/}
                                        <text className='communityBody'>Founded in 1968, Aylestone Park is a local football club hosting over 50 teams, from 4yrs to an adult first team, as well as being home to the oldest women’s club in the country, Leicester City Ladies FC. Marks Electrical has been a long-standing sponsor of the club, providing necessary funding to Grassroots football, encouraging future generations to keep fit and get active. Grassroots enables new players to improve their skills, whilst having fun. It helps to develop local talent and promote diversity within local communities, values that are core to Marks Electrical.<br /><br />In addition to Aylestone Park, Marks Electrical also continues to fund several Grassroots clubs within Leicestershire.</text>
                                    </Container>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
    }

    greyRow2 = () => {
        if(this.props.mobileSite === true) {
            return (
                <Grid.Row className='mobileCommunityGreyBox'>
                    <Container className='mobileCommunityWhiteContainer'>
                        <Grid className='mobileCommunityGrid'>
                            <Grid.Row>
                                <Container className='mobileCommunityContainer2'>
                                    <h2 className='mobileCommunityHeaders'>Leicester City Football Club</h2>
                                    {/*<header className='mobileCommunitySubHeader'>Saving the Marks Electrical Leicester City Mural</header>*/}
                                    <text className='mobileCommunityBody'>Marks Electrical has a strong track record of sponsorship deals with Leicester City Football Club and this relationship is being further solidified through the creation of a new community partner program. In conjunction with Eleven Sports Media, the Club have developed an award-winning fan engagement inventory, with its community partners featuring throughout. The Club will hold partner events and brand days giving significant exposure to the Mark Electrical brand. This partnership demonstrates Marks Electricals’ continued dedication to supporting the local sporting community and promoting excellence within the county.</text>
                                </Container>
                            </Grid.Row>
                            <Grid.Row>
                                <Image className='mainImage' src={Image4} />
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
        else {
            return (
                <Grid.Row className='communityGreyBox'>
                    <Container className='communityWhiteContainer'>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column className='communityImageColumn'>
                                    <Container className='communityContainer2'>
                                        <h2 className='communityHeaders'>Leicester City Football Club</h2>
                                        {/*<header className='communitySubHeader'>Community Partner 2021-22</header>*/}
                                        <text className='communityBody'>Marks Electrical has a strong track record of sponsorship deals with Leicester City Football Club and this relationship is being further solidified through the creation of a new community partner program. In conjunction with Eleven Sports Media, the Club have developed an award-winning fan engagement inventory, with its community partners featuring throughout. The Club will hold partner events and brand days giving significant exposure to the Mark Electrical brand. This partnership demonstrates Marks Electricals’ continued dedication to supporting the local sporting community and promoting excellence within the county.</text>
                                    </Container>
                                </Grid.Column>
                                <Grid.Column className='communityImageColumn communityImageColumnRight'>
                                    <Image className='mainImage' src={Image4} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Row>
            )
        }
    }

    render() {
        return (
            <div className='community'>
                <Grid className='mainGrid'>
                    <Grid.Row className='investorsRows'>
                        <MainHeader mobileSite={this.props.mobileSite} logo={Logo} h3='h3_white'
                                    shopButton='shopButton' menuDropdown='whiteDropdown' searchImage={SearchImageWhite}
                                    sharePriceUrl='https://irtools.co.uk/tools/share_price/db9f7736-8152-4900-8987-5ca09eb494c4'
                                    navLinks='nav-links' hamburgerColour='white'/>
                        {this.mainImage()}
                        {this.mainCopy()}
                    </Grid.Row>
                    {this.lightBlueRow()}
                    {this.greyRow()}
                    {this.blueRow2()}
                    {this.greyRow2()}
                </Grid>
                <Footer mobileSite={this.props.mobileSite}/>
            </div>
        )
    }
}