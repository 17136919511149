import React from 'react';
const TrustBox = (props) => {
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = React.useRef(null);
    React.useEffect(() => {
// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
// If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
// When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (
        <div id="trustbox" className={props.className} data-locale="en-GB" data-template-id="53aa8807dec7e10d38f59f32"
             data-businessunit-id="4be1d1510000640005080e61" data-style-height="100%" data-style-width="100%"
             data-theme="dark">
            <a href="https://uk.trustpilot.com/review/www.markselectrical.co.uk" target="_blank"
               rel="noopener noreferrer">Trustpilot</a>
        </div>
    );
};
export default TrustBox;